import React from "react";
import FilterHeader from "./Subcomponents/FilterHeader";
import Footer from "../Footer/Footer";



export default function FindDeals() {
  return (
    <div>
      <div><FilterHeader /></div>
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
}

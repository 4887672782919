import Skeleton from '@mui/material/Skeleton';
import { Card } from '@mui/material';


const CardMainSkeleton = () => {
    return (
        <>
            <div className="mapandListing_card">
                <div className="p-0">
                    <Card className='main_cardmi'>
                        <div className="w-100 d-flex justify-content-center pt-1">
                            <div className="cardmain_img">
                                <Skeleton variant="rounded" animation="wave" style={{ width: '100%', height: '100%' }} />
                            </div>
                        </div>
                        <div className='maindataxx' style={{ padding: "5px 6px" }}>
                            <Skeleton variant="text" animation="wave" sx={{ fontSize: '1.5rem' }} />
                            <Skeleton variant="text" animation="wave" sx={{ fontSize: '0.8rem' }} />
                            <Skeleton variant="text" animation="wave" sx={{ fontSize: '0.8rem' }} />
                        </div>
                        <div className="heart_compareMain">
                            <div className={`w-100 d-flex align-items-center px-2 Skeleton-item-for-hide`}>
                                <Skeleton variant="rounded" animation="wave" style={{ width: '100%', height: '25px' }} />
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
}

export default CardMainSkeleton;

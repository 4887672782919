import React from "react";
import CustomOffer from '../../../../images/PriceTabimages/custom.png'
import { useNavigate } from "react-router-dom";

export default function HowToBuyFromPrivateSeller({isUserLogIn}) {

  const navigate = useNavigate()

  return (
    <div className="py-5">
      <div className="d-flex coloring ">
        <div className="custom_offer_img_circle">
          <img src={CustomOffer} alt='custom Offer' loading="lazy"/>
        </div>
        <div className="ps-5 Buyer_fullWwidthTabs">
          <h2>Buying direct could get you a better deal</h2>

          <ul>
            <li>We make it easy for buyers and sellers to connect directly. Our search tools make it easy for buyers to access our wide inventory of properties, save your favorite properties, and set up alerts so that you are always in the know.</li>
            <li>
              Registering in our system makes it easy to seek property details, ask questions, set up showings and even make offers. These functions can be accessed from the property listing page. Registering is easy and free!
            </li>
          </ul>
        </div>
      </div>
      <div className="text-center">
        <button className={"Buyer_fullWidthBtn"} onClick={()=>navigate('/login')} disabled={isUserLogIn}>{isUserLogIn ? 'Registered' : 'Register / JoinNow'}</button>
      </div>
    </div>
  );
}

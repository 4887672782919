import React, { useEffect, useState } from "react";
import DashboardLayout from "../../Dashboard";
import Footer from "../../Footer/footer";
import axios from "axios";
import baseURL from "../../../config";
import Cookies from "universal-cookie";
import { RotatingLines } from "react-loader-spinner";
import { FormControl, MenuItem, Select } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { toast } from "react-toastify";
import { ToastContainer} from "react-toastify";

const RefinedImages = () => {
  const [loading, setloading] = useState(true);
  const [selectedImages, setSelectedImages] = useState([]);
  const [category, setCategory] = React.useState("");
  const [data, setData] = useState([]);
  const cookies = new Cookies();
  const jwt_token = cookies.get("jwt_authorization");

  const FetchData = () => {
    setloading(true);
    const headers = {
      Authorization: `Bearer ${jwt_token}`,
    };

    axios
      .get(`${baseURL}/api/Admin/GetImages`, { headers })
      .then((response) => {
        const refinedImagesData = response?.data?.data || [];
        setData(refinedImagesData);
        setloading(false);
      })
      .catch((error) => {
        console.error(error);
        setloading(false);
      });
  };
  const updateCategory = () => {
    if (selectedImages.length === 0 || !category) {
        toast.error("Both Images and Media Category Selection is Required")
      return;
    }

    const headers = {
      Authorization: `Bearer ${jwt_token}`,
    };

    const updateData = selectedImages.map((imageId) => ({
        Id: imageId,
        Type: category,
      }));
    axios
      .post(`${baseURL}/api/Admin/UpdateImages`, updateData, { headers })
      .then((response) => {
        toast.success(response?.data.message)
        setCategory("");
        setSelectedImages("");
        FetchData();
      })
      .catch((error) => {
        console.error("Error updating category:", error);
      });
  };
  useEffect(() => {
    FetchData();
    // eslint-disable-next-line
  }, []);

  const handleCheckboxChange = (assignmentId) => {
    setSelectedImages((prevSelectedImages) => {
      if (prevSelectedImages.includes(assignmentId)) {
        return prevSelectedImages.filter((id) => id !== assignmentId);
      } else {
        return [...prevSelectedImages, assignmentId];
      }
    });
  };
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  return (
    <DashboardLayout>
      <section className="page-content mt-4 mb-2">
        <div className="row pb-5">
          <div className="col-7">
            <h3 className="ms-2 fw-bold">Refined Images</h3>
          </div>
          <div className="col-3">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Media Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Select Media CAtegory"
                onChange={handleCategoryChange}
              >
                <MenuItem value={"Floor Plan"}>Floor Plan</MenuItem>
                <MenuItem value={"Interior"}>Interior</MenuItem>
                <MenuItem value={"Exterior"}>Exterior</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-2 d-flex align-items-center">
            <button  onClick={updateCategory}  className="btn category_Button">Update Category</button>
          </div>
        </div>
        {loading ? (
          <div className="text-center">
            <RotatingLines strokeColor="grey" />
          </div>
        ) : (
          <div className="row row-cols-1 row-cols-md-4 g-4">
            {data.map((x) => (
              <div key={x.id} className="col">
                <div className="card d-flex justify-content-end refined_images_cards">
                  <div className="d-flex pb-1 justify-content-end">
                    <input
                      type="checkbox"
                      checked={selectedImages.includes(x.id)}
                      onChange={() => handleCheckboxChange(x.id)}
                    />
                  </div>
                  <img
                    src={`${baseURL}/${x?.fileUrl}`}
                    alt="Lisings Img"
                    loading="lazy"
                    style={{objectFit:"contain",height:"110px",width:"100%", borderRadius: 8 }}
                  />
                  <div className="d-flex justify-content-end">
                    <button className="cardsime mt-2">
                      {x?.mediaCategory}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
  <ToastContainer />
      </section>
      <Footer />
    </DashboardLayout>
  );
};

export default RefinedImages;

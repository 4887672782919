import BuyWithUsBanner from '../../images/WhyBuyHomeWithUsBanner.png';
import ExpertGuidance from '../../images/ExpertGuidanceEverySystem.png';
import TransparentAndFairPricing from '../../images/TransparentAndFairPrice.png';
import PersonalizedMAtchMarketing from '../../images/PersonalizeMatchMaking.png';
import UnparallelMarketAccess from '../../images/UnParallelMarketAccess.png';
import Footer from '../Footer/Footer';
import HelmetLayout from '../../Utils/HelmetLayout/Helmet';

const WhyBuyHomeWithUs = () => {
    return (
        <>

            <HelmetLayout pageName={'buy-home-with-us'} canonicalLink={'buy-home-with-us'} />


            <div className='AboutUsMain_container'>
                <div className="MortgaegMain_image AboutUsMain_image">
                    <div className="image_color">
                        <img src={BuyWithUsBanner} alt="Buy with us banner" loading="lazy"/>
                    </div>
                    <div className="AboutUs_HeadingContainer">
                        <h3 className='AboutUsDes_heading mt-2'>
                            Why Buy a Home with AssignmentSold?
                        </h3>
                        <h5 className='AboutUs_des mt-4'>
                            "At AssignmentSold, we understand that buying a home is more than a transaction—it's the path to your dream life. Discover a seamless, personalized home buying experience that prioritizes your needs and unlocks the door to your future."
                        </h5>
                    </div>
                </div>


                <div className='d-flex align-items-center justify-content-center '>
                    <div className='toolTicker_container selling_value_mobile_view'>
                        <div className='toolAdImage_container'>
                            <img src={ExpertGuidance} alt='Guidance on every step' loading="lazy"/>
                        </div>
                        <div className='toolAdtext_container'>
                            <h2 className='tools_ad_heading'>Expert Guidance Every Step of the Way</h2>
                            <p className='SellerInf_DesSelling'>Expert Guidance Every Step of the Way" offers comprehensive, professional support throughout the real estate journey, leveraging seasoned expertise to navigate complexities, provide tailored advice, and ensure a seamless, informed transaction process.</p>
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-center  tools_grey_container'>
                    <div className='toolTicker_container'>
                        <div className='toolAdtext_container'>
                            <h2 className='tools_ad_heading'>Transparent and Fair Pricing</h2>
                            <p className='SellerInf_DesSelling'>Transparent and Fair Pricing" ensures clarity and integrity at every stage of the real estate transaction, offering buyers a straightforward and honest breakdown of costs. This commitment eliminates hidden fees and surprises, fostering trust and confidence. By prioritizing transparency, we empower our clients to make informed decisions, ensuring fair market value for every property.</p>
                        </div>
                        <div className='toolAdImage_container'>
                            <img src={TransparentAndFairPricing} alt='Fair pricing' loading="lazy"/>
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-center '>
                    <div className='toolTicker_container selling_value_mobile_view'>
                        <div className='toolAdImage_container'>
                            <img src={PersonalizedMAtchMarketing} alt='Personalized match making' loading="lazy"/>
                        </div>
                        <div className='toolAdtext_container'>
                            <h2 className='tools_ad_heading'>Personalized Matchmaking</h2>
                            <p className='SellerInf_DesSelling'>Personalized Matchmaking" delivers a bespoke real estate experience, leveraging advanced algorithms and expert insights to align properties with clients' unique preferences and lifestyle aspirations. This tailored approach ensures a harmonious match between buyer and property, optimizing the search process and transforming visions of the perfect home into reality.</p>
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-center  tools_grey_container'>
                    <div className='toolTicker_container'>
                        <div className='toolAdtext_container'>
                            <h2 className='tools_ad_heading'>Unparalleled Market Access</h2>
                            <p className='SellerInf_DesSelling'>Unparalleled Market Access" grants clients privileged entry to a curated selection of premium, often undisclosed properties. This elite service empowers discerning buyers with a competitive advantage, facilitating access to exclusive real estate opportunities across a range of high-end markets.</p>
                        </div>
                        <div className='toolAdImage_container'>
                            <img src={UnparallelMarketAccess} alt='Unparalleled market access' loading="lazy"/>
                        </div>
                    </div>
                </div>

                <div className='mt-5'>
                    <Footer />
                </div>
            </div >
        </>
    )
}

export default WhyBuyHomeWithUs
import React from "react";
import "./SearchAgent.css";
import Footer from "../Footer/Footer";
import AgentCard from "./Subcomponents/AgentCardListing/AgentCard";
import { NavLink } from "react-router-dom";

export default function SearchAgent() {
  return (
    <div>
      <div className="container page-width font">
        <div className="parentagent pb-0">
          {/* <div className="agent_heading">
          <h4 className="agenthead">Search Our Agent Directory</h4>
          </div>
          <span className="pt-2 agent_SearchField">
            <AgentSearchField />
          </span> */}
        </div>
        <div className="AdvisorAgent_link">
           <NavLink to='/mortgage-advisor'>
            <button>Mortgage Advisor</button>
           </NavLink>
          </div>
        <div className="row">
          <div className="col-md-12 pt-3 col-12 agent_picHide" style={{ position: "relative" }}>
            <img
              className="agentimg"
              src={require("../../images/agentimg.jpeg")}
              alt="Agent Card pic not found"
              loading="lazy"
            />
            {/* Overlay text */}
            <div
              style={{
                position: "absolute",
                top: 120,
                left: 10,
                right: 0,
                bottom: 0,
                display: "flex",
                fontFamily: "Montserrat",
                flexDirection: "column",
                justifyContent: "left",
                alignItems: "left",
                padding: 50,
              }}
            >
              <p
                className="m-0"
                style={{
                  color: "#fff",
                  fontSize: 32,
                  fontWeight: 700,
                  fontFamily: "Montserrat",
                  textAlign: "left",
                }}
              >
                Find an Experienced Agent
              </p>
              <p
                className="m-0"
                style={{
                  color: "#fff",
                  fontSize: 16,
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                Unbiased search results mean you find an agent with the
                experience you need. We don't get paid on agent matches and we
                don't take a cut of their commission.
              </p>
            </div>
          </div>
          {/* <div className="col-lg-5 col-12">
            <div className="row columflex">
              <div className="col-12 m-0 mb-1 agentborder">
                <div className="row">
                  <div className="col-lg-8 col-12 agent_textHide">
                    {" "}
                    <p className="agenttexthead p-0">I have an agent in mind</p>
                    <p className="agenttextbody p-0">
                      Connect with your agent to collaborate on
                      AssignmentSold.ca
                    </p>
                  </div>
                  <div className="col-lg-4 col-12 p-0 buttonadjustments">
                    {" "}
                    <button className="whitegreenbutton agent_whitegreenbutton">Use My Agent</button>
                  </div>
                </div>
              </div>
              <div className="col-12 m-0 agentborder">
                <div className="row">
                  <div className="col-lg-8 col-12 agent_textHide">
                    {" "}
                    <p className="agenttexthead p-0">Help me find an agent</p>
                    <p className="agenttextbody p-0">
                      Tell us what you're looking for and get only the results
                      that match your needs.
                    </p>
                  </div>
                  <div className="col-lg-4 col-12 p-0 buttonadjustments">
                    {" "}
                    <button className="whitegreenbutton agent_whitegreenbutton">
                      Search for Agent
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="allagentbutton pt-4">
          <p className="fitneeds">
            {" "}
            Here are a few agents that might fit your needs.
          </p>
        </div>

        <div className="cards agent_allcards pt-4">
          <AgentCard />
        </div>
        <div className="allagentbutton">
          <button className="Agentbutton">See All Agents</button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

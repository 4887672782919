import { useEffect, useState } from 'react';
import DashboardLayout from '../../Dashboard';
import { RotatingLines } from 'react-loader-spinner';
import { IconButton, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import UserAllListingDataCard from './Components/Cards/UserAllListingDataCards';
import axios from 'axios';
import baseURL from '../../../config';
import { toast, ToastContainer } from "react-toastify";
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';


const AllUserListings = () => {

    const cookies = new Cookies();
    const jwt_token = cookies.get("jwt_authorization");
    const navigate = useNavigate();
    const [Spinner, setSpinner] = useState(false);
    const [filteredCardData, setFilteredCardData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [data, setData] = useState([]);

    const fetchUsersData = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${jwt_token}`,
            };
            const response = await axios.get(
                `${baseURL}/api/Admin-User/GetUsers`,
                { headers }
            );
            const UsersData = response?.data?.data;
            setData(UsersData)
            setFilteredCardData(UsersData)
            setSpinner(false)
        } catch (error) {
            setSpinner(false)
            toast.error(error);
        }
    };

    useEffect(() => {
        if (!jwt_token) {
			navigate('/login');
            return;
		}
        setSpinner(true)
        fetchUsersData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const debounce = (func, delay) => {
        let timer;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    const debouncedSearch = debounce((query) => {
        setSpinner(false)
        if (query.length > 0) {
            const filteredData = data?.filter((listing) =>
                listing?.firstName?.toLowerCase().includes(query.toLowerCase()) ||
                listing?.email?.toLowerCase().includes(query.toLowerCase()) ||
                listing?.phoneNumber?.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredCardData(filteredData);
        } else {
            setFilteredCardData(data);
        }
    }, 500);

    const handleSearchInputChange = (event) => {
        setSpinner(true)
        setSearchQuery(event.target.value)
        debouncedSearch(event.target.value);
    };

    return (
        <DashboardLayout>
            <div className="page-content my_listing_mobContent">
                <div className='d-flex justify-content-between flexDrirection mobile_listing_MarginTop'>
                    <h3 className=" dashboard_listing_margin fw-bold text-black">Users</h3>
                    <form onSubmit={(e) => e.preventDefault()} className='border dashboard_listing_margin d-flex rounded' style={{minWidth:'300px'}}>
                        <InputBase
                            size='medium'
                            sx={{ flex: 1, paddingLeft: 1 }}
                            placeholder="Search by name, email, contact"
                            value={searchQuery}
                            onChange={handleSearchInputChange}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </form>
                </div>
                <br />
                {
                    Spinner === true ? (
                        <center className='mt-5 text-center'>
                            <RotatingLines
                                strokeColor="grey"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="70"
                                visible={Spinner}
                            />
                        </center>
                    ) : (
                        <>
                            {/* Map data to create cards */}
                            {filteredCardData?.length === 0 ? (
                                <div className="no__FoundMain font">
                                    <div className="no__Found__img">
                                        <img className='not_found_img_mobile' src={require("../../../images/noResultFound.png")} alt="Result not found" loading="lazy"/>
                                    </div>
                                    <div className="no__FoundText font-family-regular not_found_Text_mobile">
                                        <p>Result Not Found  <u><a href="/submit-assignment">Add Your Listings Now</a></u></p>
                                    </div>
                                </div>
                            ) : (
                                <div className='d-flex flex-wrap' style={{ gap: '20px' }}>
                                    {filteredCardData?.map((item, index) => (
                                        <UserAllListingDataCard item={item} key={index} />
                                    ))}
                                </div>
                            )}
                        </>
                    )
                }
            </div>
            <ToastContainer />
        </DashboardLayout >
    )
}


export default AllUserListings;
import React from "react";
import "../../../src/Components.css";
import "./Footer.css";
import { HiLocationMarker } from "react-icons/hi";
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoMdMail } from "react-icons/io";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { HashLink as Link } from 'react-router-hash-link';

export default function Footer() {
  const data = useSelector((state) => state.adminSiteDataSlice);


  return (
    <div>
      <footer
        className="text-center text-lg-start greencolor text-white mt-2"
        style={{ backgroundColor: "#F5F5F5" }}
      >
        {/* Section: Social media */}
        {/* Section: Links  */}
        <section style={{ backgroundColor: "#F5F5F5", color: "#676767" }}>
          <div className="flex">
            {/* Grid row */}
            <div className="row section-width">
              {/* Grid column */}
              <div className="col-md-3 col-12 po-3 mobile_div font mx-auto ">
                {/* Content */}
                <h5
                  className="text-uppercase font textcolor fw-bold"
                >
                  Explore AssignmentSold.ca
                </h5>
                <hr className="mb-4 mt-0 d-inline-block mx-auto" />

                {data !== undefined && (
                  <>
                    <p className="textcolor">
                      <HiLocationMarker className="icon_setting textcolor" /> {`${data[0]?.address}`}
                    </p>
                    <p className="textcolor">
                      <BsFillTelephoneFill className="icon_setting textcolor" /> {`${data[0]?.phone}`}
                    </p>
                    <p className="textcolor">
                      <IoMdMail className="icon_setting textcolor" /> {`${data[0]?.email}`}
                    </p>
                    <p className="textcolor">
                      <a href={`${data[0]?.facebook}`} target="_blank" rel="noopener noreferrer">
                        <FaFacebookF className="roundicon" style={{ backgroundColor: "#676767" }} />{" "}
                      </a>
                      <a href={`${data[0]?.instagram}`} target="_blank" rel="noopener noreferrer">
                        <AiOutlineInstagram className="roundicon icmargin" style={{ backgroundColor: "#676767" }} />{" "}
                      </a>
                      <a href={`${data[0]?.tiktok}`} target="_blank" rel="noopener noreferrer">
                        <FaTiktok className="roundicon icmargin" style={{ backgroundColor: "#676767" }} />{" "}
                      </a>
                    </p>
                  </>
                )}
              </div>

              <div className="col-md-3 col-12 mobile_div font mx-auto mb-4">
                {/* Links */}
                <h5
                  className="text-uppercase textcolor font fw-bold"
                  style={{ fontSize: "21px" }}
                >
                  Selling a Property
                </h5>
                <hr className="mb-4 mt-0 d-inline-block mx-auto" />
                <Link smooth to="/advertise-with-us" className="textcolor">
                  <p className="textcolor">Advertise with us</p>
                </Link>
                <Link smooth to="/sell#pricing" className="textcolor">
                  <p className="textcolor">Pricing</p>
                </Link>
                <NavLink to="/blogs/Selling" className="textcolor">
                  <p className="textcolor">All about Selling</p>
                </NavLink>
              </div>

              <div className="col-md-3 col-12 po-3 mobile_div font mx-auto mb-4">
                <h5
                  className="text-uppercase p-0 font textcolor fw-bold"
                >
                  Buying a Property
                </h5>
                <hr className="mb-4 mt-0 d-inline-block mx-auto" />

                <NavLink to="/buy-home-with-us" className="textcolor">
                  <p className="textcolor">Why buy a Home with Assignment Sold?</p>
                </NavLink>

                <NavLink to="/how-it-works" className="textcolor">
                  <p className="textcolor">How it Works</p>
                </NavLink>
                
                <NavLink to="/blogs/Buying" className="textcolor">
                  <p className="textcolor">All about Buying</p>
                </NavLink>

                <NavLink to="/company-advertisement" className="textcolor">
                  <p className="textcolor">Company Advertisement</p>
                </NavLink>
              </div>

              <div className="col-md-2 col-12 mobile_div font mx-auto mb-md-0 mb-4">
                {/* Links */}
                <h5
                  className="text-uppercase font textcolor fw-bold"
                  style={{ fontSize: "21px" }}
                >
                  Quick Links
                </h5>
                <hr className="mb-4 mt-0 d-inline-block mx-auto" />
                <NavLink to="/about" className="textcolor">
                  <p className="textcolor">About Us</p>
                </NavLink>
                <NavLink to="/press" className="textcolor">
                  <p className="textcolor">Press</p>
                </NavLink>
                <NavLink to="/privacy-policy" className="textcolor">
                  <p className="textcolor">Privacy Policy</p>
                </NavLink>
                <NavLink to="/cookie-policy" className="textcolor">
                  <p className="textcolor">Cookie Policy</p>
                </NavLink>
                <NavLink to="/blogs/Heart-finds-home" className="textcolor">
                  <p className="textcolor">Heart finds Home</p>
                </NavLink>
                <NavLink to="/blogs/News" className="textcolor">
                  <p className="textcolor">Real Estate News</p>
                </NavLink>
              </div>
              {/* Grid column */}
            </div>
            {/* Grid row */}
          </div>
        </section>

        <div
          className="text-center copy_right font px-3 py-4"
          style={{ backgroundColor: "#F5F5F5", color: "#676767", borderTop: "0.5px solid #676767" }}
        >
          <p>Website designed and developed by <a href='https://chishtiglobal.ca' target='_blank' rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>ChishtiGlobal.ca</a></p>
          <p className="m-0">Copyright 2021-2022 Assignmentsold.ca All Rights Reserved</p>
        </div>
      </footer>
    </div>
  );
}

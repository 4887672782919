import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useEffect } from 'react';
import PhoneInput from 'react-phone-number-input'


export default function UserDetails({ onNext, formData, updateFormData }) {
    const [isFormValid, setIsFormValid] = useState(true);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);
    useEffect(() => {
        function handleResize() {
            setIsMobileView(window.innerWidth <= 800);
        }

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    // const formatPhoneNumber = (inputNumber) => {
    //     const cleaned = ("" + inputNumber).replace(/\D/g, "").slice(0, 11);
    //     const match = cleaned.match(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})$/);
    //     let formattedNumber = "";
    //     if (match[1]) {
    //         formattedNumber += `+${match[1]}`;
    //     }
    //     if (match[2]) {
    //         formattedNumber += ` (${match[2]}`;
    //     }
    //     if (match[3]) {
    //         formattedNumber += `) ${match[3]}`;
    //     }
    //     if (match[4]) {
    //         formattedNumber += `-${match[4]}`;
    //     }
    //     return formattedNumber;
    // };
    const handleInputChange = (field, value) => {
        const updatedFormData = {
            ...formData,
            [field]: value,
        };
        updateFormData(updatedFormData); // Update the parent state
    };

    useEffect(() => {
        const validateUserDetails = () => {
            const isValid = formData?.email?.trim() !== ''
                && formData?.phone?.trim() !== ''
                && formData?.firstName?.trim() !== ''
                && formData?.lastName?.trim() !== ''
                && formData?.province?.trim() !== ""
                && formData?.checkbox_1 === true
                && formData?.checkbox_2 === true
                && formData?.checkbox_3 === true;
            setIsFormValid(isValid);
        };

        validateUserDetails();
    }, [formData]);
    return <>
        <div className='bg-white getquote_card_padding mt-4' style={{ borderRadius: "30px" }}>

            <div className='margin_bottom font' >
                <h4>
                    1. Enter your contact information
                </h4>
            </div>
            <Form className="mt-3 d-flex Mobilefont13 fullwidthcolumn">
                <Form.Group className=" w-50 pe-3 mobileviewWidth FieldWidth">
                    <Form.Label className="fs-6 mb-0 fontmobile16 font text-dark text-shadow-black" >First Name</Form.Label>
                    <Form.Control
                        size="small"
                        type="text"
                        className='fontmobile14'
                        value={formData.firstName}
                        onChange={(e) => handleInputChange('firstName', e.target.value)}
                    />
                </Form.Group>
                <Form.Group className=" w-50 ps-3 mobile_padding_input mobileviewWidth FieldWidth">
                    <Form.Label className="fs-6 fontmobile16 mb-0 text-shadow-black">Last Name</Form.Label>
                    <Form.Control
                        size="small"
                        type="text"
                        className='fontmobile14'
                        value={formData.lastName}
                        onChange={(e) => handleInputChange('lastName', e.target.value)}
                    />
                </Form.Group>
            </Form>
            <Form className="d-flex mt-3 fullwidthcolumn">
                <Form.Group className=" w-50 pe-3 mobileviewWidth FieldWidth">
                    <Form.Label className="fs-6 mb-0 fontmobile16 text-shadow-black"> Email </Form.Label>
                    <Form.Control
                        size="small"
                        type="email"
                        className='fontmobile14'
                        value={formData.email}
                        onChange={(e) => handleInputChange('email', e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="w-50 ps-3 mobile_padding_input mobileviewWidth FieldWidth">
                    <Form.Label className="fs-6 mb-0 fontmobile16 text-shadow-black "> Phone </Form.Label>
                    {/* <Form.Control
                        size='medium'
                        type="tel"
                        className='fontmobile14'
                        value={formatPhoneNumber(formData.phone)}
                        onChange={(e) => handleInputChange('phone', formatPhoneNumber(e.target.value))}
                    /> */}
                    <PhoneInput
                        size='small'
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry='CA'
                        value={formData.phone}
                        onChange={(e) => handleInputChange('phone', e)}
                        name='phoneNumber'
                        id='phoneNumber'
                        className='Phone_input w-100 bg-white quotes_phone_input'
                        placeholder='Enter Your Phone Number'
                    />
                </Form.Group>
            </Form>

            <Form className="d-flex mt-3 fullwidthcolumn">
                <Form.Group className=" w-50 pe-3 mobileviewWidth FieldWidth">
                    <Form.Label className="fs-6 mb-0 fontmobile16 text-shadow-black"> Province </Form.Label>
                    <Form.Control
                        size="small"
                        type="text"
                        className='fontmobile14'
                        value={formData.province}
                        onChange={(e) => handleInputChange('province', e.target.value)}
                    />
                </Form.Group>
            </Form>

            <div className=''>
                <div className='pt-3 lh-1' >
                    <input type="checkbox" onChange={(e) => handleInputChange("checkbox_1", !formData.checkbox_1)} checked={formData.checkbox_1} />
                    <span className='ms-2 font dynamicFont12'>
                        "I acknowledge that I have reviewed and agreed to the Terms of Service and Privacy Policy. I give my consent for NeedHome to process my personal information in compliance with the policy. I am aware that my information may be shared within the company group to enhance my overall experience, gain insight into my preferences, enhance existing products and services, as well as develop new ones that may align with my interests, and for statistical and analytical purposes. I also understand that I have the option to revoke this consent at any time by adjusting my preferences in my account settings."

                    </span>
                </div>

                <div className='pt-3 lh-1'>
                    <input type="checkbox" onChange={(e) => handleInputChange("checkbox_2", !formData.checkbox_2)} checked={formData.checkbox_2} />
                    <span className='ms-2 font dynamicFont12'>
                        "I confirm that I have been provided with the French version of the Terms of Service, and I explicitly request to engage in this contract in the English language. I also expressly request that all documents pertaining to this contract be prepared in English."
                    </span>
                </div>

                <div className='pt-3 lh-1' >
                    <input type="checkbox" onChange={(e) => handleInputChange("checkbox_3", !formData.checkbox_3)} checked={formData.checkbox_3} />
                    <span className='ms-2 font dynamicFont12'>
                        "Indeed, I consent to NeedHome utilizing my information to gain a deeper understanding of my preferences and areas of interest. This will enable them to customize the content they send me via email and SMS, as well as to deliver advertisements that are more likely to capture my interest, even on social media platforms. I also acknowledge and accept that NeedHome employs data collection technologies in the emails they send me, in order to enhance their understanding of how I engage with their communications and to better tailor them to my preferences. I am aware that I retain the right to revoke this consent at any given time."
                    </span>
                </div>
            </div>
        </div>

        <div className='d-flex bottom ' >
            <button
                style={{ position: 'absolute', bottom: '10px', right: '20px', border: "1px solid", padding: isMobileView ? "8px 12px" : "10px 25px" }}
                className='btn btn-primary fs-5 fontmobile16 primary-bg font text-shadow-black'
                onClick={() => onNext(formData)}
                disabled={!isFormValid}
            >
                Next &gt;
            </button>
        </div>

    </>;
}
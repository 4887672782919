import { createSlice } from "@reduxjs/toolkit";

const filterDataSlice = createSlice({
  name: "FilterDataSlice",
  initialState: [], 
  reducers: {
    addData: (state, action) => {
      state.push(action.payload);
    },
    removeData: () => {
      return [];
    },
  },
});

export const { addData, removeData } = filterDataSlice.actions;
export default filterDataSlice.reducer;


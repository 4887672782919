import React from "react";

export default function MailTemplate({ activeId, mails }) {
  let matchedEmail = null;
  if (mails?.fetchedEmails && mails.fetchedEmails.length > 0) {
    mails.fetchedEmails.map((email) => {
      if (email.id === activeId) {
        matchedEmail = email;
      }
      return null;
    });
  }
  return (
    <div>
      {matchedEmail ? (
        <>
          <p className="pt-5"
            dangerouslySetInnerHTML={{
              __html: matchedEmail.body,
            }}
          ></p>
        </>
      ) : (
        <>No Data Avaible To view</>
      )}
    </div>
  );
}

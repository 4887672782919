import React from "react";
import baseURL from "../../../config";
import CloseIcon from "@mui/icons-material/Close";


export default function CompareModal({ n, handleRemoveCompare }) {

  const nowAssignmentPrice = n?.assignmentPrices?.length > 0 ? n?.assignmentPrices[n?.assignmentPrices?.length - 1].prices : n?.assignmentPrice;
  const nowAssPrice =
    n?.assignmentPrices?.length > 0
      ? n?.assignmentPrices[n?.assignmentPrices?.length - 1].prices
      : n?.assignmentPrice;
  const NowSqftPrice = Math.ceil(nowAssPrice / n?.interiorSize);

  return (

    <>
      <div key={n?.id} className="cardinmodal">
        {n?.assignmentMedias &&
          n?.assignmentMedias.length > 0 &&
          n?.assignmentMedias.fileUrl !== "" ? (
          <img
            src={`${baseURL}/${n?.assignmentMedias[0]?.fileUrl}`}
            alt={n?.projectName}
            style={{ width: "100%", height: '10rem', borderRadius: 15 }}
            loading="lazy"
          />
        ) : (
          <img
            src={require("../../../images/no-image.png")}
            alt={n?.projectName}
            style={{ width: "100%", height: '10rem', borderRadius: 15 }}
            loading="lazy"
          />
        )}

        <div style={{ height: '105px' }}>
          <p className="m-0 modalcardpara font">
            <span className="textgreen">
              {n?.projectName ? (
                <>
                  {n?.projectName.length > 20 ?
                    <>
                      <span>{n?.projectName.slice(0, 20)} ... </span> | <span>{n?.interiorSize.toLocaleString("en-US")}SQft</span>
                    </>
                    :
                    <>
                      {n?.projectName.slice(0, 20)} |  <span>{n?.interiorSize.toLocaleString("en-US")}SQft</span>
                    </>
                  }
                </>
              ) : null}
            </span>
          </p>

          <p className="m-0 modalcardpara  font">
            {" "}
            { }{" "}
            {n?.developer ? (
              <>
                {n?.developer.length > 27 ?
                  <span>{n?.developer.slice(0, 27)} ... </span>
                  :
                  n?.developer.slice(0, 27)
                }
              </>
            ) : null}

          </p>
          <p className="m-0 modalcardpara font">
            <span className="textgreen">{n?.city}</span>
          </p>

          <p className="m-0 modalcardpara2  font">
            <span className="textgreen ">$ {nowAssignmentPrice.toLocaleString("en-US")}
              {" "}
              <span className="modalcardpara font">{NowSqftPrice.toLocaleString("en-US")}/SQft</span>
            </span>
          </p>
        </div>
        <button className="EraseCompare_button" onClick={() => handleRemoveCompare(n)}>
          <CloseIcon style={{ fontSize: '16px', color: 'red', fontWeight: '600' }} />
        </button>
      </div>
    </>
  );
}

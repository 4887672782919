import { useEffect, useState } from "react";
export default function ButtonList({ selectedValues, handleSelectionChange }) {
  const [activeButtons, setActiveButtons] = useState([]);
  useEffect(() => {
    // Set the active buttons based on the selected buttons prop
    setActiveButtons(selectedValues);
  }, [selectedValues]);

  const handleClick = (button) => {
    // Check if the button is already active
    const isActive = activeButtons.includes(button);

    if (isActive) {
      // If the button is active, remove it from the active buttons state
      const newActiveButtons = activeButtons.filter((btn) => btn !== button);
      setActiveButtons(newActiveButtons);
      handleSelectionChange(newActiveButtons); 
    } else {
      // If the button is inactive, add it to the active buttons state
      const newActiveButtons = [...activeButtons, button];
      setActiveButtons(newActiveButtons);
      handleSelectionChange(newActiveButtons); 
    }
  };
  return (
    <div className="mt-2 p-2">
      <div>
        {/* <button
          className={`btn ${
            activeButtons.includes("All")
              ? "btn font drawerbtoncss2"
              : "btn font drawerbtoncss"
          }`}
          onClick={() => handleClick("All")}
        >
          All
        </button> */}
        <button
          className={`btn ${
            activeButtons.includes("Condo")
              ? "btn font drawerbtoncss2"
              : "btn font drawerbtoncss"
          }`}
          onClick={() => handleClick("Condo")}
        >
          Condo
        </button>
        <button
          className={`btn ${
            activeButtons.includes("Condo Town")
              ? "btn font drawerbtoncss2"
              : "btn font drawerbtoncss"
          }`}
          onClick={() => handleClick("Condo Town")}
        >
          Condo Town
        </button>
        <button
          className={`btn ${
            activeButtons.includes("TownHouse")
              ? "btn font drawerbtoncss2"
              : "btn font drawerbtoncss"
          }`}
          onClick={() => handleClick("TownHouse")}
        >
          TownHouse
        </button>
        <button
          className={`btn ${
            activeButtons.includes("Semi-Detached")
              ? "btn font drawerbtoncss2"
              : "btn font drawerbtoncss"
          }`}
          onClick={() => handleClick("Semi-Detached")}
        >
          Semi-Detached
        </button>
        <button
          className={`btn ${
            activeButtons.includes("Detached")
              ? "btn font drawerbtoncss2"
              : "btn font drawerbtoncss"
          }`}
          onClick={() => handleClick("Detached")}
        >
          Detached
        </button>
        {/* Add more buttons as needed */}
      </div>
    </div>
  );
}

import React from "react";
import { ThreeCircles } from "react-loader-spinner";

export default function Loader({ size }) {
  return (
    <div>
      <ThreeCircles
        height= {size}
        width={size}
        color="#f6941c"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="three-circles-rotating"
        outerCircleColor="#02549e"
        innerCircleColor="#f6941c"
        middleCircleColor="#02549e"
      />
    </div>
  );
}

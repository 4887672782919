import { createSlice } from "@reduxjs/toolkit";

const imageUploadSlice = createSlice({
  name: "ImageUploadSlice",
  initialState: [],
  reducers: {
    addImageData: (state, action) => {
      state.push(action.payload);
    },
    removeImageData: (state, action) => {
      const imageUrlToRemove = action.payload; // Assuming action.payload is the URL to remove
      return state.filter((item) => item.imageURL !== imageUrlToRemove);
    },
    clearImageData:()=>{
      return [];
    }
  },
});

export const { addImageData, removeImageData, clearImageData } = imageUploadSlice.actions;
export default imageUploadSlice.reducer;

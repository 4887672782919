import React from "react";

export default function Pricemyhouse() {
  return (
    <div className="text-center">
      <div className="d-flex flex-column align-items-center">
        <h1 className="text-primary font-weight-bold">You will be guided through the process with our experts</h1>

        <ul className="text-start mt-3" style={{ listStyle: 'disc' }}>
          <li>
            {" "}
            You can recieve the expert analysis to help price your property
          </li>
          <li>
            You can access our local experts that have vast industry experience
            with your local market
          </li>
          <li>Your opinion and knowledge matters and will be taken</li>
        </ul>

        <p className="text-center mt-4" style={{ fontWeight: '500' }}>
          <strong>Want cash back when buying your next property?</strong>
        </p>
        <center>
          <a href='#valueHouse'>
          <button className="btn learn_moreBtnTabs text-center m-0">
            Learn More
          </button>
          </a>        
        </center>
      </div>
    </div>
  );
}

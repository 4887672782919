import { useState, useEffect } from "react";
import DashboardLayout from "../../../Dashboard";
import { toast, ToastContainer } from 'react-toastify';
import { RotatingLines } from "react-loader-spinner";
import { IconButton, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import '../UserListings.css';
import baseURL from "../../../../config";
import UserListingCard from "./Cards/UserListingCards";
import Cookies from 'universal-cookie';
import axios from "axios";
import { useDispatch } from "react-redux";
import { removePdfData } from "../../../../ReduxStore/Slices/PdfDownloadSlice";
import Drawer from "@mui/material/Drawer";
import DrawerContent from "../../../../HomePage/SubComponents/MapandListing/DrawerContent";
import Loader from "../../../../HomePage/SubComponents/MapandListing/Loader";
import UserSaveSearchesCard from "./Cards/UserSaveSearchesCard";
// import UserConversationCard from "./Cards/UsersConversationCard";
import { Helmet } from 'react-helmet';


const UserAllListing = () => {

    const { search } = useLocation();
    const { id } = useParams()
    const params = new URLSearchParams(search);
    const ListingOwnerName = params.get('name');
    const ListingOwnerRole = params.get('role');
    const cookies = new Cookies();
    const jwt_token = cookies.get("jwt_authorization");
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchBy, setSearchBy] = useState('ProjectName')
    const [btnStatus, setBtnStatus] = useState('');
    const [filteredCardData, setFilteredCardData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([])
    const [searchValue, setSearchValue] = useState('');
    const [selectedCards, setSelectedCards] = useState([]);
    const [btnDisabled, setBtnDisabled] = useState();
    const fetchUserListingData = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${jwt_token}`,
            };
            const response = await axios.get(
                `${baseURL}/api/Admin-User/GetUserAssignment?UserId=${id}&Status=${btnStatus}`,
                { headers }
            );
            if (response) {
                const UsersData = response?.data?.data;
                setData(UsersData)
                setFilteredCardData(UsersData)
                setLoader(false)
            }
        } catch (error) {
            setLoader(false)
            toast.error(error);
        }
    };


    //Fetch UserFavourites 

    const fetchUserFavourites = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${jwt_token}`,
            };
            const response = await axios.get(
                `${baseURL}/api/Admin-User/GetUserFavourite?UserId=${id}`,
                { headers }
            );
            if (response) {
                const responseData = response?.data?.data;
                const assignmentArrays = responseData.map(item => item.assignment || []);
                const allFavorites = [].concat(...assignmentArrays);
                setData(allFavorites)
                setFilteredCardData(allFavorites)
                setLoader(false)
            }
        } catch (error) {
            setLoader(false)
            toast.error(error);
        }
    };



    //Fetch SaveSearches 

    const fetchSaveSearches = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${jwt_token}`,
            };
            const response = await axios.get(
                `${baseURL}/api/Admin-User/GetUserSearches?UserId=${id}`,
                { headers }
            );
            if (response) {
                const searchesData = response?.data?.data;
                setData(searchesData)
                setFilteredCardData(searchesData)
                setLoader(false)
            }
        } catch (error) {
            setLoader(false)
            toast.error(error);
        }
    };

    //Fetch Conversation

    // const fetchConverstaion = async () => {
    //     try {
    //         const headers = {
    //             Authorization: `Bearer ${jwt_token}`,
    //         };
    //         const response = await axios.get(
    //             `${baseURL}/api/Admin-User/GetUserChats?UserId=${id}`,
    //             { headers }
    //         );
    //         if (response) {
    //             const conversationData = response?.data?.data;
    //             setData(conversationData)
    //             setFilteredCardData(conversationData)
    //             setLoader(false)
    //         }
    //     } catch (error) {
    //         setLoader(false)
    //         toast.error(error);
    //     }
    // };



    useEffect(() => {
        if (ListingOwnerRole === 'Buyer') {
            setBtnStatus('Favorite')
        }
        else {
            setBtnStatus('Active')
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!jwt_token) {
            navigate('/login');
            return;
        }
        setLoader(true)
        if (btnStatus === 'Active' || btnStatus === 'Pending' || btnStatus === 'Suspended') {
            fetchUserListingData()
        }
        else if (btnStatus === 'Favorite') {
            fetchUserFavourites()
        }
        else if (btnStatus === 'Searches') {
            fetchSaveSearches()
        }
        // else if (btnStatus === 'Conversations') {
        //     fetchConverstaion()
        // }
        // eslint-disable-next-line
    }, [btnStatus])


    //Change PropertyStatus
    const ChangePropertyStauts = async (status) => {
        try {
            const headers = {
                Authorization: `Bearer ${jwt_token}`,
            };

            const payload = selectedCards.map(Uid => ({
                Uid: Uid,
                status: status,
            }));

            const response = await axios.post(
                `${baseURL}/api/Admin-User/UpdateListing`, payload,
                { headers }
            );

            if (response) {
                setSelectedCards([])
                fetchUserListingData()
                setBtnDisabled(false)
            }

        } catch (error) {
            setLoader(false)
            setSelectedCards([])
            setBtnDisabled(false)
            toast.error(error);
        }
    };

    const handleChangePropStatusBtn = (status) => {
        if (!jwt_token) {
            navigate('/login');
        }
        setBtnDisabled(true)
        setLoader(true)
        ChangePropertyStauts(status)
    }


    const debounce = (func, delay) => {
        let timer;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    const debouncedSearch = debounce((query) => {
        setLoader(false)
        if (query.length > 0) {
            const filteredData = data?.filter((item) => {
                switch (searchBy) {
                    case 'ListingId':
                        return item?.id?.toString() === query;
                    case 'ProjectName':
                        return item?.projectName?.toLowerCase().includes(query);
                    case 'Price':
                        return item?.assignmentPrice?.toString() === query;
                    default:
                        return true;
                }
            });
            setFilteredCardData(filteredData);
        } else {
            setFilteredCardData(data);
        }
    }, 500);

    const handleSearchInputChange = (event) => {
        setLoader(true)
        debouncedSearch(event.target.value);
        setSearchValue(event.target.value)
    };

    //Drawer open even refresh
    const [drawerLoading, setDrawerLoading] = useState(false)
    const drawerSlug = params.get('drawerSlug');
    const location = useLocation();
    const slugPresent = drawerSlug;
    const [draweritem, setDrawerItem] = useState();
    const [itemID, setItemID] = useState(1);
    const { searchData } = location.state || {};
    
    useEffect(() => {
        if (searchData === undefined) {
            if (slugPresent) {
                axios
                    .get(`${baseURL}/api/Assignment/GetAssignment/${slugPresent}`)
                    .then((response) => {
                        if (response?.data?.data) {
                            setDrawerItem(response?.data?.data);
                            setOpenDrawer(response?.data?.data?.id);
                            setItemID(response?.data?.data?.id);
                            setDrawerLoading(false)
                        } else {
                            toast.error("There is no Data for this Assignment");
                            navigate(`/dashboard/users/${id}`);
                            setDrawerLoading(false)
                        }
                    })
                    .catch((error) => {
                        navigate(`/dashboard/users/${id}`);
                        toast.error('Error in fetching Listing');
                        setDrawerLoading(false)
                    });
            }
        }
        // eslint-disable-next-line
    }, [slugPresent]);

    useEffect(() => {
        const handlePopstate = () => {
            const currentPath = window.location.pathname;
            if (currentPath === `/dashboard/users/${id}`) {
                setOpenDrawer(null);
                setDrawerLoading(false)
                setItemID(1);
                dispatch(removePdfData());
            }
        };

        window.addEventListener("popstate", handlePopstate);

        return () => {
            window.removeEventListener("popstate", handlePopstate);
        };
        // eslint-disable-next-line
    }, [dispatch]);


    const [openDrawer, setOpenDrawer] = useState(null);
    const toggleDrawer = (anchor, itemId) => () => {
        setOpenDrawer(null);
        setItemID(1);
        dispatch(removePdfData());
        navigate(`/dashboard/users/${id}?name=${ListingOwnerName}`);
    };

    const isDrawerOpen = (itemId) => {
        // eslint-disable-next-line
        return openDrawer == itemId;
    };



    return (
        <DashboardLayout>
            
            <Helmet>
                <title>AssignmentSold.ca</title>
            </Helmet>
            
            <div className="row br-mobile4remMarginTop"></div>
            <section className='page-content br-page-content'>
                <div className="br-container mb-3">
                    <div className="headingtitlelister">
                        <h3 className="Single_allListingHeading m-0 d-flex align-items-center">
                            <span style={{ cursor: "pointer" }} onClick={() => navigate('/dashboard/users')}>User</span>
                            <ArrowForwardIosRoundedIcon className='mx-1 singleUserBreadCrumbs_arrow' />
                            <span className="singleUserBreadCrumbs_slash">/</span>
                            <span>{ListingOwnerName}</span>
                        </h3>
                    </div>
                    <div className="br-filterconntainer">
                        <div className="br-datebtn">
                            {(btnStatus === 'Pending' || btnStatus === 'Suspended') && (
                                <button className='UserSingleListing_statusBtn' disabled={selectedCards.length === 0 || btnDisabled} onClick={() => handleChangePropStatusBtn('Active')}>
                                    Active
                                </button>
                            )}
                            {(btnStatus === 'Active' || btnStatus === 'Suspended') && (
                                <button className='UserSingleListing_statusBtn' disabled={selectedCards.length === 0 || btnDisabled} onClick={() => handleChangePropStatusBtn('Pending')}>
                                    Pending
                                </button>
                            )}
                            {(btnStatus === 'Pending' || btnStatus === 'Active') && (
                                <button className='UserSingleListing_statusBtn' disabled={selectedCards.length === 0 || btnDisabled} onClick={() => handleChangePropStatusBtn('Suspended')}>
                                    Suspended
                                </button>
                            )}
                        </div>

                        <div className="d-flex br-searchInputAndTitle">
                            <form
                                onSubmit={(e) => e.preventDefault()}
                                className="border d-flex end_round2 br_serachInp w-100"
                            >
                                <IconButton
                                    type="button"
                                    sx={{ p: "10px" }}
                                    aria-label="search"
                                >
                                    <SearchIcon />
                                </IconButton>
                                <InputBase
                                    size="medium"
                                    sx={{ flex: 1, paddingLeft: 1 }}
                                    placeholder={"Search by title,id and price"}
                                    value={searchValue}
                                    onChange={handleSearchInputChange}
                                />
                            </form>
                            <select
                                className="form-select TitleMenuWidth end_round m-0 br_serachSelect"
                                aria-label="Default select example"
                                value={searchBy}
                                onChange={(e) => setSearchBy(e.target.value)}
                            >
                                <option value="ListingId">ListingId</option>
                                <option value="ProjectName">Project Name</option>
                                <option value="Price">Price</option>
                            </select>
                        </div>
                    </div>
                </div>

                {/* ======== Status Filter Section ======== */}

                <div className="br-Statusbuttons single_userListingStatusBtn">
                    {ListingOwnerRole !== 'Buyer' && (
                        <>
                            <button className={`btn ${btnStatus === 'Active' ? 'br_ActivestatusBtn' : 'br_statusBtn'}`} onClick={() => setBtnStatus('Active')}>
                                Active
                            </button>
                            <button className={`btn ${btnStatus === 'Pending' ? 'br_ActivestatusBtn' : 'br_statusBtn'}`} onClick={() => setBtnStatus('Pending')}>
                                Pending
                            </button>
                            <button className={`btn ${btnStatus === 'Suspended' ? 'br_ActivestatusBtn' : 'br_statusBtn'}`} onClick={() => setBtnStatus('Suspended')}>
                                Suspended
                            </button>
                        </>
                    )}
                    <button className={`btn ${btnStatus === 'Favorite' ? 'br_ActivestatusBtn' : 'br_statusBtn'}`} onClick={() => setBtnStatus('Favorite')}>
                        Favorite
                    </button>
                    <button className={`btn ${btnStatus === 'Searches' ? 'br_ActivestatusBtn' : 'br_statusBtn'}`} onClick={() => setBtnStatus('Searches')}>
                        Searches
                    </button>
                    {/* <button className={`btn ${btnStatus === 'Conversations' ? 'br_ActivestatusBtn' : 'br_statusBtn'}`} onClick={() => setBtnStatus('Conversations')}>
                        Conversations
                    </button> */}
                </div>

                {/* ======== Card Section ======== */}
                {loader ?
                    <center className="text-center mt-4">
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="70"
                            visible={loader}
                        />
                    </center>
                    :
                    filteredCardData?.length === 0 ?
                        <>
                            <div className="no__FoundMain font">
                                <div className="no__Found__img">
                                    <img className='not_found_img_mobile' src={require("../../../../images/noResultFound.png")} alt="Result not found" loading="lazy"/>
                                </div>
                                <div className="no__FoundText font-family-regular not_found_Text_mobile">
                                    <p>No RecordFound</p>
                                </div>

                            </div>
                        </>
                        :
                        <>
                            {(btnStatus === 'Active' || btnStatus === 'Pending' || btnStatus === 'Suspended' || btnStatus === 'Favorite') && (
                                <div className="d-flex flex-wrap ActiveUserListing_card mt-4">
                                    {filteredCardData?.map((item, index) => {
                                        return (
                                            <UserListingCard key={index} setDrawerLoading={setDrawerLoading} selectedCards={selectedCards} setSelectedCards={setSelectedCards} ListingOwnerName={ListingOwnerName} item={item} />
                                        )
                                    })}
                                </div>
                            )}
                            {(btnStatus === 'Searches') && (
                                <div className="mt-4 d-flex flex-column user_savesearchesCardContainer">
                                    {filteredCardData?.map((item, index) => {
                                        return (
                                            <UserSaveSearchesCard key={index} item={item} />
                                        )
                                    })}
                                </div>
                            )}
                            {/* {(btnStatus === 'Conversations') && (
                                <div className="mt-4 d-flex flex-column user_savesearchesCardContainer">
                                    {filteredCardData?.map((item, index) => {
                                        return (
                                            <UserConversationCard key={index} ListingOwnerName={ListingOwnerName} item={item} />
                                        )
                                    })}
                                </div>
                            )} */}

                        </>
                }
            </section>
            {drawerLoading ? (
                <div className="scrollLoader fav_scrollLoader">
                    <Loader size="80" />
                    <p style={{ color: "#F8A23E", fontWeight: 600 }}>Loading....</p>
                </div>
            ) : (
                <Drawer
                    anchor="right"
                    open={isDrawerOpen(itemID)}
                    onClose={toggleDrawer("right", itemID)}
                >
                    <DrawerContent
                        item={draweritem}
                        anchor={"right"}
                        toggleDrawer={toggleDrawer}
                    />
                </Drawer>
            )}

            <ToastContainer />
        </DashboardLayout>
    )
}

export default UserAllListing;
import React, { useState } from 'react'
import DashboardLayout from '../../Dashboard'
import './stats.css'
import CountUp from 'react-countup';
import { useEffect } from 'react';
import baseURL from '../../../config';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import hotdeals from '../../../images/hotdeals.svg'
import pending from '../../../images/pending.svg'
import active from '../../../images/Active.svg'
import suspend from '../../../images/suspend.svg'
import justReached from '../../../images/justreached.svg'
import justReduced from '../../../images/justreduced.svg'
import fastMoving from '../../../images/fastmoving.svg'
import { RotatingLines } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';


const Statistics = () => {

    const navigate = useNavigate()
    const cookies = new Cookies();
    const jwt_token = cookies.get("jwt_authorization");
    const [data, setData] = useState({})
    const [loading, setloading] = useState(true);


    const fecthStatisticsData = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${jwt_token}`,
            };
            const response = await axios.get(
                `${baseURL}/api/Admin/GetStatistics`,
                { headers }
            );
            if (response) {
                const StatsData = response?.data?.data;
                setData(StatsData)
                setloading(false)
            }
        } catch (error) {
            setloading(false)
            toast.error(error);
        }
    }

    useEffect(() => {
        if (!jwt_token) {
            navigate('/login');
            return;
        }
        setloading(true);
        fecthStatisticsData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <DashboardLayout>
                <section className='page-content'>
                    <div className=' '>

                        {
                            loading === true ? (
                                <center className="mt-5">
                                    <RotatingLines
                                        strokeColor="grey"
                                        strokeWidth="5"
                                        animationDuration="0.75"
                                        width="70"
                                        visible={loading}
                                    />
                                </center>
                            ) : (
                                <>
                                    <div className='mobile4remMarginTop me-5'>
                                        <div className="flex-container mt-2">

                                            <div className="card-stats bg-Active" onClick={() => navigate('/dashboard/stats/Active')}>
                                                <div className='d-flex aligncenter justify-content-between '>
                                                    <span className='fw-normal'> Active </span>
                                                    <img className='mobileresponsive_img' src={active} alt="Active Icon" loading="lazy"/>

                                                </div>
                                                <h3 className='fw-bold text-start'>
                                                    <CountUp start={0}
                                                        end={data?.active}
                                                        duration={2.75}
                                                        separator=","
                                                        decimal=","
                                                    />
                                                </h3>
                                            </div>


                                            <div className="card-stats bg-Pending" onClick={() => navigate('/dashboard/stats/Pending')}>
                                                <div className='d-flex aligncenter justify-content-between '>
                                                    <span className='fw-normal'> Pending </span>
                                                    <img className='mobileresponsive_img' src={pending} alt="Pending Icon" loading="lazy"/>

                                                </div>
                                                <h3 className='fw-bold text-start'>

                                                    <CountUp start={0}
                                                        end={data?.pending}
                                                        duration={2.75}
                                                        separator=","

                                                        decimal=","
                                                    />
                                                </h3>
                                            </div>


                                            <div className="card-stats bg-Suspend" onClick={() => navigate('/dashboard/stats/Suspended')}>
                                                <div className='d-flex aligncenter justify-content-between '>
                                                    <span className='fw-normal'> Suspended </span>
                                                    <img className='mobileresponsive_img' src={suspend} alt="Suspended Icon" loading="lazy"/>

                                                </div>
                                                <h3 className='fw-bold text-start'>
                                                    <CountUp start={0}
                                                        end={data?.suspended}
                                                        duration={2.75}
                                                        separator=","

                                                        decimal=","
                                                    />
                                                </h3>
                                            </div>
                                        </div>


                                        <div className="flex-container mt-4">

                                            <div className="card-stats">
                                                <div className='d-flex  justify-content-between '>
                                                    <span className='color-white fw-normal'>Hot Deals</span>
                                                    <img className='mobileresponsive_img' src={hotdeals} alt="Hot deals Icon" loading="lazy"/>
                                                </div>
                                                <h3 className='fw-bold text-start'>
                                                    <CountUp start={0}
                                                        end={data?.hotDeals}
                                                        duration={2.75}
                                                        separator=","

                                                        decimal=","
                                                    />
                                                </h3>
                                            </div>


                                            <div className="card-stats">
                                                <div className='d-flex justify-content-between '>
                                                    <span className='color-white fw-normal'>Just Reached</span>
                                                    <img className='mobileresponsive_img' src={justReached} alt="Just Reached Icon" loading="lazy"/>
                                                </div>
                                                <h3 className='fw-bold text-start'>
                                                    <CountUp start={0}
                                                        end={data?.justReached}
                                                        duration={2.75}
                                                        separator=","

                                                        decimal=","
                                                    />
                                                </h3>
                                            </div>


                                            <div className="card-stats">
                                                <div className='d-flex justify-content-between '>
                                                    <span className='color-white fw-normal '>Price Change </span>
                                                    <img className='mobileresponsive_img' src={justReduced} alt="Price change icon" loading="lazy"/>
                                                </div>
                                                <h3 className='fw-bold '>
                                                    <CountUp start={0}
                                                        end={data?.priceChange}
                                                        duration={2.75}
                                                        separator=","
                                                        decimal=","
                                                    />
                                                </h3>
                                            </div>


                                            <div className="card-stats       ">
                                                <div className='d-flex justify-content-between '>
                                                    <span className='color-white fw-normal'>Fast Moving </span>
                                                    <img className='mobileresponsive_img' src={fastMoving} alt="Fast moving Icon" loading="lazy"/>
                                                </div>
                                                <h3 className='fw-bold '>
                                                    <CountUp start={0}
                                                        end={data?.fastMoving}
                                                        duration={2.75}
                                                        separator=","

                                                        decimal=","
                                                    />
                                                </h3>

                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                    </div>
                </section>
                <ToastContainer />
            </DashboardLayout >
        </>

    )
}

export default Statistics; 
import React, { useEffect, useState } from "react";
import axios from "axios";
import baseURL from "../../../config";
import Cookies from "universal-cookie";
import { RotatingLines } from "react-loader-spinner";
import Maillistings from "./SubComponents/Maillistings";
import MailTemplate from "./SubComponents/MailTemplate";
import { useSelector } from "react-redux";
export default function RefinedEmails({ mailInbox, setMailInbox }) {
  const mailRefresh = useSelector((state) => state.refreshMail);
  const [activeId, setActiveId] = useState();
  const [loading, setloading] = useState(true);
  const [mails, setMails] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const cookies = new Cookies();
  const jwt_token = cookies.get("jwt_authorization");
  const FetchData = () => {
    setloading(true);
    const headers = {
      Authorization: `Bearer ${jwt_token}`,
    };
    axios
      .get(`${baseURL}/api/Admin/FetchEmail/${mailInbox}`, { headers })
      .then((response) => {
        const mail = response?.data?.data || [];
        setMails(mail);
        setloading(false);
      })
      .catch((error) => {
        console.error(error);
        setloading(false);
      });
  };
  const RefreshData = () => {
    setloading(true);
    const headers = {
      Authorization: `Bearer ${jwt_token}`,
    };
    axios
      .get(`${baseURL}/api/Admin/RefreshEmail/${mailInbox}`, { headers })
      .then((response) => {
        const mail = response?.data?.data || [];
        setMails(mail);
        setloading(false);
      })
      .catch((error) => {
        console.error(error);
        setloading(false);
      });
  };
  useEffect(() => {
    if (!isRefresh) {
      FetchData();
    } else {
      setIsRefresh(false);
    }
    // eslint-disable-next-line
  }, [mailInbox]);

  useEffect(() => {
    if (isRefresh) {
      RefreshData();
    } else {
      setIsRefresh(true);
    }
    // eslint-disable-next-line
  }, [mailRefresh]);
  const ButtonBack = () => {
    setMailInbox("");
  };
  return (
    <div className="MobilE_____Margins">
      {loading ? (
        <div className="text-center">
          <RotatingLines strokeColor="grey" />
        </div>
      ) : (
        <>
          {mails?.fetchedEmails && mails.fetchedEmails.length > 0 ? (
            <div className="row">
              <div className="col-4 p-1 separation">
                <Maillistings
                  mails={mails}
                  setActiveId={setActiveId}
                  setMailInbox={setMailInbox}
                />
              </div>
              <div className="col-8 scrollsxe2">
                {activeId ? (
                  <>
                    <MailTemplate activeId={activeId} mails={mails} />
                  </>
                ) : (
                  <div className="d-flex p-3 align-items-center mb-5">
                    <div className="mt-5 Click_SP">
                      <span className="Click_SP"> Click on a </span>
                      <br />
                      <span className="Convo_Sp">Conversation </span> <br />
                      To View its Contents!!...
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-center mt-5 Click_SP">
                No Data Available To View !! 
              </div>
              <div className="d-flex justify-content-center">
                <button onClick={ButtonBack} className="btn btn-primary">
                  Go back
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

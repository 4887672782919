import { useLocation } from "react-router-dom";
import "./MortgageAdvisorDetails.css";
import Footer from "../Footer/Footer";
import { TbMessageDots } from "react-icons/tb";
import { IoCallOutline } from "react-icons/io5";

const MortgageAdvisorDetails = () => {
  const location = useLocation();
  // const { detailsData } = location.state || {};
  const data = location.state.detailsData;
  return (
    <div className="MortgageAdvisor_details">
      <div className="MortgageAdvisor_Gradient">
        <div className="MortgageAdvisor_Details">
          <div className="MortgageAdvisor_Pic">
            <img src={data?.image} alt={`${data?.name} Profile`} loading="lazy"/>
          </div>
         <div className="advisor_allDet">
         <div className="Advisor_InfrmName">
            <h3>{data?.name}</h3>
          </div>
          <div className="Advisor_InfrmLevel">
            <p>Agent Level 2</p>
          </div>
          <div className="Advisor_InfrmRate">
            <p>#12700</p>
          </div>
          <div className="Advisor_InfrmLanguage">
            <p>{`${data?.language} Speaking`}</p>
          </div>
          <div className="mortgageDetails_Icons">
            <div className="mortgageDetCallIcon mx-2">
              <TbMessageDots className="mortgage_iconsdet" />
            </div>
            <div className="mortgageDetCallIcon mx-2">
              <IoCallOutline className="mortgage_iconsdet" />
            </div>
          </div>
         </div>
        </div>
      </div>

      <div className="MortgageDetailsSection">
        <div className="Advisor_Address advisorDetailsBox">
          <h3 className="AdvisorBox_heading">Metropolis at Metropolitan</h3>
          <p className="AdvisorBox_des">
            Unit 1143 - 4700 KingswayBurnaby, BC, V5H 4M1
          </p>
        </div>

        <div className="Advisor_Call advisorDetailsBox">
          <h3 className="AdvisorBox_heading">Call</h3>
          <div className="Advisor_callSection">
            <div className="AdvisorCall_headings">
              <p className="AdvisorBox_des">Local:</p>
              <p className="AdvisorBox_des">Toll Free:</p>
              <p className="AdvisorBox_des">Fax:</p>
            </div>
            <div className="AdvisorCall_Details">
              <p className="AdvisorBox_des">1-778-654-7931</p>
              <p className="AdvisorBox_des">1-778-654-7931</p>
              <p className="AdvisorBox_des">1-778-654-7931</p>
            </div>
          </div>
        </div>

        <div className="AdvisorCall_hours advisorDetailsBox">
          <h3 className="AdvisorBox_heading">Hours</h3>
          <div className="Advisor_callSection">
            <div className="AdvisorCall_headings">
              <p className="AdvisorBox_des">Mon-Fri:</p>
              <p className="AdvisorBox_des">Saturday:</p>
              <p className="AdvisorBox_des">Sunday:</p>
            </div>
            <div className="AdvisorCall_Details">
              <p className="AdvisorBox_des">9:00 am-6:00 pm</p>
              <p className="AdvisorBox_des">9:00 am-6:00 pm</p>
              <p className="AdvisorBox_des">9:00 am-6:00 pm</p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="advisorMap_des">
          <div className="advisor_map">
              <iframe
                title="Google Map"
                width="100%"
                height="100%"
                style={{ border: '1px solid #676767', borderRadius: "20px" }}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3243.595248679713!2d-122.08398878461643!3d37.4224243396231!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f80e66614f07d%3A0xd8f1e4b24d887f87!2sGolden%20Gate%20Bridge!5e0!3m2!1sen!2sus!4v1632809337498!5m2!1sen!2sus"
                allowFullScreen
              ></iframe>
          </div>

          <div className="advisor_des">
            <h3 className="advisor_desHead">
              Get your best rate and (mortgage) peace of mind.
            </h3>
            <p className="advisorDes_details">
              "In today's lightning-fast housing market, securing a smooth,
              stress-free mortgage experience is paramount.
            </p>
            <p className="advisorDes_details">
              When it comes to this significant financial decision, peace of
              mind is priceless. That's where I come in!
            </p>
            <p className="advisorDes_details">
              With over two decades of experience in retail banking, I offer an
              exceptional mortgage journey tailored to your unique needs,
              ensuring you get the best rate and mortgage product.
            </p>
            <p className="advisorDes_details">
              I handle all the nitty-gritty details, liaising with lenders on
              your behalf, so you can focus on your busy life! Whether you're
              buying, renewing, or refinancing your home, or eyeing a vacation
              property — I'm here to guide you every step of the way.
            </p>
            <p className="advisorDes_details">
              I'm fluent in both English and Punjabi to address your mortgage
              needs. Contact me today!"
            </p>
          </div>
        </div>
      </div>

      <div className="advisor_footer">
        <Footer />
      </div>
    </div>
  );
};
export default MortgageAdvisorDetails;

// packageData.js

export const packageData = {
    TopHomePageGallery: [
        {
            days: 7,
            price: 5.5,
        },
        {
            days: 14,
            price: 10,
        },
        {
            days: 30,
            price: 15,
        },
    ],
    HomePageTicker: [
        {
            days: 7,
            price: 7.0,
        },
        {
            days: 14,
            price: 14,
        },
        {
            days: 30,
            price: 25,
        },
    ],
    HighlightAdd: [
        {
            days: 7,
            price: 8.0,
        },
        {
            days: 14,
            price: 16,
        },
        {
            days: 30,
            price: 30,
        },
    ],
    Tags: [
        {
            days: 7,
            price: 10,
        },
        {
            days: 14,
            price: 12,
        },
        {
            days: 30,
            price: 20,
        },
    ],
};

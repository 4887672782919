import React from "react";

export default function OffersandNegotiations() {

  return (
    <div className="text-center">
      <div className="d-flex flex-column align-items-center">
        <h1 className="text-primary font-weight-bold">Negotitate and close the deal</h1>

        <ul className="text-start mt-3" style={{listStyle: 'disc'}}>
          <li>
            Your offers, negotiations and the paperwork can be handled for you.
          </li>
          <li>
            You have the option to have buyers or agents send offers directly to
            your custom dashboard.
          </li>
          <li>
            You can access our experienced real estate lawyers to provide
            guidaance and advice
          </li>
        </ul>

        <p className="text-center mt-4" style={{fontWeight:'500'}}>
          <strong>Buyers can enjoy our handy OfferMaker. </strong>
        </p>
        <center>
          <a href='#findOffice'>
            <button className="btn learn_moreBtnTabs text-center m-0">
              Learn More
            </button>
          </a>
        </center>
      </div>
    </div>
  );
}

import mapboxgl from "mapbox-gl";
import DevelopmentToken from "../../../MapBoxToken";
import { useState, useRef, useEffect } from 'react';

const MapandListingDrawerMap = ({ longitude, latitude }) => {
    const [, setDataRec] = useState(false);
    mapboxgl.accessToken = `${DevelopmentToken}`;
    const mapContainer = useRef(null);
    const map = useRef(null);
    const marker = useRef(null);

    useEffect(() => {
        const initializeMap = () => {
            if (!map.current) {
                map.current = new mapboxgl.Map({
                    container: mapContainer.current,
                    style: 'mapbox://styles/mapbox/streets-v12',
                    center: [longitude, latitude],
                    zoom: 10
                });

                marker.current = new mapboxgl.Marker({
                    color: "red"
                })
                .setLngLat([longitude, latitude])
                .addTo(map.current);

                setDataRec(true);
            } else {
                marker.current.setLngLat([longitude, latitude]);
            }
        };

        if (typeof longitude === "number" && typeof latitude === "number") {
            initializeMap();
        } else {
            setDataRec(false);
        }

        return () => {
            if (map.current) {
                map.current.remove();
                map.current = null;
            }
        };
    }, [longitude, latitude]);

    return (
        <>
            <div ref={mapContainer} className="map-container" style={{ width: '100%', height: '400px' }} />
        </>
    );
}

export default MapandListingDrawerMap;

import { createSlice } from "@reduxjs/toolkit";

const MapBoxDataSlice = createSlice({
  name: "MapBoxDataSlice",
  initialState: [], 
  reducers: {
    addMapData: (state, action) => {
      state.push(action.payload);
    },
    removeMapData: () => {
      return [];
    },
  },
});

export const { addMapData, removeMapData } = MapBoxDataSlice.actions;
export default MapBoxDataSlice.reducer;


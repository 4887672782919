import * as Yup from "yup";

export const NewPasswordSchema = Yup.object({
    password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
      "Password must be at least 8 characters long and have a mix of lowercase and uppercase letters and numbers"
    )
    .required("This field is required"),
  cpassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("This field is required"),
});

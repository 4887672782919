import React, { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Components/Navbar/Navbar";

const WithNavbarLayout = ({ children }) => {
  const location = useLocation();
  const [prevPathname, setPrevPathname] = useState(location?.pathname);
  const routeChanged =
    location.pathname !== prevPathname &&
    !location.pathname.startsWith('/assignment-sale/')

  useLayoutEffect(() => {
    if (routeChanged) {
      window.scrollTo(0, 0);
      setPrevPathname(location.pathname);
    }
  }, [location.pathname, routeChanged]);

  return (
    <>
      <div style={{ position: "sticky", top: 0, zIndex: 1100 }}>
        <Navbar />
      </div>
      <div>{children}</div>
    </>
  );
};

export default WithNavbarLayout;

import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import Navbar from '../../Navbar/MobileNavbar';
import { BsDot } from 'react-icons/bs'

const MobileSavedSearch = ({ row }) => {
    const [showComponent, setShowComponent] = useState(false);
    const [selectedUserData, setSelectedUserData] = useState("");
    const formatter = new Intl.NumberFormat();
    const handleButtonClick = (data) => {
        setSelectedUserData(data)
        setShowComponent(true);
    };

    const handleCloseModal = () => {
        setShowComponent(false)
    }
    
    function Dateformatter() {
        const dateStr = row?.created;
        const date = new Date(dateStr);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString(undefined, options);
        return formattedDate;

    }
    const DetailsSearch = () => {
        return (
            <>
                <Navbar showComponent={showComponent} setShowComponent={setShowComponent} />
                <Modal aria-labelledby="contained-modal-title-vcenter" className='mt-3 '
                    centered show={showComponent} fullscreen={true} onHide={handleCloseModal}>
                    <Modal.Body className='  mt-5 '>
                        <div className='d-flex justify-content-between'>
                            <div >
                                <h3 className='text-primary fw-bold'>
                                    Search Details
                                </h3>
                            </div>
                            <div className='d-flex flex-column font13 text-secondary'>
                                <span>{Dateformatter()}</span>
                            </div>
                        </div>

                        <div className='border rounded mt-4 shadow-sm'>
                            <h3 className=' text-primary p-2 fs-5'> {selectedUserData?.searchName}</h3>
                            <hr className='m-0' />
                            <ul className='p-2 mb-0 font13 d-flex flex-wrap list-styled'>
                                {
                                    selectedUserData?.searchparameters?.maxPrice &&
                                    <li className='px-2' >
                                        <BsDot /> <strong>Price : </strong>    ${formatter.format(selectedUserData?.searchparameters?.minPrice)}-${formatter.format(selectedUserData?.searchparameters?.maxPrice)}
                                    </li>
                                }
                                {
                                    selectedUserData?.searchparameters?.maxSqft &&
                                    <li className='px-2' >
                                        <BsDot /> <strong>Area : </strong>  {formatter.format(selectedUserData?.searchparameters?.minSqft)}-{formatter.format(selectedUserData?.searchparameters?.maxSqft)}Sqft
                                    </li>
                                }
                                {
                                    selectedUserData?.searchparameters?.maxRadius &&
                                    <li className='px-2' >
                                        <BsDot /> <strong>Radius : </strong> {formatter.format(selectedUserData?.searchparameters?.minRadius)} -{formatter.format(selectedUserData?.searchparameters?.maxRadius)}KM
                                    </li>
                                }
                                {
                                    selectedUserData?.searchparameters?.searchparameters_Citys && selectedUserData.searchparameters.searchparameters_Citys.length > 0 &&
                                    <li className='px-2' >

                                        <>
                                            <BsDot /> <strong>City : </strong>
                                            {selectedUserData.searchparameters.searchparameters_Citys
                                                .filter((city) => city.parameter) // Filter out empty values
                                                .map((city) => city.parameter) // Extract parameter values
                                                .join(", ")
                                            }
                                        </>

                                    </li>
                                }

                                {
                                    selectedUserData?.searchparameters?.searchparameters_Beds && selectedUserData.searchparameters.searchparameters_Beds.length > 0 &&
                                    <li className='px-2' >
                                        <>
                                            <BsDot /> <strong>Bedrooms : </strong>
                                            {selectedUserData.searchparameters.searchparameters_Beds
                                                .filter((city) => city.parameter) // Filter out empty values
                                                .map((city) => city.parameter) // Extract parameter values
                                                .join(", ")
                                            }
                                        </>
                                    </li>
                                }
                                {
                                    selectedUserData?.searchparameters?.searchparameters_Baths && selectedUserData.searchparameters.searchparameters_Baths.length > 0 &&
                                    <li className='px-2' >
                                        <>
                                            <BsDot /> <strong>Bathrooms : </strong>
                                            {selectedUserData.searchparameters.searchparameters_Baths
                                                .filter((city) => city.parameter) // Filter out empty values
                                                .map((city) => city.parameter) // Extract parameter values
                                                .join(", ")
                                            }
                                        </>
                                    </li>
                                }
                                {
                                    selectedUserData?.searchparameters?.searchparameters_Parkings && selectedUserData.searchparameters.searchparameters_Parkings.length > 0 &&
                                    <li className='px-2' >
                                        <>
                                            <BsDot /> <strong>Bedrooms : </strong>
                                            {selectedUserData.searchparameters.searchparameters_Parkings
                                                .filter((city) => city.parameter) // Filter out empty values
                                                .map((city) => city.parameter) // Extract parameter values
                                                .join(", ")
                                            }
                                        </>
                                    </li>
                                }
                                {
                                    selectedUserData?.searchparameters?.status &&
                                    <li className='px-2' >
                                        <BsDot /> <strong>Status : </strong> {selectedUserData?.searchparameters?.status}
                                    </li>
                                }
                                {
                                    selectedUserData?.searchparameters?.searchparameters_Types && selectedUserData.searchparameters.searchparameters_Types.length > 0 &&
                                    <>
                                        <br />
                                        <li className='px-2 w-100' >
                                            <>

                                                <BsDot /> <strong>Property Type : </strong> <br />
                                                <div className='ms-3'>
                                                    {selectedUserData.searchparameters.searchparameters_Types
                                                        .filter((city) => city.parameter) // Filter out empty values
                                                        .map((city) => city.parameter) // Extract parameter values
                                                        .join(", ")
                                                    }
                                                </div>

                                            </>
                                        </li>
                                    </>

                                }

                            </ul>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='brodernone d-flex justify-content-center mt-3 mb-3'>
                    </Modal.Footer>
                </Modal>
            </>
        );
    };

    return (
        <>
            <div>
                <div className='d-flex border py-4 px-2 '>
                    <div className='pe-2'>
                        <input type="checkbox" />
                    </div>

                    <div className='d-flex justify-content-between w-100'>
                        <div className='d-flex flex-column ps-2 fontsmall'>
                            <span className='fw-bold Font16'>{row?.searchName} </span>
                            {row?.searchparameters?.maxRadius && <span >Radius : {formatter.format(row?.searchparameters?.minRadius)} - {formatter.format(row?.searchparameters?.maxRadius)} Km</span>}
                            <span className='mt-1'>Since: {Dateformatter()}</span>
                        </div>
                        <button onClick={() => handleButtonClick(row)} className='border rounded fontsmall primary-bg text-white' style={{ padding: "6px 12px", height: "30px" }}>
                            Details
                        </button>
                    </div>
                </div>
            </div>
            {showComponent && <DetailsSearch />}
        </>
    )
}

export default MobileSavedSearch

import "./ResultNotFound.css"


const ResultNotFound  = (props) =>{
    return(
        <div className="no__FoundMain font">
            <div className="no__Found__img">
                <img src={require("../../../images/noResultFound.png")}  alt="Result not found" loading="lazy"/>
            </div>
        <div className="no__FoundText">
            <p>We appologize, but it seems that no result were found for your search</p>
        </div>
        <div>
            <button className="no__FoundButton" onClick={props.onClick}>
                Refine Your Search
            </button>
        </div>
        </div>
    )
}

export default ResultNotFound;
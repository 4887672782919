import * as React from "react";
import { useState } from "react";
import CardMain from "../../../HomePage/SubComponents/MapandListing/CardMain";
import "../../../HomePage/SubComponents/CardListing/Card.css";
import "../../../../src/Components.css";
import "../../../../src/App.css";
import "./FavCards.css";
import DrawerContent from "../../../HomePage/SubComponents/MapandListing/DrawerContent";
import Loader from "../../../HomePage/SubComponents/MapandListing/Loader";
import baseURL from "../../../config";
import axios from "axios";
import NotFoundImge from "../../../images/EmptyFav.png";
import Cookies from "universal-cookie";
import Drawer from "@mui/material/Drawer";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { removePdfData } from "../../../ReduxStore/Slices/PdfDownloadSlice";
import { Helmet } from 'react-helmet';
import CardMainSkeleton from "../../../HomePage/SubComponents/MapandListing/CardMainSkeleton";

export default function FavouriteCards() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const jwt_token = cookies.get("jwt_authorization");
  const dispatch = useDispatch();

  const favouriteData = useSelector((state) => state.heartDataSlice);
  const [data, setData] = useState([]);
  const [drawerLoading, setDrawerLoading] = useState(false);
  const [cardToShow, setCardToShow] = useState([]);

  //Drawer open even refresh
  const slug = useParams();
  const location = useLocation();
  // eslint-disable-next-line
  const path = location.pathname;
  const slugPresent = slug.id;
  const [draweritem, setDrawerItem] = useState();
  const [itemID, setItemID] = useState(1);
  const { searchData } = location.state || {};
  useEffect(() => {
    if (searchData === undefined) {
      if (slugPresent) {
        axios
          .get(`${baseURL}/api/Assignment/GetAssignment/${slugPresent}`)
          .then((response) => {
            if (response?.data?.data) {
              setDrawerItem(response?.data?.data);
              setOpenDrawer(response?.data?.data?.id);
              setItemID(response?.data?.data?.id);
              setDrawerLoading(false)
            } else {
              toast.error("There is no Data for this Assignment");
              navigate("/dashboard/favourite");
              setDrawerLoading(false)
            }
          })
          .catch((error) => {
            navigate("/dashboard/favourite");
            toast.error('Error in fetching Listing');
            setDrawerLoading(false)
          });
      }
    }
    // eslint-disable-next-line
  }, [slugPresent]);

  useEffect(() => {
    const handlePopstate = () => {
      const currentPath = window.location.pathname;
      if (currentPath === "/dashboard/favourite") {
        setOpenDrawer(null);
        setItemID(1);
        dispatch(removePdfData());
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [dispatch]);

  const [openDrawer, setOpenDrawer] = useState(null);
  const toggleDrawer = (anchor, itemId) => () => {
    setOpenDrawer(null);
    setItemID(1);
    dispatch(removePdfData());
    navigate("/dashboard/favourite");
  };

  const isDrawerOpen = (itemId) => {
    // eslint-disable-next-line
    return openDrawer == itemId;
  };

  // Loader
  const [isLoading, setIsLoading] = useState(true);

  const handleFavoriteUpdate = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/api/User-Data/GetFavouriteByUser`,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      if (response?.data?.result === 1) {
      const Favdata = response?.data?.data?.map((item) => item.assignment);
      setData(Favdata);
      setIsLoading(false);
    }
    else{
      setIsLoading(false);
      toast.error('There is problem in fetching data');
    }
  } catch (error) {
      setIsLoading(false);
      toast.error('There is problem in fetching data');
    }
  };

  useEffect(() => {
    if (!jwt_token) {
      return navigate('/login', { state: { returnUrl: `dashboard/favourite` } })
    }
    handleFavoriteUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    const commonIds = favouriteData?.favItems?.map((item) => item.id);
    const filteredData = data?.filter((item) => commonIds.includes(item.id));
    setCardToShow(filteredData);
  }, [data, favouriteData]);

  return (
    <div className={` ${isLoading ? "aligncenter justifycenter" : ""}  col-12`}>
      <Helmet>
        <title>AssignmentSold.ca</title>
      </Helmet>

      {isLoading ? (
        <div
          className="d-flex flex-wrap justify-content-start mb-3 mob_fav_card"
          style={{ gap: "15px" }}
        >
          {Array.from(new Array(8))?.map((_, index) => (
            <CardMainSkeleton key={index} />
          ))}
        </div>
      ) : (
        <>
          {cardToShow?.length === 0 ? (
            <div className="no__FoundMain font py-0 Mobile__Justigy__Start ">
              <div className="font">
                <h2 className="fw-bold webvisibility_None">
                  Favorite Assignments
                </h2>
                <p>
                  You have not saved any favourite assignments yet. To save an
                  Assignment as a favourite is simple.{" "}
                </p>
              </div>
              <div className=" ">
                <img
                  className="ReponsiveWidth"
                  src={NotFoundImge}
                  alt="No Favourite"
                  style={{ width: '320px' }}
                  loading="lazy"
                />
              </div>
            </div>
          ) : (
            <>
              <div
                className="d-flex flex-wrap justify-content-start mb-3 mob_fav_card"
                style={{ gap: "15px" }}
              >
                {cardToShow?.length !== 0 &&
                  cardToShow?.map((item, index) => (
                    <CardMain
                      key={index}
                      item={item}
                      cardPath={"dashboard/favourite/"}
                      setDrawerLoading={setDrawerLoading}
                    />
                  ))}
              </div>
            </>
          )}
        </>
      )}
      {drawerLoading ? (
        <div className="scrollLoader fav_scrollLoader">
          <Loader size="80" />
          <p style={{ color: "#F8A23E", fontWeight: 600 }}>Loading....</p>
        </div>
      ) : (
        <Drawer
          anchor="right"
          open={isDrawerOpen(itemID)}
          onClose={toggleDrawer("right", itemID)}
        >
          <DrawerContent
            item={draweritem}
            anchor={"right"}
            toggleDrawer={toggleDrawer}
          />
        </Drawer>
      )}
      <ToastContainer />
    </div>
  );
}

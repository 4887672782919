import './Pricing.css';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { Elite_Pkg, Premium_Pkg, Starter_Pkg } from './PricingData';
export default function PricingCard({ redirect }) {
    const cookies = new Cookies();
    const jwt_token = cookies.get("jwt_authorization");
    const isLogedIn = localStorage.getItem('anonymous');
    const isUserAuthorize = jwt_token && isLogedIn === '1';
    let emailConfirmed = false;
    if (jwt_token) {
        const decoded = jwtDecode(jwt_token);
        const emailConfirmedStr = decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/anonymous'];
        emailConfirmed = emailConfirmedStr === "True";
    }

    const updated_Premium_Pkg = [...Starter_Pkg, ...Premium_Pkg];
    const updated_Elite_Pkg = [...updated_Premium_Pkg, ...Elite_Pkg]
    const navigate = useNavigate()
    return (
        <div className="card-container">
            {/* --------------------------------------------------------------first card---------------- */}

            <div className="pricing-card pricing_cardn stater_cardn">
                <div className="card-title">
                    <h2>
                        <strong className="text-black font">Starter</strong>
                    </h2>
                </div>
                <div className="card-desc text-black">
                    <p>
                        Our customer approach comes with a no-obligation FREE consultation
                        aimed at tailor- ing solution based on your needs.
                    </p>
                </div>
                <div className="card-priceStarter">
                    <h1>
                        <strong className="text-black font">Free</strong>
                    </h1>
                    <button onClick={() => isUserAuthorize ?
                        (emailConfirmed ?
                            navigate(`/${redirect}`, { state: { price: "0", border: "starter-border", color: "starter-color", name: "Starter" } })
                            :
                            navigate("/email-confirmation?isEmailConfirmed=false"))
                        :
                        navigate('/login', { state: { returnUrl: window.location.pathname } })
                    } className="card-btn selectedPkg_btn">List Now</button>
                </div>
                <div className='mt-4'>
                    <p className="fst-italic text-black">includes:</p>
                </div>
                <div className="includes pb-5 mob_features mt-4">
                    {Starter_Pkg?.map((e, index) => (
                        <div key={index} className="features mt-0">
                            <div className='px-3 text-black'>
                                <CheckCircleIcon />
                            </div>
                            <p className='text-black'>{e}</p>
                            <div className="info-icon">
                                <InfoOutlinedIcon />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* ----------------------------------------Second Card-------------------------------- */}

            <div className="pricing-card pricing_cardn">

                <div className="card-title">
                    <h2>
                        <strong className='font text-black'>Premium</strong>
                    </h2>
                </div>
                <div className="card-desc text-black">
                    <p>
                        This is the perfect options for those who would have used the real
                        estate agent in the past. We help you confidently navigate your
                        journey from listing to pricing to SOLD.
                    </p>
                </div>
                <div className="card-pricePremium">
                    <h1>
                        <strong className='font text-black'>Custom</strong>
                    </h1>
                    <button
                        //  onClick={() => isUserAuthorize ?
                        //     (emailConfirmed ? navigate(`/${redirect}`, { state: { price: "15,000", border: "preminum-border", color: "text-primary", name: "Premium" } })
                        //         : navigate("/email-confirmation?isEmailConfirmed=false"))
                        //     : navigate('/login', { state: { returnUrl: window.location.pathname } })}
                        className="card-btn choosePkg_btn">Coming Soon</button>
                </div>
                <div className='mt-4'>
                    <p className="fst-italic text-black">includes:</p>
                </div>
                <div className="includes pb-5 mob_features mt-3">
                    {updated_Premium_Pkg.map((e, index) => (
                        <div key={index} className="features text-black mt-0">
                            <div className='text-black px-3'>
                                <CheckCircleIcon />
                            </div>
                            <p>{e}</p>
                            <div className="info-icon">
                                <InfoOutlinedIcon />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* ---------------------------Third Card----------------------------------------- */}
            <div className="pricing-card pricing_cardn elite_cardn">


                <div >
                    <div className="card-title">
                        <h2>
                            <strong className="text-black font">Elite</strong>
                        </h2>
                    </div>
                    <div className="card-desc text-black">
                        <p>
                            This is the perfect solution which can be custom tailored to meet
                            all of your specific needs. It includes everything from the
                            premium package while providing you with a range of optional
                            licensed real estate services which are only payable upon a
                            successful sale.
                        </p>
                    </div>
                    <div className="card-price">
                        <h1>
                            <strong className="text-black font">Custom</strong>
                        </h1>
                        <button
                            //  onClick={() =>
                            //     isUserAuthorize ?
                            //         (emailConfirmed ?
                            //             navigate(`/${redirect}`, { state: { price: "50,000", border: "elite-border", color: "elite-color", name: "Elite" } })
                            //             : navigate("/email-confirmation?isEmailConfirmed=false"))
                            //         : navigate('/login', { state: { returnUrl: window.location.pathname } })} 
                            className="card-btn choosePkg_btn">Coming Soon
                        </button>
                    </div>
                    <div className='mt-4'>
                        <p className="fst-italic text-black">includes:</p>
                    </div>
                    <div className="includes mob_features mt-4 text-white pb-5">
                        {updated_Elite_Pkg.map((e, i) => (
                            <div key={i} className="features mt-0">
                                <div className='text-black px-3'>
                                    <CheckCircleIcon />
                                </div>
                                <p className='text-black'>{e}</p>
                                <div className="info-icon">
                                    <InfoOutlinedIcon />
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </div >
    )
}
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProfileDashboard from '../Dashboard/Components/Profile/ProfileDashboard';
// import MessageListing from "../Dashboard/Components/Messages/MessageListing";
import EmailInboxes from '../Dashboard/Components/MailInboxes/EmailInboxes';
import RefinedImages from '../Dashboard/Components/RefinedImages/RefinedImages';
// import Conversation from "../Dashboard/Components/Conversation/Conversation";
import SavedSearchDashboard from "../Dashboard/Components/SavedSearch/SavedSearchDashboard";
import WebHighlights from "../Dashboard/Components/WebHightlights/WebHighlights";
import NotFoundPage from '../Components/404Page/NotfoundPage';
import WithNavbarLayout from './WithNavbarLayout';
import Statistics from '../Dashboard/Components/Statistics/Statistics';
import FavouritePage from '../Components/FavouritePage/FavouritePage';
import ListingGallary from '../Dashboard/Components/Listing/ListingGallary';
import MyListing from '../Dashboard/Components/Listing/MyListing';
// import UserListing from '../Dashboard/Components/Userlistings/userListing';
import BuyerRequest from '../Dashboard/Components/BuyerRequests/BuyerRequests';
import MyOrder from '../Dashboard/Components/OrderSummary/MyOrder';
import OrderSummary from '../Dashboard/Components/OrderSummary/OrderSummary';
import AllUserListings from '../Dashboard/Components/NewUserListing/AllUserListings';
import UserAllListing from '../Dashboard/Components/NewUserListing/Components/SingleUserAllListings';
import AllStatisticsListing from '../Dashboard/Components/Statistics/AllStatisticsListing';
// import UserConversationForAdmin from '../Dashboard/Components/NewUserListing/UserConversationForAdmin';
import { Helmet } from "react-helmet";

const DashboardRoutes = () => {
  return (
    <>
     <Helmet>
        <title>AssignmentSold.ca</title>
      </Helmet>
    <Routes>
      <Route exact path="/profile" Component={ProfileDashboard} />
      {/* <Route exact path="/users" element={<UserListing />} /> */}
      <Route exact path="/users" element={<AllUserListings />} />
      <Route exact path="/users/:id" element={<UserAllListing />} />
      {/* <Route exact path="/users-conversation/:id" element={<UserConversationForAdmin />} /> */}
      {/* <Route exact path="/Messages" Component={MessageListing} />
      <Route exact path="/Messages/:id" Component={MessageListing} />
      <Route exact path="/chat" Component={Conversation} /> */}
      <Route exact path="/refined-images" Component={RefinedImages} />
      <Route exact path="/admin-emails" Component={EmailInboxes} />
      <Route exact path="/saved-search" Component={SavedSearchDashboard} />
      <Route path="favourite" Component={FavouritePage} />
      <Route path="favourite/:id" Component={FavouritePage} />

      {/* <Route exact path="/home" Component={HomeDashboard} /> */}
      <Route exact path="/stats" Component={Statistics} />
      <Route exact path="/stats/:id" Component={AllStatisticsListing} />

      <Route exact path="/highlights" Component={WebHighlights} />
      <Route exact path="/buyer-request" Component={BuyerRequest} />
      <Route exact path="/buyer-request/:id" Component={BuyerRequest} />
      <Route exact path="/order-summary/:id" Component={MyOrder} />
      <Route exact path="/order-summary/details/:id" Component={OrderSummary} />
      <Route exact path="/assignments-listing/:id" Component={MyListing} />
      <Route exact path="/assignments-listing/gallery/:id" Component={ListingGallary} />
      <Route path="*" element={<WithNavbarLayout><NotFoundPage /></WithNavbarLayout>} />
    </Routes>
    </>
  );
};

export default DashboardRoutes;
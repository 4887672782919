import { createSlice } from "@reduxjs/toolkit";

const acivityIcon = createSlice({
    name: "acivityIcon",
    initialState: 0,
    reducers: {
        showActivity: () => {
        return 1;
      },
        hideActivity: () => {
        return 0;
      },
    },
  });
export const { showActivity, hideActivity } = acivityIcon.actions;
export default acivityIcon.reducer;
import React from "react";
import CustomOffer from '../../../../images/PriceTabimages/custom.png'
import { useNavigate } from "react-router-dom";

export default function HowToMakeOffer({isUserLogIn}) {


  const navigate = useNavigate()
  return (
    <div className="py-5">
      <div className="d-flex coloring ">
        <div className="custom_offer_img_circle">
          <img src={CustomOffer} alt='custom Offer' loading="lazy"/>
        </div>
        <div className="ps-5 Buyer_fullWwidthTabs">
          <h2>Offer and negotiate with ease</h2>

          <ul>
            <li>Once you find the ideal property and register, you can easily make offers and negotiate
              your deal using our Offer Maker tool. It simplifies and streamlines the offer process,
              ensuring it's safe and straightforward.</li>
            <li>
              Once key transaction details are captured and agreed upon using the Offer Maker, those details can be sent to your chosen legal professional for them to prepare an Agreement of Purchase and Sale.
            </li>
          </ul>
        </div>
      </div>
      <div className="text-center">
      <button className={"Buyer_fullWidthBtn"} onClick={()=>navigate('/login')} disabled={isUserLogIn}>{isUserLogIn ? 'Registered' : 'Register / JoinNow'}</button>
      </div>
    </div>
  );
}

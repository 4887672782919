import React, { useState, useEffect } from "react";
import Select from "@mui/material/Select";
import { MenuItem } from '@mui/material';
import { Box, InputLabel, FormControl, TextField } from "@mui/material";
import Slider from "@mui/material/Slider";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const MortgageCalculator = ({
  purchasePrice,
  handlePurchasePriceChange,
  region,
  formatedPurchasePrice,
  setDownValuePdf,
  setyearsValuePdf,
  setLandTaxPdf,
  setTotalMortgagePdf,
  setInterestPdf,
  setMonthlyMortgagePdf,
}) => {

  const data = useSelector((state) => state.adminSiteDataSlice);
  const interestFromAdmin = data[0]?.intrestRate;
  const [downPayment, setDownPayment] = useState(0);
  const [downValue, setDownValue] = useState(0);
  const [interestRate, setInterestRate] = useState(0);
  const [interestValue, setInterestValue] = useState(0);
  const purachsePriceTonumber = parseInt(purchasePrice);

  useEffect(() => {
    setInterestRate(interestFromAdmin / 100);
    setInterestValue(interestFromAdmin);
    setInterestPdf(interestFromAdmin);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interestFromAdmin]);

  const handleDownPAyChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9.]/g, "");
    setDownValue(numericValue);
  };

  const handleSliderChange = (event, newValue) => {
    setDownValue(event.target.value);
  };

  //handle Interest Change

  const handleInterestChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9.]/g, "");
    setInterestValue(numericValue);
    setInterestRate(numericValue / 100);
    // interestFromAdmin(numericValue); If there is any mistake unComment it
  };

  // Amortization years Handle
  const [amortizationYears, setAmortizationYears] = useState(30);
  const handleAmortizationChange = (event) => {
    setAmortizationYears(event.target.value);
    setyearsValuePdf(event.target.value);
  };

  //Term years handle
  const [termYearsValue, setTermsYearsValue] = useState("");
  const handleTermYearsChange = (event) => {
    setTermsYearsValue(event.target.value);
  };

  //Mortgage Calculations
  useEffect(() => {
    calculateDownPayment(purchasePrice);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchasePrice]);

  const calculateLandTransferTax = (purchasePrice) => {
    let fnfAmount = 0;
    const property = region;

    if (purchasePrice <= 55000) {
      fnfAmount = purchasePrice * 0.005;
    } else if (purchasePrice <= 250000) {
      fnfAmount = (purchasePrice - 55000) * 0.01 + 275;
    } else if (purchasePrice <= 400000) {
      fnfAmount = (purchasePrice - 250000) * 0.015 + 275 + 1950;
    } else if (purchasePrice <= 2000000) {
      fnfAmount = (purchasePrice - 400000) * 0.02 + 275 + 1950 + 2250;
    } else {
      fnfAmount = (purchasePrice - 2000000) * 0.025 + 275 + 1950 + 2250 + 32000;
    }

    return property === "Toronto" ? fnfAmount * 2 : fnfAmount;
  };

  const calculateFinalAmount = (
    purchasePrice,
    interestRate,
    amortizationYears,
    downValue
  ) => {
    const monthlyInterestRate =
      Math.pow(Math.pow(1 + interestRate / 2, 2), 1 / 12) - 1;
    const totalPayments = amortizationYears * 12;
    const numerator = (purchasePrice - downValue) * monthlyInterestRate; //downpay should be subtract from purchase price
    const denominator = 1 - Math.pow(1 + monthlyInterestRate, -totalPayments);
    const finalAmount = numerator / denominator;
    return finalAmount;
  };

  const calculateDownPayment = (purchasePrice) => {
    let calculatedDownPayment;
    if (purchasePrice <= 500000) {
      calculatedDownPayment = purchasePrice * 0.05;
    } else if (purchasePrice >= 500000 && purchasePrice < 1000000) {
      calculatedDownPayment = (purchasePrice - 500000) * 0.1 + 25000;
    } else if (purchasePrice >= 1000000) {
      calculatedDownPayment = purchasePrice * 0.2;
    }
    setDownValue(calculatedDownPayment);
    setDownPayment(calculatedDownPayment);
    setDownValuePdf(calculatedDownPayment);
  };

  const calculateMortgageInsurance = (
    percentageAmount,
    purchasePrice,
    downValue
  ) => {
    const percentageDifference = 100 - percentageAmount;
    let mortgageInsurance;
    if (percentageDifference <= 65) {
      mortgageInsurance = (purchasePrice - downValue) * 0.006;
    } else if (percentageDifference <= 75) {
      mortgageInsurance = (purchasePrice - downValue) * 0.017;
    } else if (percentageDifference <= 80) {
      mortgageInsurance = (purchasePrice - downValue) * 0.024;
    } else if (percentageDifference <= 85) {
      mortgageInsurance = (purchasePrice - downValue) * 0.028;
    } else if (percentageDifference <= 90) {
      mortgageInsurance = (purchasePrice - downValue) * 0.031;
    } else if (percentageDifference <= 95) {
      mortgageInsurance = (purchasePrice - downValue) * 0.04;
    } else {
      mortgageInsurance = (purchasePrice - downValue) * 0.04;
    }

    return mortgageInsurance;
  };

  // const resultForDownPayment = downValue;
  const percentageAmount = (downValue / purchasePrice) * 100;
  const calculatedMortgageInsurance = Math.round(
    calculateMortgageInsurance(percentageAmount, purchasePrice, downValue)
  );
  const calculatedAmount = calculateFinalAmount(
    purchasePrice,
    interestRate,
    amortizationYears,
    downValue
  );
  const monthlyMortagage = Math.round(calculatedAmount);
  
  useEffect(() => {
    setMonthlyMortgagePdf(monthlyMortagage);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyMortagage]);


  const landTransferTax = Math.round(calculateLandTransferTax(purchasePrice));

  useEffect(() => {
    setLandTaxPdf(landTransferTax);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landTransferTax]);

  const mortgageTotal = Math.round(
    purchasePrice - downValue + calculatedMortgageInsurance
  );
  useEffect(() => {
    setTotalMortgagePdf(mortgageTotal);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mortgageTotal]);

  const roundDownValue = Math.round(downValue);
  const formattedDownPayment = roundDownValue
    ? roundDownValue.toLocaleString("en-US")
    : "";

  const toShowMonthlyMortgage = monthlyMortagage.toLocaleString("en-US");
  const toShowMortgageTotal = mortgageTotal.toLocaleString("en-US");
  const toShowLandTransferTax = landTransferTax.toLocaleString("en-US");
  //Mortgage Calculation End

  return (
    <div>
      <div className="row">
        <div className="col-lg-6 col-12">
          <p className="font fieldheadings m-0">
            <strong>Purchase Price</strong>
          </p>
          <input
            type="text"
            value={
              formatedPurchasePrice === "" ? "" : `$${formatedPurchasePrice}`
            }
            onChange={handlePurchasePriceChange}
            className="mortgage__input"
          />
        </div>
        <div className="col-lg-6 col-12">
          {" "}
          <p className="font fieldheadings m-0">
            <strong>Down Payment</strong>
          </p>
          <input
            type="text"
            value={
              formattedDownPayment === "" ? "" : `$${formattedDownPayment}`
            }
            onChange={handleDownPAyChange}
            className="mortgage__input"
          />
        </div>
      </div>
      <div className="row font mx-2">
        <div className="col-lg-12 col-12 rangepadding">
          <Slider
            defaultValue={0}
            onChange={handleSliderChange}
            min={downPayment}
            max={purachsePriceTonumber}
            step={1}
            valueLabelDisplay="auto"
            sx={{
              color: "#F6941C",
              "& .MuiSlider-thumb": {
                height: 20,
                width: 20,
                backgroundColor: "#F6941C",
                border: "2px solid white",
                "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                  boxShadow: "inherit",
                },
              },
            }}
          />
        </div>
      </div>
      <div className="row font">
        <div className="col-lg-6 col-12">
          {" "}
          <p className="font fieldheadings m-0">
            <strong>Interest Rate (%)</strong>
          </p>
          <input
            type="text"
            value={interestValue}
            onChange={handleInterestChange}
            className="mortgage__input"
          />
        </div>
        <div className="col-lg-6 col-12">
          {" "}
          <p className="font fieldheadings m-0">
            <strong>Amortization (Years)</strong>
          </p>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Amortization years
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={amortizationYears}
                label="Amortization  years"
                onChange={handleAmortizationChange}
                size="small"
                sx={{
                  color: "#0253A0",
                  fontWeight: "600",
                
                }}
              >
                <MenuItem value={5}>5 years</MenuItem>
                <MenuItem value={10}>10 years</MenuItem>
                <MenuItem value={15}>15 years</MenuItem>
                <MenuItem value={20}>20 years</MenuItem>
                <MenuItem value={25}>25 years</MenuItem>
                <MenuItem value={30}>30 years</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
      </div>
      <div className="row font">
        <div className="col-lg-6 col-12">
          {" "}
          <p className="font fieldheadings m-0">
            <strong>Terms (Years)</strong>
          </p>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="termsYears">Terms years</InputLabel>
              <Select
               size="small"
                labelId="termsYears"
                id="termsyearsLabel"
                value={termYearsValue}
                label="Terms years"
                onChange={handleTermYearsChange}
                sx={{
                  color: "#0253A0",
                  fontWeight: "600",
                }}
              >
                <MenuItem value={2}>2 years</MenuItem>
                <MenuItem value={5}>5 years</MenuItem>
                <MenuItem value={10}>10 years</MenuItem>
                <MenuItem value={15}>15 years</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
        <div className="col-lg-6 col-12">
          {" "}
          <p className="font fieldheadings m-0">
            <strong>Payment Frequency</strong>
          </p>
          <TextField
           size="small"
            sx={{
              width: "100%",
              height: "40px",
              borderRadius: "4px",
              marginBottom: "20px",
            }}
            id="outlined-basic"
            disabled
            label={<span className="font textindrawerfield">Monthly</span>}
            variant="outlined"
            name="assignStatus"
          />
        </div>
      </div>
      <div className="row font">
        <div className=" justifycenter">
          <p className=" pt-4 m-0">
            <strong>Land Transfer Tax</strong>
          </p>
        </div>
        <div className=" justifycenter">
          <p className=" pt-1 landTax__price m-0">
            <strong>{`$${toShowLandTransferTax}`}</strong>
          </p>
        </div>
        <div className=" justifycenter">
          <p className=" pt-3 m-0">
            <strong>Mortgage Calculator</strong>
          </p>
        </div>
        <div className=" justifycenter">
          <p className=" pt-1 pricecalculator m-0">
            <strong>{`$${toShowMortgageTotal}`}</strong>
          </p>
        </div>
        <div className=" justifycenter">
          <p className=" pt-3 m-0">
            <strong>My Payments</strong>
          </p>
        </div>
        <div className=" justifycenter">
          <p className=" pt-1 pricecalculator m-0">
            <strong>{`$${toShowMonthlyMortgage} Monthly`}</strong>
          </p>
        </div>
        <div className=" justifycenter aligncenter">
          <p className="pt-2 m-0">
            Would you like to mortgage Pre-Authorization? <br />{" "}
            <span className="">
              Make an appointment with a &nbsp;
              <NavLink to="/mortgage-advisor">
                <span
                  style={{
                    color: "#0253a0",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  Mortgage Advisor
                </span>
              </NavLink>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default MortgageCalculator;

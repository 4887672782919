import * as Yup from 'yup';

export const ContactSchema = Yup.object({
    email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email format"
    )
    .required("This field is required"),
    fullName:Yup.string().min(1).max(50).required("This field is required"),
    message:Yup.string().min(5).max(1000).required("This field is required"),
    
});
import * as React from "react";
import "./AgentCard.css";
import "../../../../../src/Components.css";

export default function AgentCardStructure(props) {
  return (
    <div >
      <div className="d-flex">
        <div >
          <img className="cardimage agent_cardImage" src={props.imgsrc} alt="Agent" loading="lazy"/>
        </div>
        <div className="cardsflexes">
          <div >
            <p className="m-0 p-0  pt-1 cardname">{props.name}</p>
            <p className="m-0  p-0 pt-1 cardtextdata">{props.city}</p>
            <p className="m-0 p-0 pt-1 cardtextdata">{props.num} </p>
            <p className="m-0 p-0 pt-1 cardtextdata">
              <strong> {props.totalsale}</strong> <span> Total Sales</span>
            </p>
            <p className="m-0  p-0 pt-1 cardtextdata">
              <strong>{props.trsale} </strong> <span> In Torronto</span>
            </p>
            <p className="m-0 p-0 pt-1 cardtextdata">
              <strong> {props.pricerange}</strong> <span> Price Range</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";
import baseURL from "../../config";
import "./EmailConfirmation.css";
import { useLocation, useNavigate } from "react-router";

const EmailConfirmation = () => {

  const { search } = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(search)
 
  const [timer, setTimer] = useState(60); 
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [emailSent, setEmailSent] = useState(false); 
  const isEmailConfirmed = queryParams.get("isEmailConfirmed");
  // const [emailConfirmed, setEmailConfirmed] = useState(
  //   localStorage.getItem("anonymous") || "0"
  // );
  const cookies = new Cookies();
  const jwt_token = cookies.get("jwt_authorization");
  const decode = jwtDecode(jwt_token);
  const email =
    decode[
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
    ];
  
  const emailConfirmedStr = decode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/anonymous'];
  const emailConfirmed = emailConfirmedStr === "True";
  const fetchVerificationCode = () => {
    if (emailSent || emailConfirmed) {
      return;
    }

    setIsButtonDisabled(true);

    const headers = {
      Authorization: `Bearer ${jwt_token}`,
    };

    axios
      .get(`${baseURL}/api/EmailConfirmationCode/${email}`, { headers })
      .then((response) => {
        setEmailSent(true);
        startTimer(); // Start the countdown timer
      })
      .catch((error) => {
        console.error(error);
        setIsButtonDisabled(false);
      });
  };

  const startTimer = () => {
    let countdown = timer;
    const intervalId = setInterval(() => {
      countdown--;

      if (countdown >= 0) {
        setTimer(countdown);
      }

      if (countdown === 0) {
        clearInterval(intervalId);
        setIsButtonDisabled(false);
        setEmailSent(false);
        setTimer(60);
      }
    }, 1000);
  };

  useEffect(() => {
    if(isEmailConfirmed === 'false'){
      fetchVerificationCode();
    }else{
      setEmailSent(true);
      startTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(queryParams.has('isEmailConfirmed')){
      setTimeout(() => {
        queryParams.delete("isEmailConfirmed");
        navigate('/email-confirmation')
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   const emailConfirmedValue = localStorage.getItem("anonymous") || "0";
  //   setEmailConfirmed(emailConfirmedValue);
  // }, []);

  // Use the storage event to listen for changes in localStorage
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (emailConfirmed) {
        // const emailConfirmedValue = e.newValue || "0";
        // setEmailConfirmed(emailConfirmedValue);
        window.location.reload();
      }
    };

    // Add the event listener
    window.addEventListener("storage", handleStorageChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [emailConfirmed]);
  

  return (
    <div>
      <div className="container centered  aligning">
        <div className="imagecentered">
          <a href="/">
            <img
              className="imgclass1"
              src={require("../Images/icon.png")}
              alt="AssignmentSold Logo"
              loading="lazy"
            />
          </a>
        </div>
        <div className="imageresizing">
          <img
            className="mailimg"
            src={require("../Images/mail.jpeg")}
            alt="Sending Mail"
            loading="lazy"
          />
        </div>
        {emailConfirmed === false ? (
          <>
            <div className="padders">
              <div className="centerer">
                <div className="alignCenter">
                  <p className="font  m-0 resetheading">
                    Verification Email Sent
                  </p>
                </div>
                <div className="alignCenter">
                  <p className="m-0 font textheading textmer">
                    Verification sent to your account. Kindly verify your email.
                  </p>
                </div>
              </div>

              <div className="alignCenter">
                <button
                  className="Resetbutton mb-3 mt-3"
                  onClick={() => {
                    if (!isButtonDisabled) {
                      fetchVerificationCode(); // Call the function when the button is clicked and not disabled
                    }
                  }}
                  disabled={isButtonDisabled}
                >
                  {emailSent
                    ? `You can get a new Link in ${timer} seconds`
                    : "Verify Email"}
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="mt-2 text-center">
            <h1>Verification Complete ...</h1>
            <a href="/">
              <h3>
                Explore{" "}
                <u className="text-primary fw-bold">
                  {" "}
                  ASSIGNMENT
                  <span className=" fw-bold text-warning">SOLD.CA</span>{" "}
                </u>
              </h3>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailConfirmation;

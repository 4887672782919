import React from "react";
import "./CallAgent.css";

export default function CallAgent() {
  return (
    <div className="m-v-p">
      <div className="p-imp" style={{display:"flex"}}>
        <div className="t-w mobileviewDisply" >
          <p className="m-0  ">
            <span className="ca-2 font text-nowrap"> Question about selling</span> <br />
            <span className="ca-3 font"> Assignment? </span>
            <br /> <span className="ca-4 font"> We can help! </span>
          </p>
          <div className="pb-1"></div>
        
          <button className="ca-5 "> <span className="font"> Call Us</span></button>
        </div>
        <div className="t-w justifyright">
      
          <img
            className="ca-1"
            src={require("../../images/AgentPic.png")}
            alt="AgentProfile"
            loading="lazy"
          />
      
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
export default function BedButtonList({
  selectedBedValues,
  handleBedSelectionChange,
}) {
  const [activeButtons, setActiveButtons] = useState([]);

  useEffect(() => {
    // Set the active buttons based on the selected buttons prop
    setActiveButtons(selectedBedValues);
  }, [selectedBedValues]);

  const handleClick = (button) => {
    // Check if the button is already active
    const isActive = activeButtons.includes(button);

   
  if (isActive) {
    // If the button is active, remove it from the active buttons state
    const newActiveButtons = activeButtons.filter((btn) => btn !== button);
    setActiveButtons(newActiveButtons);
    handleBedSelectionChange(newActiveButtons); 
  } else {
    // If the button is inactive, add it to the active buttons state
    const newActiveButtons = [...activeButtons, button];
    setActiveButtons(newActiveButtons);
    handleBedSelectionChange(newActiveButtons); 
  }
  };
  return (
    <>
      <div className="gapper">
        <button
          className={`btn ${
            activeButtons.includes("Studio")
              ? "btn p-0 font btnbedzzz2"
              : "btn p-0 font btnbedzzz"
          }`}
          onClick={() => handleClick("Studio")}
        >
          Studio
        </button>
        <button
          className={`btn ${
            activeButtons.includes("1 Bed")
              ? "btn p-0 font btnbeds2"
              : "btn p-0 font btnbeds"
          }`}
          onClick={() => handleClick("1 Bed")}
        >
          1
        </button>
        <button
          className={`btn ${
            activeButtons.includes("1 Bed + Den")
              ? "btn p-0 font btnbed2"
              : "btn p-0 font btnbed"
          }`}
          onClick={() => handleClick("1 Bed + Den")}
        >
          1B+D
        </button>
        <button
          className={`btn ${
            activeButtons.includes("2 Bed")
              ? "btn p-0 font btnbeds2"
              : "btn p-0 font btnbeds"
          }`}
          onClick={() => handleClick("2 Bed")}
        >
          2
        </button>
        <button
          className={`btn ${
            activeButtons.includes("2 Bed + Den")
              ? "btn p-0 font btnbed2"
              : "btn p-0 font btnbed"
          }`}
          onClick={() => handleClick("2 Bed + Den")}
        >
          2B+D
        </button>
        <button
          className={`btn ${
            activeButtons.includes("3 Bed")
              ? "btn p-0 font btnbeds2"
              : "btn p-0 font btnbeds"
          }`}
          onClick={() => handleClick("3 Bed")}
        >
          3
        </button>
        <button
          className={`btn ${
            activeButtons.includes("3 Bed + Den")
              ? "btn p-0 font btnbed2"
              : "btn p-0 font btnbed"
          }`}
          onClick={() => handleClick("3 Bed + Den")}
        >
          3B+D
        </button>
        <button
          className={`btn ${
            activeButtons.includes("4 Bed")
              ? "btn p-0 font btnbeds2"
              : "btn p-0 font btnbeds"
          }`}
          onClick={() => handleClick("4 Bed")}
        >
          4
        </button>
        <button
          className={`btn ${
            activeButtons.includes("4 Bed + Den")
              ? "btn p-0 font btnbed2"
              : "btn p-0 font btnbed"
          }`}
          onClick={() => handleClick("4 Bed + Den")}
        >
          4B+D
        </button>
        <button
          className={`btn ${
            activeButtons.includes("5 Bed")
              ? "btn p-0 font btnbeds2"
              : "btn p-0 font btnbeds"
          }`}
          onClick={() => handleClick("5 Bed")}
        >
          5
        </button>
        <button
          className={`btn ${
            activeButtons.includes("5 Bed + Den")
              ? "btn p-0 font btnbed2"
              : "btn p-0 font btnbed"
          }`}
          onClick={() => handleClick("5 Bed + Den")}
        >
          5B+D
        </button>
        <button
          className={`btn ${
            activeButtons.includes("6 Bed+")
              ? "btn p-0 font btnbeds2"
              : "btn p-0 font btnbeds"
          }`}
          onClick={() => handleClick("6 Bed+")}
        >
          6+
        </button>
      </div>
    </>
  );
}

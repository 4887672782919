import React from "react";
import DashboardLayout from "../../Dashboard";
import { Button, Form } from "react-bootstrap";
import { BsFacebook } from "react-icons/bs"; // Assuming you're using react-icons for icons
import { SiTiktok } from "react-icons/si";
import { AiFillInstagram } from "react-icons/ai";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import baseURL from "../../../config";
import { RotatingLines } from "react-loader-spinner";
import { addAdminWebData , removeAdminWebData } from "../../../ReduxStore/Slices/AdminSiteDataSlice";
import { useDispatch } from "react-redux";


const WebHighlights = () => {
  const [editMode, setEditMode] = useState(false);
  const [loading, setloading] = useState(false);
  const [data, setData] = useState({
    email: "",
    phone: "",
    address: "",
    facebook: "",
    instagram: "",
    tiktok: "",
    highlight: "",
    intrestRate: "",
  });
  const cookies = new Cookies();
  const jwt_token = cookies.get("jwt_authorization");
  const dispatch = useDispatch()


  const formatPhoneNumber = (inputNumber) => {
    const cleaned = ("" + inputNumber).replace(/\D/g, "").slice(0, 11);
    const match = cleaned.match(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})$/);
    let formattedNumber = "";
    if (match[1]) {
      formattedNumber += `+${match[1]}`;
    }
    if (match[2]) {
      formattedNumber += ` (${match[2]}`;
    }
    if (match[3]) {
      formattedNumber += `) ${match[3]}`;
    }
    if (match[4]) {
      formattedNumber += `-${match[4]}`;
    }
    return formattedNumber;
  };

  useEffect(() => {
    setloading(true);
    const headers = {
      Authorization: `Bearer ${jwt_token}`,
    };
    axios
      .get(`${baseURL}/api/Admin/GetWebHighlight`, { headers })
      .then((response) => {
        setData({
          email: response?.data?.data?.email || "",
          phone: response?.data?.data?.phone || "",
          address: response?.data?.data?.address || "",
          facebook: response?.data?.data?.facebook || "",
          instagram: response?.data?.data?.instagram || "",
          tiktok: response?.data?.data?.tiktok || "",
          highlight: response?.data?.data?.highlight || "",
          intrestRate: response?.data?.data?.intrestRate || "",
        });
        setloading(false);
      })
      .catch((error) => {
        console.error(error);
        setloading(false);
      });
  }, [jwt_token]);

  const handleSubmit = async () => {
    setloading(true);
    try {
      const response = await axios.put(
        `${baseURL}/api/Admin/UpdateWebHighlight`,
        {
          UId: "519a598f-1e2b-43b5-b00d-7316f90c900d",
          Email: data.email,
          Phone: data.phone,
          Address: data.address,
          Highlight: data.highlight,
          Facebook: data.facebook,
          Tiktok: data.tiktok,
          Instagram: data.instagram,
          IntrestRate: data.intrestRate,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );

      if (response.data.result === 1) {
        setloading(false);
        setEditMode(false);
        dispatch(removeAdminWebData());
        dispatch(addAdminWebData(response?.data?.data));
      }
    } catch (error) {
      console.error(error);
      setEditMode(false);
      setloading(false);
    }
  };

  const handlePhoneNumberChange = (e) => {
    // Use the formatPhoneNumber function to format the phone number value
    formatPhoneNumber(e.target.value);
    setData({ ...data, phone: e.target.value })
  };

  return (
    <DashboardLayout>
      <div className="page-content">
        <div className=" mt-3">
          <div className="fw-bold fs-5 Mobilefont17 mobile4remMarginTop ">Web Highlights</div>
          {loading === true ? (
            <center className="mt-5">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="70"
                visible={loading}
              />
            </center>
          ) : (
            <>
              <Form className="mt-1 d-flex Mobilefont13 ">
                <Form.Group className="  w-25 pe-2 mobileviewWidth FieldWidth">
                  <Form.Label className="">Add Email</Form.Label>
                  <Form.Control
                    size="sm"
                    type="email"
                    value={data?.email} // Use the email state variable
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                    disabled={!editMode}
                  />
                </Form.Group>
                <Form.Group className="  w-25 ps-2 mobileviewWidth FieldWidth">
                  <Form.Label className="">Add Contact</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    disabled={!editMode}
                    value={formatPhoneNumber(data?.phone)}
                    onChange={handlePhoneNumberChange}
                  />
                </Form.Group>
              </Form>
              <Form className="d-flex">
                <Form.Group className="  w-25 pe-2 mobileviewWidth FieldWidth">
                  <Form.Label className="">Add Address</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    value={data?.address}
                    onChange={(e) =>
                      setData({ ...data, address: e.target.value })
                    }
                    disabled={!editMode}
                  />
                </Form.Group>
                <Form.Group className="  w-25 ps-2 mobileviewWidth FieldWidth">
                  <Form.Label className="">Intrest Rate</Form.Label>
                  <Form.Control
                    size="sm"
                    type="number"
                    disabled={!editMode}
                    value={data?.intrestRate}
                    onChange={(e) =>
                      setData({ ...data, intrestRate: e.target.value })
                    }
                  />
                </Form.Group>
              </Form>
              <Form>
                <h5 className="mt-4 Mobilefont17" >Add Social Media Links</h5>
                <Form.Group className="  w-50 mobileviewWidth FieldWidth100">
                  <Form.Label className="">Facebook</Form.Label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text py-2 rounded-0 " style={{color:"royalblue"}}>
                        <BsFacebook />
                      </span>
                    </div>
                    <Form.Control
                      size="sm"
                      type="text"
                      disabled={!editMode}
                      value={data?.facebook}
                      onChange={(e) =>
                        setData({ ...data, facebook: e.target.value })
                      }
                    />
                  </div>
                </Form.Group>
                <Form.Group className="  w-50 mobileviewWidth FieldWidth100">
                  <Form.Label className="">Instagram</Form.Label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text py-2 rounded-0" style={{color:"orange"}}>
                        <AiFillInstagram />
                      </span>
                    </div>
                    <Form.Control
                      size="sm"
                      type="text"
                      value={data?.instagram}
                      onChange={(e) =>
                        setData({ ...data, instagram: e.target.value })
                      }
                      disabled={!editMode}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="  w-50 mobileviewWidth FieldWidth100">
                  <Form.Label className="">TickTok</Form.Label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text py-2 rounded-0">
                        <SiTiktok />
                      </span>
                    </div>
                    <Form.Control
                      size="sm"
                      type="text"
                      value={data?.tiktok}
                      onChange={(e) =>
                        setData({ ...data, tiktok: e.target.value })
                      }
                      disabled={!editMode}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="w-50 mobileviewWidth FieldWidth100 mt-2">
                  <div className="form-group">
                    <label className=" fs-5 Mobilefont17" >Highlight</label>
                    <textarea
                      value={data?.highlight}
                      onChange={(e) =>
                        setData({ ...data, highlight: e.target.value })
                      }
                      disabled={!editMode}
                      className="form-control mt-1 Mobilefont13"
                      rows="2"
                    ></textarea>
                  </div>
                  {editMode === false ? (
                    <Button
                      className="w-100 mt-2"
                      onClick={() => setEditMode(true)}
                    >
                      {" "}
                      Edit{" "}
                    </Button>
                  ) : (
                    <Button className="w-100 mt-2" onClick={handleSubmit}>
                      Save Changes
                    </Button>
                  )}
                </Form.Group>
              </Form>
            </>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default WebHighlights;

import * as Yup from 'yup'
import { isValidPhoneNumber } from 'react-phone-number-input'

export const UserSignUpSchema = Yup.object({
  fname: Yup.string()
    .matches(/^[A-Za-z ]+$/, 'Name should only contain alphabets and spaces')
    .required('This field is required'),
  lname: Yup.string()
    .matches(/^[A-Za-z ]+$/, 'Name should only contain alphabets and spaces')
    .required('This field is required'),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
      'Password must include lowercase, uppercase, numbers, and at least one special character'
    )
    .required('This field is required'),

  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Invalid email format'
    )
    .required('This field is required'),
  cno: Yup.string()
    .test('is-valid-phone', 'Invalid phone number', (value, { parent }) => {
      const selectedCountry = parent.selectedCountry
      if (!selectedCountry) {
        return true
      }

      return isValidPhoneNumber(value, selectedCountry)
    })
    .required('Phone number is required'),
  cpassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('This field is required'),
  termsAndConditions: Yup.boolean().oneOf(
    [true],
    'You must agree to the Terms and Conditions'
  )
})

import { createSlice } from "@reduxjs/toolkit";

const ListingsDataSlice = createSlice({
  name: "ListingsDataSlice",
  initialState: [],
  reducers: {
    addListingsDataSlice: (state, action) => {
          state.push(action.payload);
    },
    removeListingsDataSlice: () => {
      return [];
    },
  
  },
});
export const { addListingsDataSlice, removeListingsDataSlice} = ListingsDataSlice.actions;
export default ListingsDataSlice.reducer;
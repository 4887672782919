import React from 'react';
import TextField from '@mui/material/TextField';

const FormattedField = ({ formik, name, selectedStyle , isdisable ,label, inputRef }) => {
    const handleInputChange = (e) => {
        const inputValue = e.target.value || '';
        const numericValue = inputValue.replace(/[^0-9]/g, '').substring(0, 7);
        formik.setFieldValue(name, numericValue);
    };

    const formattedValue = new Intl.NumberFormat('en-US', {
        style: selectedStyle,
        currency: 'USD',
        maximumFractionDigits: 0,
    }).format(formik.values[name] || 0);

    return (
        <TextField
        disabled={isdisable}
            size="small"
            sx={{
                width: '100%',
                height: '40px',
                borderRadius: '4px',
                '.Mui-disabled': {
                    textShadow: '0 0 black', // Change the color as needed
                  },
            }}
            type="text"
            label={selectedStyle === 'currency' ? <span className="font textinfield">$</span> : <span className="font textinfield">{label}</span>}
            className={"fieldsettings mob_filedsettings bg-white comWidth_Field"}
            value={formattedValue}
            onChange={handleInputChange}
            id={"outlined-basic"}
            variant="outlined"
            name={name}
            inputRef={inputRef} 
        />
    );
};

export default FormattedField;

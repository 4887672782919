import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import './CategoriesTabs.css';
import { useNavigate, useParams } from 'react-router-dom';


const CategoriesTab = ({ citiesFilterData, loading, loadMore }) => {
    const { id } = useParams()
    const [value, setValue] = useState(0);
    const navigate = useNavigate()
    const tabLabels = citiesFilterData?.map(cityData => cityData.cityName);

    useEffect(() => {
        if (citiesFilterData?.length !== 0) {
            const selectedIndex = tabLabels?.findIndex(label => label === id);
            if (selectedIndex !== -1) {
                setValue(selectedIndex);
            } else {
                setValue(0);
            }
        }
        // eslint-disable-next-line 
    }, [id,citiesFilterData])

    const handleChange = (event, newValue) => {
        const selectedTabValue = tabLabels[newValue];
        if (!loading && !loadMore) {
            if (selectedTabValue === 'All') {
                navigate('/')
            } else {
                navigate(`/assignment-sales/${selectedTabValue}`)
            }
        }
    };

    return (
        <Box sx={{ bgcolor: '#fff' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
                className='Custom_categoryTabs'
            >
                {citiesFilterData?.map((label, index) => (
                    <Tab key={index} label={label?.dataCount ? `${label?.cityName}(${label?.dataCount})` : `${label?.cityName}` } />
                ))}
            </Tabs>
        </Box>
    );
}

export default CategoriesTab;

import { createSlice } from "@reduxjs/toolkit";

const compareListingSlice = createSlice({
  name: "compareListingData",
  initialState: {
    compareListingData: [],
  },
  reducers: {
    addCompareItem: (state, action) => {
      if (state.compareListingData.length < 3 && !state.compareListingData.some(item => item.id === action.payload.id)) {
        state.compareListingData.push(action.payload);
      }
    },
    removeCompareItem: (state, action) => {
      state.compareListingData = state.compareListingData.filter(item => item.id !== action.payload.id);
    },
    clearCompareItems: (state) => {
        state.compareListingData = [];
      },
  },
});

export const { addCompareItem, removeCompareItem, clearCompareItems} = compareListingSlice.actions;
export default compareListingSlice.reducer;

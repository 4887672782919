import React from "react";
import "./PrivacyAndPolicy.css";
import Footer from "../Footer/Footer";
import HelmetLayout from "../../Utils/HelmetLayout/Helmet";

export default function PrivacyAndPolicy() {
  return (
    <div>

      <HelmetLayout pageName={'privacy-policy'} canonicalLink={'privacy-policy'} />

      <div className="d-flex text-white container-fluid p-4 topContainer">
        <div>
          <h2 className="topHeading">Privacy Policy</h2>
        </div>
        <div>
          <p className="topHeadingPara">
            Privacy and personal data protection is important for AssignmentSold
            (hereinafter "AssignmentSold", "we", "our" or "ours"). For this
            reason, effective measures have been put in place by AssignmentSold
            to protect the personal information in our possession or that we
            handle.
          </p>
          <p className="topHeadingPara">
            This Privacy Policy (the "Policy") explains how AssignmentSold
            collects, uses, discloses, and stores personal information that you
            may provide to us in connection with your use of our website or
            mobile application (collectively the "Platforms") or the services
            provided by AssignmentSold (the "Services"). It also informs you of
            AssignmentSold's commitments in the management and protection of
            personal information.
          </p>
          <p className="topHeadingPara">
            By accessing our Platforms or using our Services, you consent to the
            collection, use, disclosure, and retention of your personal
            information in accordance with this Policy. In certain cases, your
            consent may be "implied", i.e., that your permission is presumed
            depending on your action or inaction at the time of the collection,
            use or sharing of your personal information. If you do not agree
            with this Policy, please refrain from using our Platforms or our
            Services. Of course, some Services can only be offered to you if you
            provide AssignmentSold with information of this nature;
            consequently, AssignmentSold may not be able to provide you with
            these Services if you decide not to provide us with the necessary
            personal information.
          </p>
        </div>
      </div>

      <div className="container content_contaner">
        <div>
          <h5 className="contentHeading">Who does the Policy apply to?</h5>
          <p className="contentPara">
            This Policy applies to anyone who communicates or interacts with us,
            regardless of the method used, whether you are a customer or someone
            visiting or using our Platforms or Services. Our employees and those
            responding to our job offers are subject to a separate privacy
            policy.
          </p>
        </div>

        <div>
          <h5 className="contentHeading">What is personal information?</h5>
          <p className="contentPara">
            Personal information refers to any information which concerns a
            person and makes it possible, separately or in combination with
            other data, to identify them. This may include, for example, your
            name, email addresses, contact information and any similar
            information provided to AssignmentSold. Such information is not
            considered personal information when it is aggregated and cannot be
            associated with an identifiable individual, either directly or
            indirectly.
          </p>
        </div>

        <div>
          <h5 className="contentHeading">
            What types of personal information do we collect?
          </h5>
          <p className="contentPara">
            The types of personal information we collect depend on the way you
            interact with us, as described in the "How is your personal
            information collected?" section.
          </p>
          <p className="contentPara">
            Generally, we collect the following types of personal information:
          </p>
          <ul className="contentList">
            <li>
              <p className="contentPara ">
                <strong>Identifiers and contact details,</strong> such as your
                name, your password, your address, your zip code, your email and
                your phone number, when you subscribe to our Services, create an
                account with us, when you sign up to receive our newsletters,
                our offers and promotions, or when you submit an information or
                a service request.
              </p>
            </li>
            <li>
              <p className="contentPara">
                <strong>Information linked to your browsing activities,</strong>{" "}
                such as your IP address (if it enables us to identify you as a
                person), your device identifier, the type of browser, the pages
                viewed on our Platforms, the ads you clicked on and other
                similar information that we collect through cookies, web beacons
                and other automated methods when you access our Platforms or use
                our Services.
              </p>
            </li>
            <li>
              <p className="contentPara">
                <strong>
                  Information related to your interaction with our emails,
                </strong>{" "}
                such as their opening and clicks on links. This information is
                collected automatically through data collection technologies
                embedded in our emails, as further described in the "How is your
                personal information collected?" section.
              </p>
            </li>
            <li>
              <p className="contentPara">
                <strong>Information about your property,</strong> such as the
                type of property, the number of floors, the value of the
                property, the dimensions of the lot, the surface area of the
                land, the construction date and other similar data regarding the
                characteristics of your property. Information about your
                property may also include photographs of your property and the
                sale or rental price. This information may be collected from
                various sources, including from you directly or from third
                parties, as described in more detail in the "How is your
                personal information collected?" section.
              </p>
            </li>
            <li>
              <p className="contentPara">
                <strong>Transactional information,</strong> such as information
                about your billing and delivery address, payment information
                transaction history and other types of similar information, when
                you ask to receive our Services.
              </p>
            </li>
            <li>
              <p className="contentPara">
                <strong>Demographic and statistical data,</strong> including
                aggregated or statistical data relative to a property or a
                district in which it is located, such as population density, the
                accessibility and availability of parks, public transport and
                other public conveniences. We do not generally use this
                information to identify you personally.
              </p>
            </li>
            <li>
              <p className="contentPara">
                <strong>Any other information you give to us </strong> (as
                detailed in the "How is your personal information collected?"
                section).
              </p>
            </li>
          </ul>
        </div>

        <div>
          <h5 className="contentHeading">
            How is your personal information collected?
          </h5>
          <p className="contentPara">
            We collect personal information about you from:
          </p>

          <ul className="contentList">
            <li>
              <strong>you:</strong>{" "}
              <ul className="contentList">
                <li>
                  <p className="contentPara">
                    when you provide this information to us directly, for
                    example when you create an account with us, when you sign up
                    to receive our newsletters, our offers or promotions, when
                    you list your property on our Platforms or when you submit
                    an information or a service request, and
                  </p>
                </li>
                <li>
                  <p className="contentPara">
                    when personal information about you is automatically
                    collected as part of your use of our Services or our
                    Platforms.
                  </p>
                </li>
              </ul>
            </li>

            <li>
              <p className="contentPara">
                <strong>other affiliated entities</strong> forming part of the
                EspaceProprio group of companies, in particular EspaceProprio,
                RénoAssistance and Confia (together the "Group"), when they
                provide us with your personal information so as to offer you the
                Services you are asking for, to conduct marketing activities or
                for the purposes described in this Policy. In some cases, we
                receive anonymized or de-identified information for research and
                analysis purposes, including to understand the interests of
                users of our Platforms and Services, improve our products,
                services and features, and develop new ones (as detailed in the
                "Research and Data Analysis" subsection of the Why does
                AssignmentSold collects and uses your personal information?
                section).
              </p>
            </li>

            <li>
              <strong>third parties, </strong> when they provide us with
              personal information about you. Third parties who share your
              personal information with us include:
              <ul className="contentList">
                <li>
                  <p className="contentPara">
                    Service providers. For example, we may use service providers
                    to analyze how you interact and engage with our Platforms or
                    our Services, or third parties may help us provide customer
                    support to you.
                  </p>
                </li>
                <li>
                  <p className="contentPara">
                    Advertising partners. We may receive information about you
                    from some of our marketing or promotional service providers
                    related to how you interact with our Platforms,
                    applications, products, services, advertisements, or
                    communications.
                  </p>
                </li>
                <li>
                  <p className="contentPara">
                    Business partners. We may receive information about you from
                    some of our business partners who refer you to our services.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
          <p className="contentPara">
            We may also collect data from other third parties and public sources
            and combine them with other information, including those described
            in this Policy, so as to provide you with our Services, to improve
            the user experience and to develop or improve our Services. For
            example, we may collect Information about a property, such as the
            year of construction, the value of the property, the surface area
            and other characteristics of the property from public records held
            by public organizations, in particular the municipalities, so as to
            develop and exploit new features linked to our Services. These data
            are not generally used to identify you personally.
          </p>
          <p className="contentPara">
            You can request further details about the information we collect
            from you or someone else by contacting us at the address indicated
            in the "How can you contact us?".
          </p>
        </div>

        <div>
          <h5 className="contentHeading">
            Personal information you provide voluntarily
          </h5>
          <p className="contentPara">
            When you visit our Platforms, participate in a contest, promotion,
            survey or create an account with us, use our Services, inquire about
            our Services or when you communicate or interact with us, you may be
            asked to voluntarily provide us with certain personal information
            (such as your name, email address, mailing address and phone number)
            so that we can communicate with you more efficiently, respond to
            your inquiry and provide you with the Services you have asked for.
          </p>
        </div>

        <div>
          <h5 className="contentHeading">
            Personal information automatically collected
          </h5>
          <p className="contentPara">
            Cookies and other data collection technologies – When you access any
            of our Platforms, we use a browser feature called a "cookie" and
            other similar technologies to automatically collect certain
            information about you, your Internet activity and your activity on
            our Platforms, such as identifiers that can be used to recognize you
            or your device over time, the Internet Protocol (IP) address that
            identifies your computer, the IP address of your Internet service
            provider, the name and version of your Web browser, the date and
            time you access the Platforms, the Internet address from which you
            directly access the Platforms, the operating system used, sections
            of the Platforms visited, pages read and images viewed and documents
            displayed on the Platforms or downloaded from the Platforms. A
            cookie is a small text file containing a unique identification
            number that identifies a visitor’s browser (but does not necessarily
            identify the visitor) to our computers each time someone visits our
            Platforms.
          </p>
          <p className="contentPara">
            We use cookies and other similar technologies to manage our
            Platforms and our Services, and to analyze how our Platforms and our
            Services are used. These technologies also allow us to better
            understand your interests and provide you with a more personalized
            online experience, meeting your property needs. You can reset your
            browser to be notified if you receive a cookie or to decline
            cookies. However, if you decline cookies, you may not be able to use
            some of the features of our Platforms.
          </p>
          <p className="contentPara">
            We also use data collection technologies to better understand how
            you interact with our emails and to personalize our communications
            with you. By providing us with your email address, you agree to our
            use of these technologies in emails you receive from us. If you wish
            to opt out of email marketing communications, including the use of
            these data collection technologies, you may do so by contacting us
            by email at the address listed in the "How can you contact us?"
            section or by changing your privacy settings in your My
            AssignmentSold account.
          </p>
          <p className="contentPara">
            AssignmentSold also works with advertising partners who collect and
            use information about your visits on our Platforms in order to
            present you with advertising that may be of interest to you. Our
            advertising partners may also install cookies or pixels the moment
            you click on their advertising messages or links to their websites
            or services on our Platforms. This Policy does not apply to third
            party websites or the advertising providers of such third parties.
          </p>
          <p className="contentPara">
            By using any of our Platforms, you acknowledge that we use essential
            cookies, and you consent to the use of non-essential cookies, as
            explained in our Cookie Policy. To better understand how we use
            cookies and how to block certain types of cookies, please consult
            our Cookie Policy.
          </p>
          <p className="contentPara">
            Geolocation – In order to provide our Services, we collect
            information about your approximate location (city or province),
            which is generated using your IP address when you make an inquiry on
            our Platforms. We also collect information about your precise
            location when you allow either of our Platforms to locate your
            device.
          </p>
          <p className="contentPara">
            Telephone recordings and online chat – When you interact with us by
            telephone or online chat, the conversations may be recorded for
            training, quality control and internal analytics purposes. We will
            notify you in advance that the conversation may be recorded for
            these purposes.
          </p>
        </div>

        <div>
          <h5 className="contentHeading">
            Why does AssignmentSold collect and use your personal information?
          </h5>
          <p className="contentPara">
            AssignmentSold uses the personal information collected about you for
            the following purposes:
          </p>

          <ul className="contentList">
            <li>
              <p className="contentPara">
                to respond to online service requests, questions, or comments
                that you send to us;
              </p>
            </li>
            <li>
              <p className="contentPara">
                for all purposes necessary for the provision of our Services,
                such as: to refer you or send your request to any of the
                entities in the Group which may deal with your request or
                respond to your needs;
              </p>
              <ul className="contentList">
                <li>
                  <p className="contentPara">
                    to monitor the performance and progress of your request and
                    the Services to which you have subscribed with one of the
                    entities in the Group;
                  </p>
                </li>
                <li>
                  <p className="contentPara">
                    where appropriate, to enable you to communicate with an
                    entity in the Group or enable an entity in the Group to
                    communicate with you, as appropriate, in respect of a
                    service request; and
                  </p>
                </li>
                <li>
                  <p className="contentPara">
                    to carry out internal operations necessary for the provision
                    of our Services, including resolving software interruptions
                    and operational issues.
                  </p>
                </li>
                <li>
                  <p className="contentPara">
                    to personalize and improve your experience on our Platforms;
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p className="contentPara">
                to respond to online service requests, questions, or comments
                that you send to us;
              </p>
            </li>

            <li>
              <p className="contentPara">
                to personalize and distribute online advertising content (or
                other media) and offer you products and services that may be of
                interest to you based on your preferences, interests or profile.
                To do this, we use information about your preferences, interests
                and profile, based on information you provide us as part of our
                Services, information generated when you interact with us or
                with entities in the Group, and statistical and demographic data
                available to the public. Rest assured that no sensitive
                information is used for advertising or marketing purposes and,
                to the extent possible, your information will be de-identified
                before it is used for these purposes;
              </p>
            </li>

            <li>
              <p className="contentPara">
                to offer a personalized user experience, in particular by using
                the information you provide to us or that we collect using
                automated methods to improve the accuracy and relevance of the
                Services we offer;
              </p>
            </li>

            <li>
              <p className="contentPara">
                to obtain a view of all the Services that you have within the
                entities in the Group, in particular to provide a more
                integrated experience, to personalize and improve the quality
                and relevance of the Services we offer and to simplify the
                management of your accounts within the Group;
              </p>
            </li>

            <li>
              <p className="contentPara">
                to manage, review and improve our Services, including customer
                service and internal business processes;
              </p>
            </li>

            <li>
              <p className="contentPara">
                to detect security incidents and protect against malicious,
                deceptive, fraudulent, or illegal activities; for any other
                purpose described in this Policy or that is communicated to you
                when your personal information is collected;
              </p>
            </li>

            <li>
              <p className="contentPara">
                as required or permitted by the applicable law.
              </p>
            </li>

            <li>
              <p className="contentPara">
                If you prefer that your personal information not be used within
                our Group for advertising or marketing purposes or for other
                purposes that are not necessary for the provision of our
                Services or the good continuity of our operations, you can
                withdraw your consent at any time (subject to legal or
                contractual restrictions) by following the instructions set out
                in the "What are your choices regarding the use of your personal
                information?" section below.
              </p>
            </li>

            <p className="contentPara">
              <strong>Catalog</strong> – Information on properties sold or for
              sale on our Platforms may also be used in the context of some of
              our other products or services, such as the Catalog offered on our
              website. In this case, the collection, use and disclosure of
              property information is governed by a license agreement which
              prohibits, among other things, any attempt to identify an
              individual or extract information about an identifiable individual
              from using this data.
            </p>

            <p className="contentPara">
              <strong>Research and Data analysis</strong> – AssignmentSold may
              de-identify or anonymize your information by removing your name
              and other information that could identify you and use it for
              research and analysis purposes, such as to create general tracking
              information reports used to study trends and changes linked to the
              demographics of users of our Platforms or our Services, to manage
              and improve our Platforms and to better understand the interests
              of our users. De-identified or anonymized information may also be
              used to improve our products, services and features, and to
              develop new ones.
            </p>

            <p className="contentPara">
              <strong>Electronic communications </strong> – If you provide your
              email address or telephone number, AssignmentSold and the entities
              in the Group may occasionally send you commercial electronic
              messages in the form of email or SMS, namely, to inform you of our
              promotions, important updates, to offer you a new service or
              product, including those offered by entities that are part of the
              Group or to send you real estate content. At any time, you can
              unsubscribe from these communications by using the methods
              indicated in the message or by modifying your privacy settings in
              your My AssignmentSold account.
            </p>

            <p className="contentPara">
              <strong>Targeted advertising</strong> – By using cookies and other
              similar technologies, AssignmentSold can offer you advertising
              that better meets your needs, since it is targeted according to
              your interests. When you visit or carry out actions on our
              Platforms, these technologies tell us which pages or sections you
              are interested in. Using this information, we may subsequently
              display advertising that meets your interests, and the entities in
              the Group may send you advertising when they consider you may be
              interested in their services. When you click on one of these ads,
              AssignmentSold can also track the response rate of the campaign.
              For further details on the use of cookies and tracking
              technologies, please refer to the "Personal information collected
              automatically" section and to the Cookie Policy.
            </p>
          </ul>
        </div>

        <div>
          <h5 className="contentHeading">
            With whom can your personal information be shared?
          </h5>
          <p className="contentPara">
            We may share your personal information with your consent or when
            required or permitted under applicable law. We may therefore
            specifically share your personal information with our service
            providers for the purposes described in this Policy or with the
            entities of the Group for the management of our activities and to
            provide you with better Services, as described in more detail below.
          </p>

          <p className="contentPara">
            <strong>Disclosure to entities in the</strong> – We may share your
            personal information with the entities in the Group to provide you
            with the Services requested, to obtain a view of all the Services
            that you have within the entities in the Group, in particular to
            provide you with a more integrated experience, to personalize and
            improve the quality and relevance of the Services we offer and to
            simplify the management of your accounts within the Group, to comply
            with applicable laws, regulations and requirements, and ensure that
            your information is consistent, accurate and up to date. In certain
            cases, we may share de-identified or anonymized information with
            entities in the Group to develop or improve our Services, for
            example, new features linked to the purchase, maintenance,
            renovation, management or sale of properties, or for other study,
            research or statistical purposes, in which case we take the
            appropriate steps to protect your privacy and ensure that your
            information cannot be used to re-identify you. Rest assured that
            each of the Group’s entities implements appropriate control measures
            to ensure the protection of your personal information, compliance
            with this Policy as well as compliance with applicable laws,
            regulations and requirements regarding the protection of personal
            information.
          </p>

          <p className="contentPara">
            <strong>Disclosures to our service providers</strong> – We may share
            your information with third party suppliers, consultants and other
            service providers that we employ for the purpose of performing tasks
            or rendering services on our behalf. These entities include, but are
            not limited to, our payment processing providers, Web analytics
            companies, business intelligence service providers, email service
            providers, and advertising partners. These service providers may
            also use this information to develop, test, produce and/or improve
            the products or services that they supply on our behalf.
          </p>

          <p className="contentPara">
            <strong>Disclosure to government bodies </strong> – We may disclose
            personal information about you to government bodies that are
            authorized by law to obtain such information, or where
            AssignmentSold has reasonable grounds to believe that such
            information may be useful in connection with an investigation of
            unlawful activity, or to comply with a subpoena, arrest warrant or
            order of a court, competent person or body requiring the production
            of information, or to comply with court rules regarding the
            production of records or information, or to protect the rights and
            property of AssignmentSold.
          </p>

          <p className="contentPara">
            <strong>Business transaction </strong> – If needed for the purposes
            of evaluating or concluding a commercial transaction, such as the
            sale or lease of all or part of the company or its assets or the
            modification of its legal structure by way of merger or others,
            AssignmentSold may share personal information with the other party
            to the transaction in accordance with the legally applicable
            requirements.
          </p>
        </div>

        <div>
          <h5 className="contentHeading">
            Is your personal information likely to be transferred or
            communicated outside of your province or outside of Canada?
          </h5>
          <p className="contentPara">
            AssignmentSold uses service providers and partners located outside
            of your province and outside of Canada to accomplish certain
            specific mandates in the normal course of business.
          </p>
          <p className="contentPara">
            If we need to communicate personal information to these suppliers
            and partners, we use contractual measures to ensure your personal
            information is adequately protected when we transfer it to other
            provinces and countries. However, when your personal information is
            processed in a jurisdiction other than the one where you live, it
            may be subject to the law of that foreign jurisdiction, including
            any law authorizing or requiring the communication of information to
            government bodies, the courts and the organizations responsible for
            enforcing the law and for national security in that jurisdiction.
          </p>
        </div>

        <div>
          <h5 className="contentHeading">
            Can you access, rectify or modify your personal information?
          </h5>
          <p className="contentPara">
            By contacting us by email at the address indicated in the "How can
            you reach us?" section, you can:
          </p>

          <ul className="contentList">
            <li>
              <p className="contentPara">
                request access to your personal information and obtain
                information on how we collect, use and communicate your personal
                information, subject to the limits prescribed by the applicable
                law;
              </p>
            </li>
            <li>
              <p className="contentPara">
                Request to modify or update your personal information, if it is
                incorrect, incomplete or ambiguous; and
              </p>
            </li>
            <li>
              <p className="contentPara">
                Request to delete your personal information.
              </p>
            </li>
          </ul>

          <p className="contentPara">
            Subject to certain exceptions provided by law, you will be able to
            obtain reasonable access to the personal information we hold about
            you and you will have the right to question the relevance and
            accuracy of that information and to have it amended as necessary.
            You can help AssignmentSold maintain the accuracy of your
            information by notifying AssignmentSold of any necessary changes.
          </p>

          <p className="contentPara">
            If you make a request to delete your personal information, it will
            be processed under the applicable laws. AssignmentSold may still
            retain some of your personal information to comply with its legal
            and regulatory obligations or if it is still necessary for the
            purposes for which the company collected it.
          </p>
        </div>

        <div>
          <h5 className="contentHeading">
            What are your choices regarding the use of your personal
            information?
          </h5>
          <p className="contentPara">
            By contacting us by email at the address indicated in the "How can
            you contact us?" section or by modifying your privacy settings in
            your My AssignmentSold account, you can withdraw or modify your
            consent to our use and disclosure of your personal information or
            refuse to participate in certain uses and disclosures, subject to
            legal or contractual restrictions. For example, we may, when
            permitted or required by law, continue to process your personal
            information without your consent, in particular to allow us to
            comply with our legal obligations, defend our rights or legal
            interests, for study, research or statistical purposes or for other
            legitimate business activities in accordance with the applicable
            laws. If you refuse to provide us with your personal information or
            to give us the required consents with respect to it, it may be
            impossible for us to do business with you or provide you with the
            Services requested.
          </p>
          <p className="contentPara">
            <strong>Electronic communications</strong>– At any time, you can
            have your name removed from our mailing lists for promotional or
            marketing electronic communications by clicking on the unsubscribe
            or preference center link shown in each message or by contacting us
            by email at the address indicated in the "How can you contact us?"
            section. If you choose to no longer receive promotional or marketing
            electronic communications, you may continue to receive messages from
            us for other purposes, in accordance with the applicable laws. For
            example, we may need to contact you to conclude a transaction, as
            part of the Services, to respond to your questions or to provide you
            with information that you have requested.
          </p>
          <p className="contentPara">
            <strong>Cookies and data collection technologies</strong>– You can
            block the use of cookies by activating the setting for this purpose
            in your browser. You can also block the use of cookies and data
            collection technologies by modifying your settings in the Cookie
            Preference Center accessible from your My AssignmentSold account or
            on the Platform. For more information on how to block cookies,
            please see our Cookie Policy.
          </p>
        </div>

        <div>
          <h5 className="contentHeading">
            How long do we keep your personal information?
          </h5>
          <p className="contentPara">
            We keep your personal information for as long as we have a
            legitimate need to do so. Even if you have stopped doing business
            with us, we will need to keep certain information because, among
            other reasons, it is required by law. When this information is no
            longer needed, it is destroyed or anonymized in accordance with the
            applicable law, so that you can no longer be directly or indirectly
            identified. As part of the process of destroying information, we
            follow strict rules and ensure that no unauthorized person gains
            access to it.
          </p>
        </div>

        <div>
          <h5 className="contentHeading">
            Can this Policy be changed and, if applicable, will you be notified?
          </h5>
          <p className="contentPara">
            We may make changes or additions to this Policy at any time, so we
            encourage you to review it periodically. If we make any changes to
            this Policy, we will notify you by changing the effective date of
            this Policy at the top of the page. If we make major changes, you
            will be notified in compliance with the applicable laws.
          </p>
        </div>

        <div>
          <h5 className="contentHeading">How can you contact us?</h5>
          <p className="contentPara">
            If you have any inquiries, worries, or grievances regarding the
            safeguarding of your personal information or our data protection
            procedures, kindly reach out to our Privacy Officer via email at
            privacy@assignmentSold.ca
          </p>
          <p className="contentPara">
            Please provide your name, contact information, the nature of your
            request or concern, and any other pertinent details. We appreciate
            your cooperation.
          </p>
        </div>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
}

import React from 'react'
import { useNavigate } from 'react-router-dom'
import Skeleton from '@mui/material/Skeleton';
import baseURL from '../../../../config';

const BlogMobileBannerCard = ({ item, loading }) => {

    const navigate = useNavigate()

    const handledetailsNavigate = () => {
        if (!loading) {
            navigate(`/blogs/details/${item?.slug}`)
        }
    }

    return (
        <div className='position-relative w-100' onClick={handledetailsNavigate}>
            <div className='w-100'>
                {loading ?
                    <Skeleton variant="rounded" animation="wave" style={{ width: '100%', height: '270px' }} /> :
                    <>
                        {item?.blogMedias?.length > 0 ?
                            <img src={`${baseURL}/${item?.blogMedias[0]?.fileUrl}`} alt={item?.title} className='w-100' style={{ minHeight: '270px', maxHeight: '280px', borderRadius: '12px' }} loading="lazy"/>
                            :
                            <img
                                src={require("../../../../images/no-image.png")}
                                alt={item?.title}
                                className='w-100' style={{ minHeight: '270px', maxHeight: '280px', borderRadius: '12px' }}
                                loading="lazy"
                            />
                        }
                    </>

                }
            </div>

            {!loading && (
                <>
                    <div className='article_bannerCategory'>
                        <p>{item?.blogCategory?.name}</p>
                    </div>

                    <div className='Article_banner_content'>
                        <h4>{item?.title?.length > 70 ? `${item?.title.slice(0, 70)}...` : item?.title}</h4>
                        <p>{item?.readtime} min read</p>
                    </div>
                </>
            )}
        </div>
    )
}

export default BlogMobileBannerCard

import { Modal } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import { TextField } from '@mui/material';
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useState } from 'react';
import Cookies from 'universal-cookie';
import baseURL from '../../../config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


const PriceChangingModal = ({ assignmentId, showPriceChangeModal, setShowPriceChangeModal, handleClosePriceModal, cardsData,setData }) => {


    const cookies = new Cookies();
    const jwt_token = cookies.get('jwt_authorization');
    const navigate = useNavigate()
    const [isSubmited, setIsSubmited] = useState(false)
    const [formSubmited, setFormSubmited] = useState(false)
    const [keyWord, setKeyWord] = useState('')

    const formInitialValues = {
        Prices: '',
    }

    const PricingCardSchema = Yup.object({
        Prices: Yup.string().min(1).required("This field is required"),
    });

    const formik = useFormik({
        initialValues: formInitialValues,
        validationSchema: PricingCardSchema,
        onSubmit: async (values, { resetForm }) => {
            setFormSubmited(true);
            const headers = {
                Authorization: `Bearer ${jwt_token}`,
            };
            const data = {
                AssignmentId: assignmentId,
                KeyWord: keyWord,
                Prices: values.Prices
            };

            if (!jwt_token) {
                navigate('/login', { state: { returnUrl: 'dashboard/assignments-listing/Active' } });
                return;
            }
            try {
                const response = await axios.post(`${baseURL}/api/User-Data/AddAssignmentPrice`, data, { headers })
                if (response?.data?.result === 1) {
                    const updatedData = cardsData?.map(item => {
                        if (item?.id === assignmentId) {
                            const lastAssignmentPriceIndex = item.assignmentPrices.length > 0 ?  item.assignmentPrices.length - 1 : item.assignmentPrices.length;
                            const updatedAssignmentPrices = [...item.assignmentPrices];
                            updatedAssignmentPrices[lastAssignmentPriceIndex] = {
                                ...updatedAssignmentPrices[lastAssignmentPriceIndex],
                                prices: values.Prices
                            };
                            return { 
                                ...item, 
                                assignmentPrices: updatedAssignmentPrices
                            };
                        }
                        return item;
                    });
                    setData(updatedData);
                    setFormSubmited(false);
                    resetForm();
                    handleClosePriceModal()
                    toast.success('Price change successfully')
                }
            } catch (error) {
                setFormSubmited(false);
                resetForm();
                toast.error(error)
                handleClosePriceModal()
            }
        },

    })

    const handlePriceInputChange = (e) => {
        const inputValue = e.target.value || '';
        const numericValue = inputValue.replace(/[^0-9]/g, '').substring(0, 10);
        formik.setFieldValue('Prices', numericValue);
    };

    const formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
    }).format(formik.values.Prices || 0);

    const handleKeywordChnage = (event) => {
        setKeyWord(event.target.value)
    }

    return (
        <Modal show={showPriceChangeModal} centered onHide={handleClosePriceModal} className='BumpAdd_modal' backdrop="static">
            <Modal.Body className='Bump_modal_body'>
                <div className='w-100'>
                    <div className='d-flex justify-content-between'>
                        <h5 className='BumpAdd_heading'>Price Change </h5>
                        <span className='bumpAdd_closeIcon ms-3' onClick={() => {
                            setShowPriceChangeModal(false);
                        }} ><CloseIcon style={{ fontSize: '18px' }} /></span>
                    </div>

                    <div className='d-flex flex-wrap Pay_textAll_field'>
                        <div className='Pay_text_field'>
                            <p
                                className="cardPay_head m-0"
                                style={{
                                    fontFamily: "sans-serif",
                                    fontSize: "medium",
                                }}
                            >
                                New Price
                            </p>
                            <TextField
                                type="text"
                                name="Prices"
                                size="small"
                                sx={{ width: "100%", borderRadius: "4px", marginBottom: "20px" }}
                                placeholder='Enter New Price'
                                className=""
                                id="outlined-basic"
                                variant="outlined"
                                value={formattedValue}
                                onChange={handlePriceInputChange}
                            />
                            {isSubmited ? (
                                <span style={{ color: "red" }}>{formik.errors.Prices}</span>
                            ) : null}
                        </div>

                        <div className='Pay_text_field'>
                            <p
                                className="cardPay_head m-0"
                                style={{
                                    fontFamily: "sans-serif",
                                    fontSize: "medium",
                                }}
                            >
                                Price Tag
                            </p>
                            <TextField
                                type="text"
                                size="small"
                                placeholder='Enter Keyword'
                                sx={{ width: "100%", borderRadius: "4px", marginBottom: "20px" }}
                                className="m-0"
                                id="outlined-basic"
                                variant="outlined"
                                value={keyWord}
                                onChange={(e) => handleKeywordChnage(e)}
                            />
                            <div className='mt-1'>
                                <button onClick={() => setKeyWord('New Price')} className='PricingChangeModal_Chip'>New Price</button>
                                <button onClick={() => setKeyWord('Price Updated')} className='PricingChangeModal_Chip'>Price Updated</button>
                            </div>
                        </div>
                    </div>


                    <div className='d-flex justify-content-center align-items-center mt-4' >
                        <button type="submit" disabled={formSubmited} onClick={() => {
                            formik.handleSubmit();
                            setIsSubmited(true);
                        }} className={`payNow_btn creditPay_btn`}>Save Changes</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default PriceChangingModal;
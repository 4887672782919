import React from "react";

export default function Showmyhouse() {


  return (
    <div className="text-center">
      <div className="d-flex flex-column align-items-center">

        <h1 className="text-primary font-weight-bold">We can take care, of everything </h1>

        <ul className="text-start mt-3" style={{ listStyle: 'disc' }}>
          <li>
            Your Schedule matters. We'll work around for you for the ideal
            showing times
          </li>
          <li>
            You can select a lockbox solution that fits both agents and your
            showing needs.
          </li>
          <li>
            Your online tool will keep you up-to-date with real-time requests.
          </li>
        </ul>

        <p className="text-center mt-4" style={{ fontWeight: '500' }}>
          <strong>
            Want to visit that special property you found online? Our experts
            would be happy to help
          </strong>
        </p>
        <center>
          <a href='#contactSupport'>
            <button className="btn learn_moreBtnTabs text-center m-0">
              Learn More
            </button>
          </a>
        </center>
      </div>
    </div>
  );
}

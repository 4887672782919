import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import baseURL from "../../config";
import "jspdf-autotable";
import DownloadIcon from "@mui/icons-material/Download";
import { AiFillPrinter } from "react-icons/ai";
import axios from "axios";
import Loader from '../../HomePage/SubComponents/MapandListing/Loader'

const DrawerPdf = ({
  purchasePricePdf,
  downVlauePdf,
  yearsVlauePdf,
  landTaxPdf,
  totalMortgagePdf,
  interstPdf,
  monthlyMortgagePdf,
}) => {
  const data = useSelector((state) => state.PdfDownloadSlice);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [mediaImageSrc, setMediaImageSrc] = useState("");
  const [imageRec, setImageRec] = useState(false);
  const [floorPlanSrc, setFloorPlanSrc] = useState("");
  const [floorPlanRec, setFloorPlanRec] = useState(false);

  // const fileUrl = data[0]?.assignmentMedias[0]?.fileUrl;

  //For Floor Plan Image
  const floorPlanData = data[0]?.assignmentMedias?.filter(
    (obj) => obj?.mediaCategory === "Floor Plan"
  );
  const [hasFloorPlan] = useState(false);
  //Calculation Work
  const nowAssignmentPrice =
    data[0]?.assignmentPrices?.length > 0
      ? data[0]?.assignmentPrices[data[0]?.assignmentPrices?.length - 1].prices
      : data[0]?.assignmentPrice;
  const totalToSeller = Math.ceil(
    purchasePricePdf - data[0]?.originalPrice + data[0]?.depositToBuilder
  );
  const depositPercentage = Math.ceil(purchasePricePdf * 0.05);
  const balancePayment = totalToSeller - depositPercentage;
  const totalSellerToshow = `$ ${totalToSeller?.toLocaleString("en-US")}`;
  const depositPercentageToshow = `$ ${depositPercentage?.toLocaleString(
    "en-US"
  )}`;
  const balancePayToshow = `$ ${balancePayment?.toLocaleString("en-US")}`;
  const biddingPrice = `$ ${purchasePricePdf?.toLocaleString("en-US")}`;
  const PostedFormatedDate = formatDate(data[0]?.created);
  const closingFormatdDate = formatDate(data[0]?.occupanycDate);
  const type = data[0]?.type || "N/A";
  // const Id = data[0]?.id?.toString() || "N/A";
  const style = data[0]?.style || "N/A";
  const address = data[0]?.address || "N/A";
  const bed = data[0]?.bed?.toString() || "N/A";
  const bath = data[0]?.bath?.toString() || "N/A";
  const exposure = data[0]?.exposure?.toString() || "N/A";
  const parking = data[0]?.parking?.toString() || "N/A";
  const parkingType = data[0]?.parkingType?.toString() || "N/A";
  const floorRange = data[0]?.floorRange?.toString() || "N/A";
  const garage = data[0]?.garage?.toString() || "N/A";
  const locker = data[0]?.locker?.toString() || "N/A";
  const developer = data[0]?.developer;
  const PropertyStatus = data[0]?.propStatus?.toString() || "N/A";
  const upgradeBalance =
    data[0]?.upgradeBalance !== undefined && data[0]?.upgradeBalance !== null
      ? `$ ${data[0]?.upgradeBalance.toLocaleString("en-US")}`
      : "0";
  const exteriorSize = `${data[0]?.interiorSize} FT²`;
  const sellerAskingPrice = `$ ${nowAssignmentPrice?.toLocaleString("en-US")}`;
  const originalPrice = `$ ${data[0]?.originalPrice?.toLocaleString("en-US")}`;
  const originalDeposit = `$ ${data[0]?.depositToBuilder?.toLocaleString(
    "en-US"
  )}`;
  const commission =
    data[0]?.commission !== undefined && data[0]?.commission !== null
      ? `${data[0]?.commission.toLocaleString("en-US")} %`
      : "0";
  const occupancyDue =
    data[0]?.occupancyDue !== undefined && data[0]?.occupancyDue !== null
      ? `$ ${data[0]?.occupancyDue.toLocaleString("en-US")}`
      : "0";
  const maintainenceFee =
    data[0]?.maintainenceFee !== undefined && data[0]?.maintainenceFee !== null
      ? `$ ${data[0]?.maintainenceFee.toLocaleString("en-US")}`
      : "0";
  const levyFee =
    data[0]?.levyFee !== undefined && data[0]?.levyFee !== null
      ? `$ ${data[0]?.levyFee.toLocaleString("en-US")}`
      : "0";
  const highlights = data[0]?.highlight;
  const pageNumberText = hasFloorPlan ? "1/2" : "1/1";

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { month: "short", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }


  const downloadImage = async (imageId, setMediaImageSrc, setImageRec) => {
    try {
      const response = await axios.get(
        `${baseURL}/api/data/ImageInByte/${imageId}`
      );
      const base64Data = response?.data?.data;
      if (base64Data) {
        const binaryData = Uint8Array.from(atob(base64Data), (c) =>
          c.charCodeAt(0)
        ).buffer;
        const imageData = new Blob([binaryData], { type: "image/jpeg" });
        // Convert the Blob to a data URL
        const imageUrl = URL.createObjectURL(imageData);

        // Set the data URL as the image source in state
        setMediaImageSrc(imageUrl);
        setImageRec(true);
      }
    } catch (error) {
      console.error("Error downloading image");
    }
  };


  const DownloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4",
      marginLeft: 5,
      marginRight: 5,
      marginTop: 5,
      marginBottom: 2,
    });


    //WaterMark Section

    const waterMark = new Image();
    waterMark.src = `/images/Print_waterMark.jpg`;
    doc.addImage(waterMark, "png", 80, 30, 130, 140);


    //Heading Section

    const logo = new Image();
    logo.src = `/images/New__LOGO.jpg`;
    doc.addImage(logo, "png", 96, 2, 23, 23);

    doc.setFontSize(16);
    doc.setTextColor(2, 84, 158);
    doc.setFont("Helvetica", "bold");
    doc.text("ASSIGNMENT", 117, 17);
    doc.setFontSize(16);
    doc.setTextColor(247, 152, 38);
    doc.setFont("Helvetica", "bold");
    doc.text("SOLD.CA", 155, 17);

    // First Section
    doc.rect(10, 22, 75, 35); // Border around the first section
    // Add the image
    if (data[0]?.assignmentMedias?.length > 0) {
      const img = new Image();
      img.src = `${mediaImageSrc}`;
      doc.addImage(img, "png", 12, 24, 70, 30);
    }

    doc.rect(85, 22, 200, 35);
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.setFont("Helvetica", "bold");
    doc.text("Project Name:", 95, 30);
    doc.setFont("Helvetica", "normal");
    doc.text(data[0]?.projectName, 135, 30);
    doc.setFont("Helvetica", "bold");
    doc.text("Address:", 95, 37);
    doc.setFont("Helvetica", "normal");
    doc.text(address, 135, 37);
    doc.setFont("Helvetica", "bold");
    doc.text("Type:", 95, 44);
    doc.setFont("Helvetica", "normal");
    doc.text(type, 135, 44);
    doc.setFont("Helvetica", "bold");
    doc.text("Status:", 190, 44);
    doc.setFont("Helvetica", "normal");
    doc.text(PropertyStatus, 225, 44);
    doc.setFont("Helvetica", "bold");
    doc.text("Posted Date:", 95, 52);
    doc.setFont("Helvetica", "normal");
    doc.text(PostedFormatedDate, 135, 52);
    doc.setFont("Helvetica", "bold");
    doc.text("Closing Date:", 190, 52);
    doc.setFont("Helvetica", "normal");
    doc.text(closingFormatdDate, 225, 52);

    //First Table Column

    doc.rect(10, 60, 87, 102);
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor(2, 84, 158);
    doc.text("Assignments Details", 25, 70);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    doc.setFont("Helvetica", "bold");
    doc.text("Style:", 15, 80);
    doc.setFont("Helvetica", "normal");
    doc.text(style, 66, 80);
    doc.setFont("Helvetica", "bold");
    doc.text("Exact Size Sqft:", 15, 86);
    doc.setFont("Helvetica", "normal");
    doc.text(exteriorSize, 66, 86);
    doc.setFont("Helvetica", "bold");
    doc.text("# Bedrooms/Dens:", 15, 92);
    doc.setFont("Helvetica", "normal");
    doc.text(bed, 66, 92);
    doc.setFont("Helvetica", "bold");
    doc.text("Exposure:", 15, 98);
    doc.setFont("Helvetica", "normal");
    doc.text(exposure, 66, 98);
    doc.setFont("Helvetica", "bold");
    doc.text("# Bathrooms:", 15, 104);
    doc.setFont("Helvetica", "normal");
    doc.text(bath, 66, 104);
    doc.setFont("Helvetica", "bold");
    doc.text("Floor-Range:", 15, 110);
    doc.setFont("Helvetica", "normal");
    doc.text(floorRange, 66, 110);
    doc.setFont("Helvetica", "bold");
    doc.text("Parking Slots:", 15, 116);
    doc.setFont("Helvetica", "normal");
    doc.text(parking, 66, 116);
    doc.setFont("Helvetica", "bold");
    doc.text("Parking Type:", 15, 122);
    doc.setFont("Helvetica", "normal");
    doc.text(parkingType, 66, 122);
    doc.setFont("Helvetica", "bold");
    doc.text("Garage:", 15, 128);
    doc.setFont("Helvetica", "normal");
    doc.text(garage, 66, 128);
    doc.setFont("Helvetica", "bold");
    doc.text("Locker:", 15, 134);
    doc.setFont("Helvetica", "normal");
    doc.text(locker, 66, 134);
    doc.setFont("Helvetica", "bold");
    doc.setFont("Helvetica", "bold");
    doc.text("Occupancy Date:", 15, 140);
    doc.setFont("Helvetica", "normal");
    doc.text(closingFormatdDate, 66, 140);
    doc.setFont("Helvetica", "bold");
    doc.text("Upgrade Charges:", 15, 146);
    doc.setFont("Helvetica", "normal");
    doc.text(upgradeBalance, 66, 146);
    doc.setFont("Helvetica", "bold");
    doc.text("Developer:", 15, 152);
    doc.setFont("Helvetica", "normal");
    if (developer) {
      const developer1 = developer.slice(0, 20).toString() || "N/A";
      const developer2 = developer.slice(21, 40).toString();
      doc.text(developer1, 55, 152);
      doc.text(developer2, 55, 157);
    } else {
      doc.text("N/A", 143, 152);
    }

    //Secod  Table Column

    doc.rect(100, 60, 95, 102);
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor(2, 84, 158);
    doc.text("Pricing Details", 122, 70);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    doc.setFont("Helvetica", "bold");
    doc.text("Seller Asking Price:", 103, 80);
    doc.setFont("Helvetica", "normal");
    doc.text(sellerAskingPrice, 166, 80);
    doc.setFont("Helvetica", "bold");
    doc.text("Bidding Price:", 103, 86);
    doc.setFont("Helvetica", "normal");
    doc.text(biddingPrice, 166, 86);
    doc.setFont("Helvetica", "bold");
    doc.text("Original Price:", 103, 92);
    doc.setFont("Helvetica", "normal");
    doc.text(originalPrice, 166, 92);
    doc.setFont("Helvetica", "bold");
    doc.text("Original Deposit:", 103, 98);
    doc.setFont("Helvetica", "normal");
    doc.text(originalDeposit, 166, 98);
    doc.setFont("Helvetica", "bold");
    doc.text("Total payment by buyer", 103, 104);
    doc.setFont("Helvetica", "normal");
    doc.text(totalSellerToshow, 166, 104);
    doc.setFont("Helvetica", "bold");
    doc.text("to seller:", 103, 110);
    doc.setFont("Helvetica", "bold");
    doc.text("5% Deposit upon acc. of agr:", 103, 116);
    doc.setFont("Helvetica", "normal");
    doc.text(depositPercentageToshow, 166, 116);
    doc.setFont("Helvetica", "bold");
    doc.text("Balance of the payment for", 103, 124);
    doc.setFont("Helvetica", "bold");
    doc.text("this assignment agreement:", 103, 130);
    doc.setFont("Helvetica", "normal");
    doc.text(balancePayToshow, 166, 124);
    doc.setFont("Helvetica", "bold");
    doc.text("Cooperating Commision:", 103, 136);
    doc.setFont("Helvetica", "normal");
    doc.text(commission, 166, 136);
    doc.setFont("Helvetica", "bold");
    doc.text("Occupancy-Due:", 103, 142);
    doc.setFont("Helvetica", "normal");
    doc.text(occupancyDue, 166, 142);
    doc.setFont("Helvetica", "bold");
    doc.text("Maintainence Fee:", 103, 148);
    doc.setFont("Helvetica", "normal");
    doc.text(maintainenceFee, 166, 148);
    doc.setFont("Helvetica", "bold");
    doc.text("Development / Levy Charges:", 103, 154);
    doc.setFont("Helvetica", "normal");
    doc.text(levyFee, 166, 154);

    //Third Column of Table
    doc.rect(198, 60, 87, 102);
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor(2, 84, 158);
    doc.text("Highlights", 230, 70);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    doc.setFont("Helvetica", "normal");
    if (highlights) {
      const maxCharactersPerLine = 39;
      const maxLines = 17;
      const maxCharactersIn17thLine = 35;
      const lineHeight = 5;
      let start = 0;
      let yCoordinate = 80;
      let linesDisplayed = 0;

      while (start < highlights.length && linesDisplayed < maxLines - 1) {
        let line = highlights.substring(start, start + maxCharactersPerLine);
        line = line.trimStart();
        doc.text(line, 203, yCoordinate);
        start += maxCharactersPerLine;
        yCoordinate += lineHeight;
        linesDisplayed++;
      }

      if (start < highlights.length) {
        let lastLine = highlights.substring(start, start + maxCharactersIn17thLine);
        lastLine = lastLine.trimStart();

        if (lastLine.length === maxCharactersIn17thLine) {
          lastLine = lastLine.slice(0, -3) + '...';
        }

        doc.text(lastLine, 203, yCoordinate);
      }
    }




    //Mortgage Work

    doc.setFont("Helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor(2, 84, 158);
    doc.text("Mortgage Calculator", 10, 170);

    const tableData = [
      [
        `$ ${purchasePricePdf?.toLocaleString("en-US")}`,
        `$ ${downVlauePdf?.toLocaleString("en-US")}`,
        `${interstPdf} %`,
        `${yearsVlauePdf} years`,
        `$ ${landTaxPdf?.toLocaleString("en-US")}`,
        `$ ${monthlyMortgagePdf?.toLocaleString("en-US")}`,
        `$ ${totalMortgagePdf?.toLocaleString("en-US")}`,
      ],
    ];

    // Set column widths (adjust these as needed)
    const columnWidths = [40, 40, 40, 40, 40, 40, 40];

    // Create the table with borders
    doc.autoTable({
      head: [
        [
          "Purchase Price",
          "Down Payment",
          "Interest Rate",
          "Amortization Years",
          "Land Transfer Tax",
          "Monthly Payment",
          "Mortgage Total",
        ],
      ],
      body: tableData,
      margin: { top: 175 },
      columnStyles: {
        0: {
          textColor: [0, 0, 0],
          fontStyle: "normal",
          halign: "center",
          fillColor: [255, 255, 255],
        },
        1: {
          textColor: [0, 0, 0],
          fontStyle: "normal",
          halign: "center",
          fillColor: [255, 255, 255],
        },
        2: {
          textColor: [0, 0, 0],
          fontStyle: "normal",
          halign: "center",
          fillColor: [255, 255, 255],
        },
        3: {
          textColor: [0, 0, 0],
          fontStyle: "normal",
          halign: "center",
          fillColor: [255, 255, 255],
        },
        4: {
          textColor: [0, 0, 0],
          fontStyle: "normal",
          halign: "center",
          fillColor: [255, 255, 255],
        },
        5: {
          textColor: [0, 0, 0],
          fontStyle: "normal",
          halign: "center",
          fillColor: [255, 255, 255],
        },
        6: {
          textColor: [0, 0, 0],
          fontStyle: "normal",
          halign: "center",
          fillColor: [255, 255, 255],
        },
      },
      columnWidth: columnWidths,
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.5,
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        halign: "center",
      },
    });

    doc.setTextColor(0, 0, 0);
    doc.setFontSize(9);
    doc.setFont("Helvetica", "normal");
    doc.text("To visit complete details of Property", 10, 200);
    doc.setTextColor(125, 89, 243);
    const linkText = "Click here";
    const linkURL = `https://assignmentsold.ca/assignment-sale/${data[0]?.slug}`;
    doc.textWithLink(linkText, 62, 200, { url: linkURL });
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(9);
    doc.text(pageNumberText, 277, 200);

    //Second Page Work
    if (floorPlanData?.length > 0) {
      doc.addPage();
      doc.setFontSize(20);
      doc.setTextColor(0, 0, 0);
      doc.setFont("Helvetica", "bold");
      doc.text("Floor Plan", 125, 15);
      const floorPlan = new Image();
      floorPlan.onload = function () {
        const imageWidthInMM = floorPlan.width * 0.2645833333;
        const imageHeightInMM = floorPlan.height * 0.2645833333;
        const imageWidthGreaterThanPage =
          imageWidthInMM > doc.internal.pageSize.width;
        const imageHeightGreaterThanPage =
          imageHeightInMM > (doc.internal.pageSize.height - 30);
        const floorPlanWidth = imageWidthGreaterThanPage ? 170 : imageWidthInMM;
        const floorPlanHeight = imageHeightGreaterThanPage ? 170 : imageHeightInMM;
        const newCenterX = (doc.internal.pageSize.width - floorPlanWidth) / 2;
        doc.addImage(
          floorPlan,
          "png",
          newCenterX,
          25,
          floorPlanWidth,
          floorPlanHeight,
        );
        doc.setTextColor(0, 0, 0);
        doc.setFont("Helvetica", "normal");
        doc.setFontSize(9);
        doc.text("2/2", 277, 200);
        // Save the PDF
        doc.save(`${data[0]?.projectName}-Details.pdf`);
      };
      floorPlan.src = `${floorPlanSrc}`;
    } else {
      doc.save(`${data[0]?.projectName}-Details.pdf`);
    };
  }

    const handleDownloadPDF = () => {
      setPdfLoading(true)
      if (data[0]?.assignmentMedias?.length > 0) {
        const imageId = data[0]?.assignmentMedias[0]?.id;
        downloadImage(imageId, setMediaImageSrc, setImageRec);
      } else if (data[0]?.assignmentMedias?.length === 0) {
        setImageRec(true);
      }

      if (floorPlanData?.length > 0) {
        const floorPlanId = floorPlanData[0]?.id;
        downloadImage(floorPlanId, setFloorPlanSrc, setFloorPlanRec);
      } else {
        setFloorPlanRec(true)
      }
    };

    useEffect(() => {
      if (imageRec && floorPlanRec) {
        DownloadPDF();
        setImageRec(false);
        setFloorPlanRec(false);
        setPdfLoading(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageRec, floorPlanRec]);

    return (
      <div>
        {/* <button onClick={handleDownloadPDF}>Download PDF</button> */}
        <div
          className="printicon_hide"
          style={{ position: "fixed", top: 6, right: 26, zIndex: 99 }}
        >
          <div
            className="aaligner share_drawerIcon"
            style={{ padding: "12px", cursor: "pointer" }}
            onClick={handleDownloadPDF}
          >
            <AiFillPrinter style={{ fontSize: "25px", color: "#02549e" }} />
          </div>
        </div>
        <button onClick={handleDownloadPDF} className="Detailsbutton font13">
          <DownloadIcon style={{ color: "#ffff", fontSize: "18px" }} /> Details
        </button>
        {pdfLoading && (
          <div className="scrollLoader">
            <Loader size="80" />
            <p style={{ color: "#F8A23E", fontWeight: 600 }}>Loading....</p>
          </div>
        )}
      </div>
    );
  };

  export default DrawerPdf;

import { Visibility, VisibilityOff } from "@mui/icons-material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useFormik } from "formik";
import IconButton from "@mui/material/IconButton";
import baseURL from "../../config";
import { NewPasswordSchema } from "./NewPasswordSchema";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate , useLocation } from "react-router-dom";
import { useState } from "react";
import Loader from "../Loader/Loader";
import "react-toastify/dist/ReactToastify.css";
import './ResetPassword.css'


const NewPassword = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Email = queryParams.get("email");
  const Token = queryParams.get("token");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  
  const handleClickShowCPassword = () => setShowCPassword((show) => !show);
 
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const formInitialValues = {
    password: "",
    cpassword: "",
  };
  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: NewPasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      const data = {
        Email: Email,
        Password: values.password,
        Token: Token,
        DeviceId: "",
      };
      try {
        const response = await axios.post(
          `${baseURL}/api/confirmPasswordresetcode`,data 
        );
        if (response?.data?.result === 1) {
          toast.success("Password changed Sucessfully");
          setTimeout(() => {
            navigate("/login");
          }, 1500);
        } else {
          toast.error(response?.data?.error);
        }
      } catch (error) {
        toast.error("Try Again");
        console.error(error.message);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    },
  });
  return (
    <div className="container centered  aligning">
      <div className="imagecentered">
        <img
          className="imgclass1"
          src={require("../Images/icon.jpeg")}
          alt="AssignmentSold Logo"
          loading="lazy"
        />
      </div>
      <div>
      <p className="font m-0 resetheading">Reset Your Password</p>
      </div>
    <div className="textFieldmain">
    <p className="font  fieldhead m-0">
        <strong>New Password</strong>
      </p>
      <div>
        <FormControl sx={{ width: "386px" }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            size="small"
            value={formik.values.password}
            onChange={formik.handleChange}
            name="password"
            id="outlined-adornment-password1"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            }
            label={<span className="font">Password</span>}
          />
          {isSubmitted ? (
            <span style={{ color: "red" }}>{formik.errors.password}</span>
          ) : null}
        </FormControl>
      </div>
      <p className="font fieldhead m-0">
        <strong>Confirm Password</strong>
      </p>
      <FormControl sx={{ width: "386px" }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">
          Re-Enter Password
        </InputLabel>
        <OutlinedInput
          size="small"
          value={formik.values.cpassword}
          onChange={formik.handleChange}
          name="cpassword"
          id="outlined-adornment-password5"
          type={showCPassword ? "text" : "password"}
          endAdornment={
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowCPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showCPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          }
          label={<span className="font">Re-Enter Password</span>}
        />
        {isSubmitted ? (
          <span style={{ color: "red" }}>{formik.errors.cpassword}</span>
        ) : null}
      </FormControl>
      </div>
      {isLoading ? (
        <div className="aligncenter justifycenter mt-4">
          <Loader />
        </div>
      ) : (
        <div>
          <button
            type="submit"
            onClick={() => {
              formik.handleSubmit();
              setIsSubmitted(true);
            }}
            className="CreateButton"
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default NewPassword;


import './MyOrderSummary.css'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import DashboardLayout from "../../Dashboard";
import { toast, ToastContainer } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';
import baseURL from '../../../config';
import Cookies from 'universal-cookie';
import dayjs from 'dayjs';
import { RotatingLines } from 'react-loader-spinner';
import PayModal from '../Listing/PayModal';
import { Typography } from '@mui/material';


const OrderSummary = () => {

    const { id } = useParams();
    const location = useLocation();
    const status = new URLSearchParams(location.search).get('status');
    const projectName = new URLSearchParams(location.search).get('projectName');
    const navigate = useNavigate()
    const cookies = new Cookies();
    const jwt_token = cookies.get('jwt_authorization');
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true)
    const [showPayModal, setShowPayModal] = useState(false)
    const [selectedPackages, setSelectedPackages] = useState([])

    function FetchData() {
        const headers = {
            Authorization: `Bearer ${jwt_token}`,
        };

        axios
            .get(`${baseURL}/api/User-Data/Myorders/${id}`, { headers })
            .then((response) => {
                const allAssignments = response?.data?.data;
                setData(allAssignments);
                setLoader(false)
            })
            .catch((error) => {
                toast.error(error);
                setLoader(false)
            });

    }

    useEffect(() => {
        if (!jwt_token) {
            navigate('/login');
            return;
        }
        FetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleCheckboxChange = (pkgData) => {
        setSelectedPackages((prevSelectedPackages) => {
            const isPackageSelected = prevSelectedPackages.some((pkg) => pkg === pkgData);

            if (isPackageSelected) {
                return prevSelectedPackages.filter((pkg) => pkg !== pkgData);
            } else {
                return [...prevSelectedPackages, pkgData];
            }
        });
    };

    // Calculate item total dynamically
    const calculateItemTotal = () => {
        return selectedPackages?.reduce((total, item) => total + parseFloat(item.price), 0).toFixed(2);
    };

    const calculateTotalWithTax = () => {
        const taxRate = 4;
        const subtotal = calculateItemTotal();
        let totalWithTax = parseFloat(subtotal) + taxRate;
        return totalWithTax?.toFixed(2);
    };

    const handleClosePayModal = () => {
        setShowPayModal(false)
    }

    const [openTooltipIndex, setOpenTooltipIndex] = useState(null);

    const handleTooltipToggle = (index) => {
        setOpenTooltipIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <DashboardLayout>
            <ToastContainer />
            <div className="row br-mobile4remMarginTop"></div>
            <section className='page-content br-page-content'>


                {/* ----- Web View Table ------ */}
                <div className="d-flex orderSummary_webViewContainer justify-content-between align-items-center mb-3 mt-4">

                    <div className="headingtitlelister">
                        <h3 className="Single_allListingHeading m-0 d-flex align-items-center">
                            <ArrowBackIosNewIcon className='mx-1 singleUserBreadCrumbs_arrow'  onClick={() => navigate(-1)}/>
                            <span style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>Order Summary</span>{" "}
                            <span style={{ fontSize: '20px' }}> {`/ ${projectName}`}</span>
                        </h3>
                    </div>
                </div>
                {loader ?
                    <center className="text-center mt-5 orderSummary_webViewContainer">
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="70"
                            visible={loader}
                        />
                    </center>
                    :
                    <div className='w-100 p-4 Order_PricingTableContainer orderSummary_webViewContainer'>
                        <table className='Order_PricingTable w-100'>
                            <thead className='w-100'>
                                <tr className='w-100'>
                                    <th style={{ width: '35%' }}>Category</th>
                                    <th style={{ width: '13%' }}>Price</th>
                                    <th style={{ width: '13%' }}>Days</th>
                                    <th style={{ width: '26%' }}>Duration</th>
                                    <th style={{ width: '13%' }}>Sub Total</th>
                                </tr>
                            </thead>
                            <tbody style={{ borderBottom: '1px solid #d9d9d9' }}>
                                {data?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className='d-flex align-items-center'>
                                                {status === 'Pending' && (
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            className='m-0 order_summary_checkBox'
                                                            checked={selectedPackages.some((pkg) => pkg === item)}
                                                            onChange={() => handleCheckboxChange(item)}
                                                            control={<Checkbox className='ps-2' />}
                                                        />
                                                    </FormGroup>
                                                )}
                                                <img src={require(`../../../Components/Images/${item?.category.replace(/\b\w/g, c => c.toUpperCase()).replace(/\s/g, '')}Icon.png`)} alt='ticker-logo' style={{ height: '36px' }} loading="lazy"/>
                                                <span className='ms-2'>{item?.category?.includes('Tags') ? 'Hot Deal Tags' : item?.category}</span>
                                                <Tooltip
                                                    title={
                                                        <div>
                                                            <Typography style={{ fontSize: '12px' }}>
                                                                Get on average of ${item?.category === 'Top Home Page Gallery' ? '5.5' : item?.category === 'Home Page Ticker' ? '7' : item?.category === 'Highlight Add' ? '8' : item?.category === 'Tags' ? '10' : '5.5'} impressions/7 days with a prime position on our homepage.{''}
                                                                <a href='https://assignmentsold.ca/tools-to-promote-ad' style={{ cursor: 'pointer', textDecoration: 'underline' }} target='_blank' rel="noopener noreferrer">Learn more</a>
                                                            </Typography>
                                                        </div>
                                                    } arrow>
                                                    <span className="info-icon ms-1">
                                                        <InfoOutlinedIcon />
                                                    </span>
                                                </Tooltip>
                                            </td>
                                            <td style={{ color: '#000', fontWeight: '500' }}>{`$ ${item?.price}`}</td>
                                            <td style={{ fontWeight: '600' }}>{`${item?.days} days`}</td>
                                            <td style={{ fontWeight: '600' }}>{`${dayjs(item?.startDatedayjs)?.format("DD MMM YYYY")} - ${dayjs(item?.endDate)?.format("DD MMM YYYY")}`}</td>
                                            <td style={{ color: '#121212', textAlign: 'center' }}><strong>{`$${item?.price}`}</strong></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>


                        <div className='ms-auto p-3 d-flex flex-column align-items-end' style={{ minWidth: '880px', gap: '15px' }}>
                            <p className='m-0 d-flex' style={{ color: "#7e7e7e", fontWeight: '600' }}>
                                <span style={{ width: '130px', textAlign: 'left' }}>Item Total</span>
                                <span style={{ fontWeight: '700', width: '80px', textAlign: 'right' }}><strong>{`$${calculateItemTotal()}`}</strong></span>
                            </p>
                            <p className='m-0 d-flex' style={{ color: "#7e7e7e", fontWeight: '600' }}>
                                <span style={{ width: '130px', textAlign: 'left' }}>Tax</span>
                                <span style={{ fontWeight: '700', width: '80px', textAlign: 'right' }}><strong>$4.00</strong></span>
                            </p>
                            <p className='m-0 d-flex' style={{ color: "#7e7e7e", fontWeight: '600' }}>
                                <span style={{ width: '130px', textAlign: 'left' }}>Total</span>
                                <span style={{ fontWeight: '700', width: '80px', textAlign: 'right', color: '#4FB020' }}><strong>{`$${calculateTotalWithTax()}`}</strong></span>
                            </p>
                            {status === 'Pending' && (
                                <button className='WebPayNow_btn' onClick={() => setShowPayModal(true)}>Pay now</button>
                            )}
                        </div>

                    </div>
                }


                {/* ---- Mobile View Content ----- */}

                <div className='orderSummary_mobileViewContainer'>
                    <h3 className="m-0 orderSummaryMob_heading" >
                        {`${projectName}`}
                    </h3>

                    {loader ?
                        <center className="text-center mt-4">
                            <RotatingLines
                                strokeColor="grey"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="70"
                                visible={loader}
                            />
                        </center>
                        :
                        <>
                            <div className='orderSummary_billCard '>
                                {data?.map((item, index) => {
                                    return (
                                        <div key={index} className='OrderSumm_MobBillCard'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div className='d-flex align-items-center'>
                                                    {status === 'Pending' && (
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                className='m-0 order_summary_checkBox'
                                                                checked={selectedPackages.some((pkg) => pkg === item)}
                                                                onChange={() => handleCheckboxChange(item)}
                                                                control={<Checkbox className='ps-2' />}
                                                            />
                                                        </FormGroup>
                                                    )}
                                                    <img src={require(`../../../Components/Images/${item?.category.replace(/\b\w/g, c => c.toUpperCase()).replace(/\s/g, '')}Icon.png`)} alt='ticker-logo' style={{ height: '36px' }} loading="lazy"/>
                                                    <span className='ms-2'>{item?.category?.includes('Tags') ? 'Hot Deal Tags' : item?.category}</span>
                                                    <Tooltip
                                                        key={index}
                                                        title={
                                                            <div>
                                                                <Typography style={{ fontSize: '12px' }}>
                                                                    Get on average of ${item?.category === 'Top Home Page Gallery' ? '5.5' : item?.category === 'Home Page Ticker' ? '7' : item?.category === 'Highlight Add' ? '8' : item?.category === 'Tags' ? '10' : '5.5'} impressions/7 days with a prime position on our homepage.{' '}
                                                                    <a href='https://assignmentsold.ca/tools-to-promote-ad' style={{ cursor: 'pointer', textDecoration: 'underline' }} target='_blank' rel="noopener noreferrer">Learn more</a>
                                                                </Typography>
                                                            </div>
                                                        }
                                                        arrow
                                                        open={openTooltipIndex === index}
                                                        onOpen={() => handleTooltipToggle(index)}
                                                        onClose={() => handleTooltipToggle(index)}
                                                        className='orderSummary_billCard '
                                                    >
                                                        <div style={{ color: '#9A9A9A' }} onClick={() => handleTooltipToggle(index)}>
                                                            <InfoOutlinedIcon />
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <span>Price</span>
                                                <span>{`$${item?.price}`}</span>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <span>Days</span>
                                                <span>{`${item?.days} days`}</span>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <span>Duration</span>
                                                <span>{`${dayjs(item?.startDatedayjs)?.format("DD MMM YYYY")} - ${dayjs(item?.endDate)?.format("DD MMM YYYY")}`}</span>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <span style={{ fontWeight: '600', fontSize: '20px' }}>Sub Total</span>
                                                <span style={{ fontWeight: '600', color: '#000', fontSize: '20px' }}>{`$${item?.price}`}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='TotalOrderBill_mbl'>
                                <p className='d-flex justify-content-between align-items-center m-0' style={{ fontSize: '14px', fontWeight: '600', color: "#7e7e7e" }}>
                                    <span>Item Total</span>
                                    <span>{`$${calculateItemTotal()}`}</span>
                                </p>
                                <p className='d-flex justify-content-between align-items-center m-0' style={{ fontSize: '14px', fontWeight: '600', color: "#7e7e7e" }}>
                                    <span>Tax</span>
                                    <span>{`$4.00`}</span>
                                </p>
                                <p className='d-flex justify-content-between align-items-center m-0' style={{ fontSize: '20px', fontWeight: '600' }}>
                                    <span style={{ color: "#7e7e7e" }}>Total</span>
                                    <span style={{ color: "#4FB020" }}>{`$${calculateTotalWithTax()}`}</span>
                                </p>
                                {status === 'Pending' && (
                                    <button className='OrderSummaryPay_nowBtn btn mt-4' onClick={() => setShowPayModal(true)}>Pay now</button>
                                )}
                            </div>
                        </>}
                </div>
                {showPayModal && (
                    <PayModal
                        // assignmentId={assignmentId}
                        showPayModal={showPayModal}
                        setShowPayModal={setShowPayModal}
                        handleClosePayModal={handleClosePayModal}
                        selectedPackages={selectedPackages}
                    />
                )}
            </section>
        </DashboardLayout>
    )
}

export default OrderSummary
const Mortgagedata = [
    {
      name: "John Doe",
      address: "123 Main St, City",
      image: "/images/MortgageAdvisor/pic1.jpeg",
      language: "English",
      description:
        "A talented individual with a passion for coding and problem-solving.",
    },
    {
      name: "Alice Smith",
      address: "456 Elm St, Town",
      image: "/images/MortgageAdvisor/pic2.jpeg",
      language: "French",
      description:
        "Experienced in web development and fluent in multiple programming languages.",
    },
    {
      name: "Bob Johnson",
      address: "789 Oak St, Village",
      image: "/images/MortgageAdvisor/pic3.jpeg",
      language: "Spanish",
      description: "A dedicated developer with strong communication skills.",
    },
    {
      name: "Bob Johnson",
      address: "789 Oak St, Village",
      image: "/images/MortgageAdvisor/pic1.jpeg",
      language: "Spanish",
      description: "A dedicated developer with strong communication skills.",
    },
    {
      name: "Bob Johnson",
      address: "789 Oak St, Village",
      image: "/images/MortgageAdvisor/pic2.jpeg",
      language: "Spanish",
      description: "A dedicated developer with strong communication skills.",
    },
    {
      name: "Bob Johnson",
      address: "789 Oak St, Village",
      image: "/images/MortgageAdvisor/pic3.jpeg",
      language: "Spanish",
      description: "A dedicated developer with strong communication skills.",
    },
    {
      name: "Bob Johnson",
      address: "789 Oak St, Village",
      image: "/images/MortgageAdvisor/pic1.jpeg",
      language: "Spanish",
      description: "A dedicated developer with strong communication skills.",
    },
    {
      name: "Bob Johnson",
      address: "789 Oak St, Village",
      image: "/images/MortgageAdvisor/pic2.jpeg",
      language: "English",
      description: "A dedicated developer with strong communication skills.",
    },
    {
      name: "Bob Johnson",
      address: "789 Oak St, Village",
      image: "/images/MortgageAdvisor/pic3.jpeg",
      language: "English",
      description: "A dedicated developer with strong communication skills.",
    },
    {
      name: "Bob Johnson",
      address: "789 Oak St, Village",
      image: "/images/MortgageAdvisor/pic1.jpeg",
      language: "German",
      description: "A dedicated developer with strong communication skills.",
    },
    {
      name: "Bob Johnson",
      address: "789 Oak St, Village",
      image: "/images/MortgageAdvisor/pic2.jpeg",
      language: "English",
      description: "A dedicated developer with strong communication skills.",
    },
    {
      name: "johnYou",
      address: "789 Oak St, Village",
      image: "/images/MortgageAdvisor/pic3.jpeg",
      language: "french",
      description: "A dedicated developer with strong communication skills.",
    },
  ];

  export default Mortgagedata;
import AboutUsBanner from '../../images/AboutUSBanner.png';
import AboutUsModernizing from '../../images/AboutUsModernizing.png';
import AboutUsBuildingFuture from '../../images/AboutUsBuildingFuture.png';
import AboutUsHeroImg from '../../images/AboutUsHeroImg.png';
import './About.css'
import Footer from '../Footer/Footer';
import HelmetLayout from '../../Utils/HelmetLayout/Helmet';

const About = () => {
    return (
        <>

            <HelmetLayout pageName={'about'} canonicalLink={'about'} />

            <div className='AboutUsMain_container'>
                <div className="MortgaegMain_image AboutUsMain_image">
                    <div className="image_color">
                        <img src={AboutUsBanner} alt="MortgageBanner" loading="lazy"/>
                    </div>
                    <div className="AboutUs_HeadingContainer">
                        <h6 className='AboutUs_heading'>About us</h6>
                        <h3 className='AboutUsDes_heading mt-2'>
                            Get So in the Know With AssignmentSold
                        </h3>
                        <h5 className='AboutUs_des mt-4'>
                            “Knowledge is power. That’s why we go beyond the listing, to give Canadians the control they crave when buying or selling their home.”
                        </h5>
                    </div>
                </div>

                <div className='w-100 d-flex justify-content-center'>
                    <div className='AboutUs_content'>
                        <h3>About AssignmentSold.ca</h3>
                        <p className='mt-4'>For years, millions of home shoppers have turned to assignmentsold.ca® to find their dream home. Operated by Move, Inc., assignmentsold.ca® offers a comprehensive list of for-sale properties, as well as the information and tools to make informed real estate decisions. Today, more than ever, assignmentsold.ca® is The Home of Home Search℠.</p>
                        <p>
                            Assignmentsold.ca® also offers homeowners a bevy of useful tools and resources through the My Home℠ dashboard. My Home℠ dashboard allows property owners to manage their home like the important investment it is by tracking their home’s value over time, researching and managing home improvements, and scouting other similar properties in the neighborhood.
                        </p>

                        <h3 className='mt-5'>The AssignmentSold.ca Difference</h3>
                        <p className='mt-4'>Our search, content, and advertising strategies are designed to bring millions of transaction-ready buyers and sellers to assignmentsold.ca, where they can find a great agent, or connect to their current one and collaborate during the entire process.</p>
                        <p>
                            We offer a full line of advertising products and online marketing services designed to help real estate professionals connect with interested buyers and sellers. If your goals include connecting with quality buyers and sellers searching for their next home and leveraging the right tools and services to grow your business, you’ve come to the right place! assignmentsold.ca has tons of resources to help you stay informed of what’s happening in the industry, what’s working for successful agents, and what tactics are leading to success in today’s market.
                        </p>
                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-center  tools_grey_container'>
                    <div className='toolTicker_container'>
                        <div className='toolAdtext_container'>
                            <h2 className='tools_ad_heading'>Modernizing the real estate experience</h2>
                            <p className='SellerInf_DesSelling'>Buying and selling real estate is a difficult process to navigate, because it is fragmented and complex. Your journey from your current home to your new home includes many steps — research, shop, tour, finance, appraise, inspect, negotiate an offer, close and move — all with no central navigator.</p>
                        </div>
                        <div className='toolAdImage_container'>
                            <img src={AboutUsModernizing} alt='Modernizing' loading="lazy"/>
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-center '>
                    <div className='toolTicker_container selling_value_mobile_view'>
                        <div className='toolAdImage_container'>
                            <img src={AboutUsBuildingFuture} alt='BuildRealEstateFuture' loading="lazy"/>
                        </div>
                        <div className='toolAdtext_container'>
                            <h2 className='tools_ad_heading'>Building a better real estate future for all</h2>
                            <p className='SellerInf_DesSelling'>In addition to innovative products that help people get home, Zillow is working hard in other ways to push housing laws and the real estate industry forward in the best interest of consumers. As a category leader, Zillow publishes authoritative research and uses its voice to advocate for policies that make real estate more accessible, affordable and transparent.</p>
                        </div>
                    </div>
                </div>

                <div className='py-5 d-flex align-items-center justify-content-center w-100'>
                    <img src={AboutUsHeroImg} alt='OurOffice' style={{ maxWidth: '92%' }} loading="lazy"/>
                </div>

                <div className='container my-5'>
                    <h3 className='aboutStatisticsHeading'>We’re only just getting started on our journey</h3>
                    <div className='row py-5'>
                        <div className='col-md-3 aboutTotalStatistics'>
                            <h4>160M+</h4>
                            <h6>Homes in AssignmentSold living database</h6>
                            <p>Source: AssignmentSold Group investor page</p>
                        </div>
                        <div className='col-md-3 aboutTotalStatistics'>
                            <h4>10M+</h4>
                            <h6>Sell listing on AssignmentSold</h6>
                            <p>Source: AssignmentSold data</p>
                        </div>
                        <div className='col-md-3 aboutTotalStatistics'>
                            <h4>214M+</h4>
                            <h6>Average monthly unique user for 2024</h6>
                            <p>Source: AssignmentSold data</p>
                        </div>
                        <div className='col-md-3 aboutTotalStatistics'>
                            <h4>80%</h4>
                            <h6>More time spent on AssignmentSold</h6>
                            <p>Source: ComScore data on average minutes per user per month, Jan 2023 - Jan 2024</p>
                        </div>
                    </div>
                </div>

                <div>
                    <Footer />
                </div>
            </div >
        </>
    )
}

export default About
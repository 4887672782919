import './NotfoundPage.css';
import Footer from "../Footer/Footer";


const NotFoundPage = () => {
  return (
    <>
      <div className="Not_foundMain">
      <img
        src={require("../../images/404PageNotFound.png")}
        alt="404 Page Not Found"
        loading="lazy"
      />
      </div>
      <div className='mt-3'>
        <Footer />
        </div>
    </>
  );
};

export default NotFoundPage;

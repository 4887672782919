import BuyerFreedom from '../../../images/BuyerFreedom.png'
import BuyerFlexibility from '../../../images/BuyerFlexibility.png'
import './OurPromise.css'

const OurPromiseFromBuyer = () => {
    return (
        <div>
            <div className='d-flex py-4 px-4 mt-3 align-items-center justify-content-center flex-column tools_grey_container'>
                <h2 className='ourPromise_heading'>Our Promise to you</h2>
                <p className='text-center ourPromise_des'>We'll bend over backwards to meet all of your needs, not because we have to - because we want to.</p>
            </div>

            <div className='d-flex align-items-center justify-content-center'>
                <div className='toolTicker_container'>
                    <div className='toolAdtext_container'>
                        <h2 className='tools_ad_heading'>Freedom</h2>
                        <p className='SellerInf_DesSelling'>Buy any property that meets your needs no matter how it's listed. If you choose to buy a property that's listed privately, you'll have the FREEDOM and option to purchase it commission-free.</p>
                    </div>
                    <div className='toolAdImage_container'>
                        <img src={BuyerFreedom} alt='Buyer Freedom' loading="lazy"/>
                    </div>
                </div>
            </div>

            <div className='d-flex align-items-center justify-content-center  tools_grey_container'>
                <div className='toolTicker_container selling_value_mobile_view'>
                    <div className='toolAdImage_container'>
                        <img src={BuyerFlexibility} alt='Buyer Flexibility' loading="lazy"/>
                    </div>
                    <div className='toolAdtext_container'>
                        <h2 className='tools_ad_heading'>Flexibility</h2>
                        <p className='SellerInf_DesSelling'>No rigid contracts. Our Buyer Representation Agreement gives you all the FLEXIBILITY a savvy buyer wants and deserves.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurPromiseFromBuyer;

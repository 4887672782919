import React from "react";
import { ThreeCircles } from "react-loader-spinner";

export default function Loader({size}) {
  const defaultSize = 100;
  const height = size || defaultSize;
  const width = size || defaultSize;
  return (
    <div>
      <ThreeCircles
          height = {height}
          width = {width}
        color="#f6941c"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="three-circles-rotating"
        outerCircleColor="#02549e"
        innerCircleColor="#f6941c"
        middleCircleColor="#02549e"
      />
    </div>
  );
}

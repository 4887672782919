import React, { useEffect } from "react";
export default function ParkingButtons({ selectedParkingButtons , activeButtons, setActiveButtons }) {


  const handleClick = (button) => {
    // Check if the button is already active
    const isActive = activeButtons.includes(button);

    if (isActive) {
      // If the button is active, remove it from the active buttons state
      setActiveButtons(activeButtons.filter((btn) => btn !== button));
    } else {
      // If the button is inactive, add it to the active buttons state
      const newActiveButtons = [...activeButtons, button];
      setActiveButtons(newActiveButtons);
    }
  };
  useEffect(() => {
    selectedParkingButtons(activeButtons);
  }, [activeButtons, selectedParkingButtons]);
  return (
    <div>
      <button
        className={`btn ${
          activeButtons.includes("1")
            ? "btn p-0 font btnbeds2"
            : "btn p-0 font btnbeds"
        }`}
        onClick={() => handleClick("1")}
      >
        1
      </button>
      <button
        className={`btn ${
          activeButtons.includes("2")
            ? "btn p-0 font btnbeds2"
            : "btn p-0 font btnbeds"
        }`}
        onClick={() => handleClick("2")}
      >
        2
      </button>
      <button
        className={`btn ${
          activeButtons.includes("3")
            ? "btn p-0 font btnbeds2"
            : "btn p-0 font btnbeds"
        }`}
        onClick={() => handleClick("3")}
      >
        3
      </button>
      <button
        className={`btn ${
          activeButtons.includes("4")
            ? "btn p-0 font btnbeds2"
            : "btn p-0 font btnbeds"
        }`}
        onClick={() => handleClick("4")}
      >
        4
      </button>
      <button
        className={`btn ${
          activeButtons.includes("5")
            ? "btn p-0 font btnbeds2"
            : "btn p-0 font btnbeds"
        }`}
        onClick={() => handleClick("5")}
      >
        5
      </button>
    </div>
  );
}
